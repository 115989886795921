import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import $ from 'jquery';
import notie from 'notie';
import { replaceAgreementContent, toCamelCase } from '../../helper/util';

const { SearchBar } = Search;
class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      User_ID: '',
      User_Agreement_ID: '',
      User_FullName: '',
      previewAgreement: '',
      agreements: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true,
          headerAttrs: { width: 50 },
          attrs: { width: 50, className: '' }
        },
        {
          dataField: 'comp_name',
          text: 'Company Name',
          sort: true,
          headerAttrs: { width: 120 },
          attrs: { width: 120, className: '' }
        },
        {
          dataField: 'agreement_title',
          text: 'Agreement Title',
          sort: true,
          headerAttrs: { width: 200 },
          attrs: { width: 200, className: '' }
        },
        {
          dataField: 'signee_name',
          text: 'Signee Name',
          sort: true,
          headerAttrs: { width: 120 },
          attrs: { width: 120, className: '' }
        },
        {
          dataField: 'signee_title',
          text: 'Signee Title',
          sort: true,
          headerAttrs: { width: 100 },
          attrs: { width: 100, className: '' }
        },

        {
          dataField: 'agreement_status',
          text: 'Status',
          sort: true,
          headerAttrs: { width: 60 },
          attrs: { width: 60, className: '' }
        },
        {
          dataField: 'signature_date',
          text: 'Signature Date',
          sort: true,
          headerAttrs: { width: 130 },
          attrs: { width: 130, className: '' }
        },

        {
          dataField: 'agreement_PDF',
          text: 'View',
          sort: false,
          formatter: this.signagreementPreviewFormatter,
          headerAttrs: { width: 35 },
          attrs: { width: 35, className: '' }
        },
        {
          dataField: 'approve',
          text: 'Accept',
          sort: false,
          formatter: this.agreementApproveFormatter,
          headerAttrs: { width: 35 },
          attrs: { width: 35, className: '' }
        },
        {
          dataField: 'decline',
          text: 'Reject',
          sort: false,
          formatter: this.agreementDeclineFormatter,
          headerAttrs: { width: 35 },
          attrs: { width: 35, className: '' }
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.agreementPreviewFormatter = this.agreementPreviewFormatter.bind(this);
    this.signagreementPreviewFormatter = this.signagreementPreviewFormatter.bind(
      this
    );

    this.viewAgreementData = this.viewAgreementData.bind(this);
    this.GetUserAgreements = this.GetUserAgreements.bind(this);
  }

  GetUserAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/GetAllSingeUserAgreements
    `;
    this.setState({
      loading: true
    });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          agreements: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  agreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.issigned === 'No' && row.issigneesigned === 'No' ? (
          <span
            className='fa fa-edit'
            onClick={() => this.viewAgreementData(row.user_agreement_id)}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  signagreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.issigned === 'Yes' || row.issigneesigned === 'Yes' ? (
          <a
            className='fa fa-eye'
            target='_blank'
            href={process.env.REACT_APP_BASE_URL + row.agreement_PDF}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  viewAgreementData(_User_Agreement_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/getAgreement?user_agreement_id=${_User_Agreement_ID}&user_id=${this.state.User_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            previewAgreement: ''
          });
          let objUser = {
            client_title: res.data[0].client_title,
            client_name: res.data[0].client_name,
            client_comp_name: res.data[0].client_comp_name,
            client_email: res.data[0].client_email,
            client_address: res.data[0].client_address,
            client_phone_no: res.data[0].client_phone_no,
            client_fax: res.data[0].client_fax,
            vendor_signor_name: res.data[0].vendor_signor_name,
            vendor_signor_comp_name: res.data[0].vendor_signor_comp_name,
            vendor_signor_title: res.data[0].vendor_signor_title,
            vendor_signor_address: res.data[0].vendor_signor_address,
            vendor_signor_email: res.data[0].vendor_signor_email,
            vendor_signor_phone_no: res.data[0].vendor_signor_phone_no,
            vendor_signor_fax: res.data[0].vendor_signor_fax,
            user_comp_name: res.data[0].user_comp_name,
            user_comp_name_short: res.data[0].user_comp_name_short,
            user_address: res.data[0].user_address
          };
          this.setState({
            previewAgreement: replaceAgreementContent(
              res.data[0].agreement_content,
              objUser
            ),
            User_Agreement_ID: res.data[0].user_agreement_id,
            User_FullName: res.data[0].client_name
          });

          $('#preview_agreement').show();
        });
    } catch (error) {}
  }

  agreementApproveFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.agreement_status === 'Pending' ? (
          <span
            className='fa fa-check'
            style={{ color: 'green' }}
            onClick={() =>
              notie.confirm({
                text: 'Warning: DO YOU WANT TO ACCEPT THIS AGREEMENT ?',
                submitText: 'Yes', // optional, default = 'Yes'
                cancelText: 'No', // optional, default = 'Cancel'
                position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: () =>
                  this.approveAgreement(
                    row.user_agreement_id,
                    row.agreement_title,
                    row.first_name,
                    row.email
                  ) // optional
              })
            }
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  agreementDeclineFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.agreement_status === 'Pending' ? (
          <span
            className='fa fa-times'
            style={{ color: 'red' }}
            onClick={() =>
              // notie.confirm({
              //   text: 'Warning: DO YOU WANT TO DECLINE THIS AGREEMENT ?',
              //   submitText: 'Yes', // optional, default = 'Yes'
              //   cancelText: 'No', // optional, default = 'Cancel'
              //   position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              //   submitCallback: () =>
              //     this.declineAgreement(
              //       row.user_agreement_id,
              //       row.agreement_title,
              //       row.first_name,
              //       row.email
              //     ) // optional
              // })

              notie.input({
                text: 'DO YOU WANT TO REJECT THIS AGREEMENT ?',
                submitText: 'Reject', // optional, default = 'Submit'
                cancelText: 'Cancel', // optional, default = 'Cancel'
                position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: value => {
                  this.declineAgreement(
                    row.agreement_id,
                    row.user_agreement_id,
                    row.agreement_title,
                    row.first_name,
                    row.email,
                    value
                  );
                }, // optional
                placeholder: 'Enter Rejection Reason', // default: ''
                autofocus: 'true', // default: 'true'
                max: '10000', // default: ''
                maxlength: '1000', // default: ''
                min: '10', // default: ''
                minlength: '10', // default: ''
                type: 'text'
              })
            }
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  approveAgreement(
    _User_Agreement_ID,
    _Agreement_Title,
    _Client_Name,
    _Client_Email
  ) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/ApproveAgreement`;

      let objUserAgreemnet = {
        user_agreement_id: _User_Agreement_ID,
        agreement_title: _Agreement_Title,
        client_name: _Client_Name,
        client_email: _Client_Email
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };
      axios.put(api, objUserAgreemnet, headers).then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        if (res.data.success === true) {
          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.GetUserAgreements();
        } else {
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  declineAgreement(
    _Agreement_ID,
    _User_Agreement_ID,
    _Agreement_Title,
    _Client_Name,
    _Client_Email,
    _Decline_Reason
  ) {
    try {
      if (_Decline_Reason === '') {
        return notie.alert({
          type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Rejection Reason is Required.',
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/DeclineAgreement`;

      let objUserAgreemnet = {
        agreement_id: _Agreement_ID,
        user_agreement_id: _User_Agreement_ID,
        agreement_title: _Agreement_Title,
        client_name: _Client_Name,
        client_email: _Client_Email,
        decline_reason: _Decline_Reason
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };
      axios.put(api, objUserAgreemnet, headers).then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        if (res.data.success === true) {
          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.GetUserAgreements();
        } else {
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  componentWillMount = () => {
    //console.log('UserAgreements will mount');
  };

  componentDidMount = () => {
    this.GetUserAgreements();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='container-fluid'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  All Signed Agreements
                </h4>
              </div>
              <hr />
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='../assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='user_agreement_id'
                          data={this.state.agreements}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                delay={1000}
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
