const moment = require('moment');
const $ = require('jquery');

export function replaceAgreementContent(strContent, ObjContent) {
  // ...
  let COMPANY_SIGN = `<img id = 'imgSig' src=[[IMG_CLIENT_SIGN]] width = '230px' heigth='100px' alt = 'Signature' style='display:none' class='clsimgSig'/>`;
  let VENDOR_SIGN = `<img id = 'imgVendorSig' src=[[IMG_VENDOR_SIGN]] width = '230px' heigth='100px' alt = 'VendorSignature' style='display:none'/>`;

  let _strContent = strContent
    .replace(
      /\[\[CLIENT_SIGNOR_TITLE\]\]/g,
      `<label class='lblClientTitle'>${ObjContent.client_title}</label>`
    )
    .replace(
      /\[\[CLIENT_SIGNOR_NAME\]\]/g,
      `<label class='lblClientName'>${ObjContent.client_name}</label>`
    )
    .replace(/\[\[CLIENT_COMPANY_NAME\]\]/g, ObjContent.client_comp_name)
    .replace(/\[\[CLIENT_EMAIL\]\]/g, ObjContent.client_email)
    .replace(/\[\[CLIENT_ADDRESS\]\]/g, ObjContent.client_address)
    .replace(/\[\[CLIENT_PHONENO\]\]/g, ObjContent.client_phone_no)
    .replace(/\[\[CLIENT_FAX\]\]/g, ObjContent.client_fax)
    .replace(/\[\[CLIENT_SIGN\]\]/g, COMPANY_SIGN)
    .replace(/\[\[CONTRACT_DATE\]\]/g, moment().format('MMMM Do YYYY'))
    .replace(/\[\[VENDOR_SIGNOR_TITLE\]\]/g, ObjContent.vendor_signor_title)
    .replace(/\[\[VENDOR_SIGNOR_NAME\]\]/g, ObjContent.vendor_signor_name)
    .replace(/\[\[VENDOR_SIGNOR_ADDRESS\]\]/g, ObjContent.vendor_signor_address)
    .replace(
      /\[\[VENDOR_SIGNOR_PHONENO\]\]/g,
      ObjContent.vendor_signor_phone_no
    )
    .replace(/\[\[VENDOR_SIGNOR_FAX\]\]/g, ObjContent.vendor_signor_fax)
    .replace(/\[\[VENDOR_SIGNOR_EMAIL\]\]/g, ObjContent.vendor_signor_email)
    //.replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, VENDOR_SIGN)
    .replace(/\[\[VENDOR_SIGNOR_SIGN\]\]/g, 'VENDOR SIGNATURE SHOWN HERE')
    .replace(
      /\[\[VENDOR_CONTRACT_DATE\]\]/g,
      'VENDOR SIGNATURE DATE SHOWN HERE'
    )
    .replace(
      /\[\[VENDOR_SIGNOR_COMPANY_NAME\]\]/g,
      ObjContent.vendor_signor_comp_name
    )
    .replace(/\[\[USER_COMPANY_NAME\]\]/g, ObjContent.user_comp_name)
    .replace(
      /\[\[USER_COMPANY_NAME_SHORT\]\]/g,
      ObjContent.user_comp_name_short
    )
    .replace(/\[\[USER_ADDRESS\]\]/g, ObjContent.user_address);

  // var strMsgScript = _strContent.match(/\[\{(.*?)\}\]/g);
  // strMsgScript = strMsgScript[0]
  //   .replace(/\[/g, '')
  //   .replace(/\]/g, '')
  //   .replace(/&quot;/g, '"');

  // let _JSON = JSON.parse(strMsgScript);
  // console.log(_JSON.type);

  return CreateControl(_strContent);
}

function CreateControl(strMessageScript) {
  try {
    //====================CREATE BUTTON ======================================
    if (strMessageScript === '') {
      return strMessageScript;
    }
    let listControls = '[';
    $.map(strMessageScript.match(/\[\{(.*?)\}\]/g), function(value) {
      if (value !== '' && value !== null) {
        let ControlTag = value
          .replace(/\[/g, '')
          .replace(/\]/g, '')
          .replace(/&quot;/g, '"');
        //.replace(/&#39;/g, "'");

        let _JSON = JSON.parse(ControlTag);
        var ControlType = _JSON.type;
        //console.log('ControlType', ControlType);
        if (ControlType === 'textbox') {
          listControls += ControlTag + ',';
          //console.log('_JSON', _JSON.type);
          let required = '';
          if (_JSON.req === 'true') {
            required = 'required';
          }
          let _Control_HTML = `<input  type="text" style="${_JSON.style}" name="${_JSON.id}" id="${_JSON.id}" ${required} />`;
          //console.log('_Control_HTML', _Control_HTML);
          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        } else if (ControlType === 'textarea') {
          listControls += ControlTag + ',';
          //console.log('_JSON', _JSON.type);
          let required = '';
          if (_JSON.req === 'true') {
            required = 'required';
          }
          let _Control_HTML = `<textarea  name="${_JSON.id}" id="${_JSON.id}"  style="${_JSON.style}" ${required} ></textarea>`;
          //console.log('_Control_HTML', _Control_HTML);
          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        } else if (ControlType === 'checkbox') {
          listControls += ControlTag + ',';
          let checkboxData = _JSON.data.split(',');
          //console.log('checkboxData', checkboxData);
          let _Control_HTML = '<table style="width:100%"><tr>';
          let checkAll = '';
          for (let index = 0; index < checkboxData.length; index++) {
            //console.log('checkboxData[index]', checkboxData[index]);
            if (
              checkboxData[index].replace(/&#39;/g, '') === 'All of the Above'
            ) {
              checkAll = `onClick="checkAll('${_JSON.id}','${
                _JSON.id
              }-${checkboxData[index].replace(/&#39;/g, '')}')"`;
            }
            _Control_HTML += `<td><label style='margin:10px'><input style='margin:5px' ${checkAll} type="checkbox" name="${
              _JSON.id
            }" id="${_JSON.id}-${checkboxData[index].replace(
              /&#39;/g,
              ''
            )}" value="${checkboxData[index].replace(
              /&#39;/g,
              ''
            )}" />${checkboxData[index].replace(/&#39;/g, '')}</label></td>`;
            if (index % 2) {
              _Control_HTML += '</tr><tr>';
            }
            //console.log('_Control_HTML', _Control_HTML);
          }
          _Control_HTML += '</table>';
          // checkboxData.forEach(element => {
          //   if (element.replace(/&#39;/g, '') === 'All of the Above') {
          //     checkAll = `onClick="checkAll('${_JSON.id}','${element.replace(
          //       /&#39;/g,
          //       ''
          //     )}')"`;
          //   }
          //   _Control_HTML += `<label style='margin:10px'><input style='margin:3px' ${checkAll} type="checkbox" name="${
          //     _JSON.id
          //   }" id="${element.replace(/&#39;/g, '')}" value="${element.replace(
          //     /&#39;/g,
          //     ''
          //   )}" />${element.replace(/&#39;/g, '')}</label>`;
          //   //console.log('_Control_HTML', _Control_HTML);
          // });
          strMessageScript = strMessageScript.replace(value, _Control_HTML);
        }
      }
    });
    listControls = listControls.replace(/,\s*$/, '') + ']';
    //console.log('listControls', listControls);
    strMessageScript += `<input type='hidden' id='hdlistControl' value='${listControls}' />`;
    return strMessageScript;
  } catch (error) {
    return strMessageScript;
  }
}

export function load_script(src) {
  return new Promise(function(resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function() {
      resolve();
    });
    script.addEventListener('error', function(e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
}

export function toCamelCase(str) {
  if (str !== '' && str !== undefined && str !== null) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
}
