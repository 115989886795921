import React from 'react';
import notie from 'notie';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import './UserOrderForm.css';
import $ from 'jquery';
class UserOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Comp_Name: this.props.Comp_Name,
      Comp_Phone_No: this.props.Comp_Phone_No,
      Comp_Address: this.props.Comp_Address,
      Order_Form_Instruction: '',
      Order_Form_Contact_Info_Title: '',
      Order_Form_Contact_Info: '',
      Order_Form_Site_Info_Title: '',
      Order_Form_Site_Info: '',
      Factory_Acceptance_Checklist: '',
      Customer_Site_Acceptance_Plan: '',
      Current_Date: moment().format('MM-DD-YYYY'),
      Products: [],

      contact_company_name: '',
      contact_company_telephone: '',
      contact_accounting_address: '',
      contact_billing_contact: '',
      contact_provisoning_contact: '',
      contact_network_ip_activation_support: '',
      contact_network_tdm_activation_support: '',
      contact_trouble_reporting_contact: '',
      contact_trouble_reporting_alternate: '',
      contact_trouble_reporting_email: '',
      contact_email_sale_engineer: '',

      customer_company_name: '',
      customer_title: '',
      customer_full_name: '',
      customer_contact_number: '',
      customer_address: '',
      transport_order_form_company_name: '',
      transport_order_form_carrier_type: '',
      transport_order_form_requested_due_date: '',
      transport_order_form_requested_contact_number: '',
      transport_order_form_requested_order_type: '',
      transport_order_form_requested_contact_email: '',
      ip_tof_switch_type_interconnection: '',
      ip_tof_other: '',
      ip_tof_services_ip_ranges: '',
      ip_tof_capacity_sessions: '',
      ip_tof_estimated_mou: '',
      ip_tof_lnp_dips: '',
      ip_tof_lrn_route: '',
      ip_tof_max_ptime: '',
      ip_tof_fax_preference: '',
      ip_tof_num_plan: '',
      ip_tof_sil_sup_required: '',
      ip_tof_ip_route_protocol: '',
      ip_tof_bgp_ip_ranges: '',
      ip_tof_sip_rtp_ranges: '',
      ip_tof_ven_cus_require: '',
      ip_tof_reject_code: '',
      ip_tof_cdr_rep_interval: '',
      ip_tof_call_rejects: '',
      ip_tof_dtmf_handling: '',
      netblock_ip_address: '',
      customer_ip_address1: '',
      requested_ports_ses_count1: '',
      requested_call_per_sec1: '',
      default_port_number1: '',
      preferred_stat_routing1: '',
      rtp_paketization_time1: '',
      description1: '',
      product1: '',
      customer_ip_address2: '',
      requested_ports_ses_count2: '',
      requested_call_per_sec2: '',
      default_port_number2: '',
      preferred_stat_routing2: '',
      rtp_paketization_time2: '',
      description2: '',
      product2: '',
      customer_ip_address3: '',
      requested_ports_ses_count3: '',
      requested_call_per_sec3: '',
      default_port_number3: '',
      preferred_stat_routing3: '',
      rtp_paketization_time3: '',
      description3: '',
      product3: '',
      customer_ip_address4: '',
      requested_ports_ses_count4: '',
      requested_call_per_sec4: '',
      default_port_number4: '',
      preferred_stat_routing4: '',
      rtp_paketization_time4: '',
      description4: '',
      product4: '',
      hdproduct_1: '',
      hdproduct_title_1: '',
      addProduct_1: '',
      addProduct_1_Selected: '',

      hdproduct_2: '',
      hdproduct_title_2: '',
      addProduct_2: '',
      addProduct_2_Selected: '',

      hdproduct_3: '',
      hdproduct_title_3: '',
      addProduct_3: '',
      addProduct_3_Selected: '',

      hdproduct_4: '',
      hdproduct_title_4: '',
      addProduct_4: '',
      addProduct_4_Selected: '',

      hdCalled: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.GetOrderFormData = this.GetOrderFormData.bind(this);
    this.GetUserData = this.GetUserData.bind(this);
    this.GetAllProducts = this.GetAllProducts.bind(this);

    this.GetOrderFormInstruction = this.GetOrderFormInstruction.bind(this);
    this.GetFactoryAcceptanceChecklist = this.GetFactoryAcceptanceChecklist.bind(
      this
    );
    this.GetCustomerSiteAcceptancePlan = this.GetCustomerSiteAcceptancePlan.bind(
      this
    );
  }

  GetAllProducts() {
    const api = `${process.env.REACT_APP_BASE_URL}products/api/DDProduct`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);

        this.setState({
          Products: res.data
        });

        this.GetOrderFormInstruction();
        this.GetOrderFormContactInfo();
        this.GetOrderFormSiteInfo();
        this.GetOrderFormData();
        this.GetFactoryAcceptanceChecklist();
        this.GetCustomerSiteAcceptancePlan();
      });
  }

  GetUserData() {
    Object.keys(localStorage).forEach(function(key) {
      if (!/^tcc_/.test(key)) {
        localStorage.removeItem(key);
      }
    });
    const api = `${process.env.REACT_APP_BASE_URL}users/api/getUserByUserID`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        if (res.data[0] !== undefined) {
          $('#customer_company_name').val(res.data[0].comp_name);
          $('#customer_title').val(res.data[0].title);
          $('#customer_full_name').val(res.data[0].first_name);
          $('#customer_contact_number').val(res.data[0].phone_number);
          $('#customer_address').val(res.data[0].address);
        }

        this.setState({
          loading: false
        });
      });
  }

  GetOrderFormData() {
    const api = `${process.env.REACT_APP_BASE_URL}order_form/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        if (res.data[0] !== undefined) {
          //console.log(res.data[0].contact_company_name);

          this.setState({
            loading: true
          });

          $('#customer_company_name').val(res.data[0].customer_company_name);
          $('#customer_title').val(res.data[0].customer_title);
          $('#customer_full_name').val(res.data[0].customer_full_name);
          $('#customer_contact_number').val(
            res.data[0].customer_contact_number
          );
          $('#customer_address').val(res.data[0].customer_address);
        }

        this.setState({
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    localStorage.setItem(target.name, value);
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleOptionChange = changeEvent => {
    const target = changeEvent.target;

    const value = target.type === 'radio' ? target.value : target.checked;

    const name = target.name;
    console.log('target.name', target.name, 'target.value', value);
    this.setState({
      [name]: value
    });
  };

  GetOrderFormInstruction() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=2`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = '/client_login');
      }
      if (res.data.success === false) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Order_Form_Instruction: res.data[0].cms_template
      });
    });
  }

  GetFactoryAcceptanceChecklist() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=7`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = '/client_login');
      }
      if (res.data.success === false) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Factory_Acceptance_Checklist: res.data[0].cms_template
      });
    });
  }

  GetCustomerSiteAcceptancePlan() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=8`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = '/client_login');
      }
      if (res.data.success === false) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Customer_Site_Acceptance_Plan: res.data[0].cms_template
      });
    });
  }

  GetOrderFormContactInfo() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=5`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = '/client_login');
      }
      if (res.data.success === false) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Order_Form_Contact_Info: res.data[0].cms_template,
        Order_Form_Contact_Info_Title: res.data[0].cms_template_title
      });
    });
  }

  GetOrderFormSiteInfo() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=9`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = '/client_login');
      }
      if (res.data.success === false) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Order_Form_Site_Info: res.data[0].cms_template,
        Order_Form_Site_Info_Title: res.data[0].cms_template_title
      });
    });
  }

  componentWillMount = () => {
    //console.log('UserCreditForm will mount');
  };

  componentDidMount = () => {
    let script = document.createElement('script');
    script.src = 'assets/examples/js/forms/wizard_order.js';
    document.body.appendChild(script);

    $('.active_left_menu').removeClass('active_left_menu');
    $('#lnkorder').addClass('active_left_menu');

    document.body.appendChild(script);
    this.GetUserData();
    this.GetAllProducts();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('UserCreditForm will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('UserCreditForm will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('UserCreditForm did update');
  };

  componentWillUnmount = () => {
    //console.log('UserCreditForm will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div>
        <div className='loadingDiv' style={{ display: 'none' }} />
        <div className='page-header'>
          <h1 className='page-title'>Site Info</h1>
        </div>
        <input
          type='hidden'
          id='hdCalled'
          name='hdCalled'
          onChange={this.handleChange}
        />
        <div className='page-content'>
          <div className='panel' id='exampleWizardForm'>
            <div className='panel-body'>
              <div
                className='steps steps-sm row '
                data-plugin='matchHeight'
                data-by-row='true'
                role='tablist'
              >
                <div
                  className='step col-lg-2 current'
                  data-target='#customer'
                  role='tab'
                >
                  <span className='step-number'>1</span>
                  <div className='step-desc'>
                    <span className='step-title'>Instructions</span>
                  </div>
                </div>

                <div className='step col-lg-2' data-target='#bank' role='tab'>
                  <span className='step-number'>2</span>
                  <div className='step-desc'>
                    <span className='step-title'>Contacts</span>
                  </div>
                </div>
                <div
                  className='step col-lg-2'
                  data-target='#Guarantee'
                  role='tab'
                >
                  <span className='step-number'>3</span>
                  <div className='step-desc'>
                    <span className='step-title'>SOW</span>
                  </div>
                </div>
                <div className='step col-lg-3' data-target='#fat' role='tab'>
                  <span className='step-number'>4</span>
                  <div className='step-desc'>
                    <span className='step-title'>FAT</span>
                  </div>
                </div>
                <div className='step col-lg-3' data-target='#cat' role='tab'>
                  <span className='step-number'>5</span>
                  <div className='step-desc'>
                    <span className='step-title'>CAT</span>
                  </div>
                </div>
              </div>

              <div className='wizard-content'>
                <div
                  className='wizard-pane active'
                  id='customer'
                  role='tabpanel'
                >
                  <form id='customerform'>
                    <div className='col-lg-12'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.Order_Form_Instruction
                        }}
                      />
                    </div>
                  </form>
                </div>

                <div className='wizard-pane' id='bank' role='tabpanel'>
                  <h4>Contact Information</h4>
                  <form id='bankform'>
                    <div className='col-lg-12'>
                      <div className='mb-30'>
                        <div
                          className='panel-group'
                          id='exampleWizardAccordion'
                          aria-multiselectable='true'
                          role='tablist'
                        >
                          <div className='panel'>
                            <div
                              className='panel-heading'
                              id='exampleHeading1'
                              role='tab'
                            >
                              <a
                                className='panel-title'
                                data-toggle='collapse'
                                href='#exampleCollapse1'
                                data-parent='#exampleWizardAccordion'
                                aria-expanded='true'
                                aria-controls='exampleCollapse1'
                              >
                                <h5
                                  dangerouslySetInnerHTML={{
                                    __html: this.state
                                      .Order_Form_Contact_Info_Title
                                  }}
                                />
                              </a>
                            </div>
                            <div
                              className='panel-collapse collapse show'
                              id='exampleCollapse1'
                              aria-labelledby='exampleHeading1'
                              role='tabpanel'
                            >
                              <div
                                className='panel-body'
                                dangerouslySetInnerHTML={{
                                  __html: this.state.Order_Form_Contact_Info
                                }}
                              />
                            </div>
                          </div>
                          <div className='panel'>
                            <div
                              className='panel-heading'
                              id='exampleHeading2'
                              role='tab'
                            >
                              <a
                                className='panel-title collapsed'
                                data-toggle='collapse'
                                href='#exampleCollapse2'
                                data-parent='#exampleWizardAccordion'
                                aria-expanded='false'
                                aria-controls='exampleCollapse2'
                              >
                                <h5>Customer information</h5>
                              </a>
                            </div>
                            <div
                              className='panel-collapse collapse'
                              id='exampleCollapse2'
                              aria-labelledby='exampleHeading2'
                              role='tabpanel'
                            >
                              <div className='panel-body'>
                                <div className='row'>
                                  <div className='form-group col-md-6'>
                                    <label
                                      className='form-control-label'
                                      htmlFor='customer_company_name'
                                    >
                                      Company Name:
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      onChange={this.handleChange}
                                      id='customer_company_name'
                                      name='customer_company_name'
                                    />
                                  </div>
                                  <div className='form-group col-md-6'>
                                    <label
                                      className='form-control-label'
                                      htmlFor='customer_title'
                                    >
                                      Title:
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      onChange={this.handleChange}
                                      id='customer_title'
                                      name='customer_title'
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='form-group col-md-6'>
                                    <label
                                      className='form-control-label'
                                      htmlFor='customer_full_name'
                                    >
                                      Full Name:
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      onChange={this.handleChange}
                                      id='customer_full_name'
                                      name='customer_full_name'
                                    />
                                  </div>
                                  <div className='form-group col-md-6'>
                                    <label
                                      className='form-control-label'
                                      htmlFor='customer_contact_number'
                                    >
                                      Contact Number:
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      onChange={this.handleChange}
                                      id='customer_contact_number'
                                      name='customer_contact_number'
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='form-group col-md-12'>
                                    <label
                                      className='form-control-label'
                                      htmlFor='customer_address'
                                    >
                                      Address:
                                    </label>
                                    <textarea
                                      type='text'
                                      className='form-control'
                                      onChange={this.handleChange}
                                      id='customer_address'
                                      name='customer_address'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className='wizard-pane' id='Guarantee' role='tabpanel'>
                  <form id='Guaranteeform'>
                    <div className='col-lg-12'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.Order_Form_Site_Info
                        }}
                      />
                    </div>
                  </form>
                </div>
                {/** FORTH TAB */}
                <div className='wizard-pane' id='fat' role='tabpanel'>
                  <form id='fatform'>
                    <div className='col-lg-12'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.Factory_Acceptance_Checklist
                        }}
                      />
                    </div>
                  </form>
                </div>
                {/** FIFTH TAB */}
                <div className='wizard-pane' id='cat' role='tabpanel'>
                  <form id='catform'>
                    <div className='col-lg-12'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.Customer_Site_Acceptance_Plan
                        }}
                      />
                    </div>
                    <input
                      type='button'
                      id='btnSuccess'
                      name='btnSuccess'
                      onClick={() => {
                        notie.alert({
                          type: 'info',
                          text: $('#hdCalled').val(),
                          time: 3,
                          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        });
                        this.props.history.push(`/client_dashboard`);
                      }}
                      style={{ visibility: 'hidden' }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserOrderForm;
