import React, { useState } from "react";
import { Route, Redirect, NavLink } from "react-router-dom";
import lifecycle from "react-pure-lifecycle";
import { AuthConsumer } from "../../context/AuthContext";
import $ from "jquery";
import notie from "notie";
import axios from "axios";
import AdminLogin from "../../pages/AdminLogin";
import "./AdminMainLayout.css";

const methods = {
  componentDidMount(props) {
    document.body.classList.remove("page-login-v2", "layout-full", "page-dark");
    document.body.classList.add("site-navbar-small");
  }
};

const AdminMainLayout = ({ children, ...rest }) => {
  let [_txtOldPassword, setOldPassword] = useState("");
  let [_txtNewPassword, setNewPassword] = useState("");
  let [_txtConfirmPassword, setConfirmPassword] = useState("");

  function changePassword(event) {
    event.preventDefault();
    const matches = _txtNewPassword === _txtConfirmPassword;
    matches
      ? console.log("matched")
      : notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "New Password & Confirm Password Not Same",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });

    if (matches) {
      try {
        let objPasswordChange = {
          email: localStorage.getItem("tcc_user_email"),
          user_id: localStorage.getItem("tcc_user_id"),
          old_password: _txtOldPassword,
          new_password: _txtNewPassword
        };
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}users/api/changeAdminPassword`,
          data: objPasswordChange,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
          .then(res => {
            if (res.data.success === false) {
              return <Redirect to="/tcaccess" />;
            }
            let _data = res.data;
            if (_data.error === true) {
              return notie.alert({
                type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: _data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
            if (_data.affectedRows === 1) {
              notie.alert({
                type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: "Password Changed Successfully",
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
              $("#change_password").hide();
            } else {
              notie.alert({
                type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: _data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
          })
          .catch(function(response) {
            //handle error
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: response.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          });
      } catch (error) {
        notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: error,
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
    }
  }

  function inputPassword(event) {
    if (event.target.name === "txtOldPassword") {
      setOldPassword((_txtOldPassword = event.target.value));
    } else if (event.target.name === "txtNewPassword") {
      setNewPassword((_txtNewPassword = event.target.value));
    } else if (event.target.name === "txtConfirmPassword") {
      setConfirmPassword((_txtConfirmPassword = event.target.value));
    }
  }

  return (
    <AuthConsumer>
      {({ isAuth, login, logout, Comp_Name, Comp_Logo, Quotation_JSON }) => (
        <React.Fragment>
          <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggler collapsed"
                data-target="#site-navbar-collapse"
                data-toggle="collapse"
              >
                <i className="icon wb-more-horizontal" aria-hidden="true" />
              </button>

              <div
                className="navbar-brand navbar-brand-center site-gridmenu-toggle"
                data-toggle="gridmenu"
              >
                <img className="navbar-brand-logo" alt="logo" src={Comp_Logo} />
              </div>
            </div>

            <div className="navbar-container container-fluid">
              <div
                className="collapse navbar-collapse navbar-collapse-toolbar"
                id="site-navbar-collapse"
              >
                <ul className="nav navbar-toolbar navbar-toolbar-right">
                  <li className="nav-item dropdown">
                    <NavLink to="dashboard" className="nav-link" role="button">
                      {" "}
                      <span className="fa fa-home" />
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink to="agents" className="nav-link" role="button">
                      {" "}
                      <span className="fa fa-user-secret">&nbsp;</span>
                      Agents
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink to="clients" className="nav-link" role="button">
                      {" "}
                      <span className="fa fa-users">&nbsp;</span>
                      Clients
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <NavLink to="agreements" className="nav-link" role="button">
                      {" "}
                      <span className="fa fa-certificate">&nbsp;</span>
                      Agreements
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink to="products" className="nav-link" role="button">
                      {" "}
                      <span className="fa fa-shopping-bag">&nbsp;</span>
                      Products
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link"
                      data-toggle="dropdown"
                      href="#"
                      title="Messages"
                      aria-expanded="false"
                      data-animation="scale-up"
                      role="button"
                    >
                      <i className="fa fa-id-badge" aria-hidden="true"></i>
                      Vendor
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-center dropdown-menu-media"
                      role="menu"
                    >
                      <div className="dropdown-menu-header">
                        <NavLink
                          to="admin_vendor_signor"
                          role="button"
                          style={{ color: "black !important" }}
                        >
                          {" "}
                          <span className="fas fa-file-signature">&nbsp;</span>
                          Vendor Signor
                        </NavLink>
                      </div>

                      <div className="dropdown-menu-header">
                        <NavLink
                          to="admin_vendor_contact"
                          role="button"
                          style={{ color: "black !important" }}
                        >
                          {" "}
                          <span className="fa fa-user-circle">&nbsp;</span>
                          Vendor Contact
                        </NavLink>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <NavLink
                      to="admin_canned_email"
                      className="nav-link"
                      role="button"
                    >
                      {" "}
                      <span className="fas fa-envelope">&nbsp;</span>
                      Canned Email
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink to="admin_cms" className="nav-link" role="button">
                      {" "}
                      <span className="fas fa-address-card">&nbsp;</span>
                      CMS
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      to="admin_gallery"
                      className="nav-link"
                      role="button"
                    >
                      {" "}
                      <span className="fas fa-image">&nbsp;</span>
                      Gallery
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown d-block d-none d-sm-block d-md-none">
                    <NavLink
                      to="admin_settings"
                      className="nav-link"
                      role="button"
                    >
                      {" "}
                      <span className="fas fa-cog">&nbsp;</span>
                      Settings
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown d-block d-none d-sm-block d-md-none">
                    <a
                      className="nav-link"
                      href="#"
                      title="Change Password"
                      role="button"
                      onClick={() => $("#change_password").show()}
                    >
                      <i className="fa fa-key" aria-hidden="true" />
                      Change Password
                    </a>
                  </li>

                  <li className="nav-item dropdown d-block d-none d-sm-block d-md-none">
                    <a
                      className="nav-link"
                      href="#"
                      title="Log Out"
                      role="button"
                      onClick={logout}
                    >
                      <i className="fa fa-power-off" aria-hidden="true" />
                      Log
                    </a>
                  </li>
                </ul>
                <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                  <li className="nav-item hidden-float" id="toggleMenubar">
                    <a
                      className="nav-link"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="false"
                      data-animation="scale-up"
                      role="button"
                    >
                      <i className="icon wb-user-circle" aria-hidden="true" />
                    </a>
                    <div className="dropdown-menu" role="menu">
                      <a
                        className="dropdown-item"
                        href="#"
                        role="menuitem"
                        onClick={() => $("#change_password").show()}
                      >
                        <i className="fa fa-key" aria-hidden="true" />
                        Change Password
                      </a>
                      <div className="dropdown-divider" role="presentation" />
                      <NavLink
                        to="admin_settings"
                        className="dropdown-item "
                        role="menuitem"
                      >
                        <span className="fas fa-cog" /> Settings
                      </NavLink>
                      <div className="dropdown-divider" role="presentation" />
                      <a
                        className="dropdown-item"
                        href="#"
                        role="menuitem"
                        onClick={logout}
                      >
                        <i className="icon wb-power" aria-hidden="true" />{" "}
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="page flx clr">
            <div
              className="page-content container"
              style={{ minHeight: "580px" }}
            >
              {children}
            </div>
          </div>
          <form id="frmChangePassword" onSubmit={changePassword}>
            <div className="modal myModal" id="change_password">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 id="heading" className="modal-title ml-40">
                      Change Password
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => $("#change_password").hide()}
                    >
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="container-fluid">
                      <div className="form-group row ">
                        <label
                          htmlFor="txtOldPassword"
                          className="col-md-4 label-clr mt-2"
                        >
                          Old Password:
                        </label>
                        <input
                          type="password"
                          id="txtOldPassword"
                          className="form-control col-md-6"
                          name="txtOldPassword"
                          placeholder="Enter Old Password"
                          onChange={inputPassword}
                          required
                        />
                      </div>
                      <div className="form-group row ">
                        <label
                          htmlFor="txtNewPassword"
                          className="col-md-4 label-clr mt-2"
                        >
                          New Password:
                        </label>
                        <input
                          type="password"
                          id="txtNewPassword"
                          className="form-control col-md-6"
                          name="txtNewPassword"
                          placeholder="Enter New Password"
                          onChange={inputPassword}
                          required
                        />
                      </div>
                      <div className="form-group row ">
                        <label
                          htmlFor="txtConfirmPassword"
                          className="col-md-4 label-clr mt-2"
                        >
                          Confirm Password:
                        </label>
                        <input
                          type="password"
                          id="txtConfirmPassword"
                          className="form-control col-md-6"
                          name="txtConfirmPassword"
                          placeholder="Re-Enter New Password"
                          onChange={inputPassword}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => $("#change_password").hide()}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-success">
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <footer className="site-footer">
            <div className="site-footer-legal">
              <span id="spComp_Name">
                Hungry Tech & Automation LLC. A wholly owned subsidiary of
                Hungry Tech Inc.
              </span>
            </div>
          </footer>
        </React.Fragment>
      )}
    </AuthConsumer>
  );
};

const AdminMainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({ isAuth, User_ID, Quotation_JSON }) => (
        <Route
          render={matchProps =>
            isAuth ? (
              <AdminMainLayout>
                <Component
                  {...matchProps}
                  User_ID={User_ID}
                  Quotation_JSON={Quotation_JSON}
                />
              </AdminMainLayout>
            ) : (
              <Redirect to="/tcaccess" component={AdminLogin} />
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(AdminMainLayoutRoute);
