import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import CKEditor from 'ckeditor4-react';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../components/FixRequiredSelect';
import './AgentManageProducts.css';
const { SearchBar } = Search;
const Select = props => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} />
);
const CKEconfig = {
  allowedContent: true,
  skin:'kama',
  embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
  image2_alignClasses: ['image-align-left', 'image-align-center', 'image-align-right'],
  image2_disableResizer: true,
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing']
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
    },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
    { name: 'font', groups: ['font'] },
    '/',
    '/'
  ],
  extraPlugins: 'print,format,font,justify,emoji,embed,autoembed,image2'
};
class AgentManageProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      User_ID: this.props.location.state.User_ID,
      hasError: false,
      addProductTitle: '',
      addProductDesc: '',
      addProductRate: '',
      addProductFile: [],
      addisActive: 1,
      addisDashboard: 1,
      addConnectedAgreementID: '',
      Connected_Agreements: [],
      editProductTitle: '',
      editConnectedAgreementID: '',
      editProductDesc: '',
      editProductRate: '',
      editProductFile: [],
      editisActive: 0,
      editisDashboard: 0,
      Product_ID: '',
      Products: [],
      Select_Product_Files: [],
      selectedOption: null,
      selectedEditOption: null,
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'product_title',
          text: 'Product Title',
          sort: true
        },

        {
          dataField: 'isactive',
          text: 'Active',
          sort: true,
          headerAttrs: { width: 100, style: { textAlign: 'center' } },
          attrs: { width: 100, style: { textAlign: 'center' } }
        },
        {
          dataField: 'isdashboard',
          text: 'Show on Dashboard',
          sort: true,
          headerAttrs: { width: 150, style: { textAlign: 'center' } },
          attrs: { width: 150, style: { textAlign: 'center' } }
        },
        {
          dataField: 'show',
          text: '',
          sort: false,
          formatter: this.productFileFormatter,
          headerAttrs: { width: 100, style: { textAlign: 'center' } },
          attrs: { width: 100, style: { textAlign: 'center' } }
        }
        /**,{
          dataField: 'edit',
          text: 'Edit',
          sort: false,
          formatter: this.productFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          sort: false,
          formatter: this.productDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        }*/
      ],
      columnsProductFiles: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'product_file_name',
          text: 'File Name',
          sort: true
        },
        {
          dataField: 'view_file',
          text: '',
          sort: false,
          formatter: this.productFileViewFormatter,
          headerAttrs: { width: 50 },
          attrs: { width: 50, className: '' }
        }
        /** ,
        {
          dataField: 'delete_file',
          text: '',
          sort: false,
          formatter: this.productFileDeleteFormatter,
          headerAttrs: { width: 50 },
          attrs: { width: 50, className: '' }
        }
        */
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getProductData = this.getProductData.bind(this);
    this.onChangeaddUpload = this.onChangeaddUpload.bind(this);
    this.onChangeeditUpload = this.onChangeeditUpload.bind(this);
    this.getProductFiles = this.getProductFiles.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.addConnectedAgreementSetValues = this.addConnectedAgreementSetValues.bind(
      this
    );
  }

  GetAllProducts() {
    const api = `${process.env.REACT_APP_BASE_URL}products/api/agent?user_id=${this.state.User_ID}`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcagent');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Products: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAdd(e) {
    e.preventDefault();

    try {
      const _Files = Array.from(this.state.addProductFile);
      var objProduct = new FormData();

      _Files.forEach((file, i) => {
        objProduct.append(i, file);
      });
      objProduct.append('product_title', this.state.addProductTitle);
      objProduct.append('product_desc', this.state.addProductDesc);
      objProduct.append('product_rate', this.state.addProductRate);
      objProduct.append('isactive', this.state.addisActive);
      objProduct.append('isdashboard', this.state.addisDashboard);
      objProduct.append('productsFiles', this.state.addProductFile);
      objProduct.append(
        'connectedagreements',
        this.state.addConnectedAgreementID
      );

      //console.log('this.state.addSignCheck', this.state.addSignCheck);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}products/api/`,
        data: objProduct,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Product Added Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_product').hide();
            //$('.modal-backdrop').remove();
            this.GetAllProducts();
            $('#addProductFile').val('');
            this.setState({
              addProductTitle: '',
              addProductDesc: '',
              addProductRate: '',
              addProductFile: [],
              addConnectedAgreementID: '',
              addisActive: 1,
              addisDashboard: 1,
              selectedOption: null
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  productFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getProductData(row.product_id)}
        />
      </div>
    );
  };

  productFileFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-eye'
          onClick={() => this.getProductFiles(row.product_id)}
        />
      </div>
    );
  };

  productFileViewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <a
          className='fa fa-eye'
          style={{ textDecoration: 'none' }}
          target='_blank'
          href={process.env.REACT_APP_BASE_URL + row.product_file_path}
        />
      </div>
    );
  };

  productDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Warning: DO YOU WANT TO DELETE THIS PRODUCT ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deleteProductData(row.product_id) // optional
            })
          }
        />
      </div>
    );
  };

  productFileDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Are you sure to delete this record ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deleteProductFile(row.product_file_id) // optional
            })
          }
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();
    try {
      const _Files = Array.from(this.state.editProductFile);
      var objUpdateProduct = new FormData();

      _Files.forEach((file, i) => {
        objUpdateProduct.append(i, file);
      });
      objUpdateProduct.append('product_id', this.state.Product_ID);
      objUpdateProduct.append('product_title', this.state.editProductTitle);
      objUpdateProduct.append('product_desc', this.state.editProductDesc);
      objUpdateProduct.append('product_rate', this.state.editProductRate);
      objUpdateProduct.append('isactive', this.state.editisActive);
      objUpdateProduct.append('isdashboard', this.state.editisDashboard);
      objUpdateProduct.append('productsFiles', this.state.editProductFile);
      objUpdateProduct.append(
        'connectedagreements',
        this.state.editConnectedAgreementID
      );

      axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}products/api/`,
        data: objUpdateProduct,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#edit_product').hide();

            this.GetAllProducts();
            $('#editProductFile').val('');
            this.setState({
              editProductTitle: '',
              editProductDesc: '',
              editProductRate: '',
              editProductFile: [],
              editConnectedAgreementID: '',
              editisActive: 0,
              editisDashboard: 0
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getProductData(_Product_ID) {
    try {
      this.setState({
        editProductTitle: '',
        editProductDesc: '',
        editProductRate: '',
        selectedEditOption: null,
        editisActive: 0,
        editisDashboard: 0
      });

      this.setState({ Product_ID: _Product_ID });
      const api = `${process.env.REACT_APP_BASE_URL}products/api/getProduct?product_id=${_Product_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $('#edit_product').show();
          this.setState({
            editProductTitle: res.data.product[0].product_title,
            editProductDesc: res.data.product[0].product_desc,
            editProductRate: res.data.product[0].product_rate,
            editisActive: res.data.product[0].isactive,
            editisDashboard: res.data.product[0].isdashboard,
            selectedEditOption: res.data.agreements
          });
        });
    } catch (error) {}
  }

  getProductFiles(_Product_ID) {
    try {
      this.setState({
        loading: true,
        Product_ID: _Product_ID
      });
      const api = `${process.env.REACT_APP_BASE_URL}products/api/agent/getProductFiles?product_id=${_Product_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            this.setState({
              loading: false
            });
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            Select_Product_Files: res.data,
            loading: false
          });
          $('#product_files').show();
        });
    } catch (error) {}
  }

  deleteProductData(_Product_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}products/api/?product_id=${_Product_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllProducts();
          }
        });
    } catch (error) {}
  }

  deleteProductFile(_Product_File_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}products/api/getProductFiles/?product_file_id=${_Product_File_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Product File Deleted Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.getProductFiles(this.state.Product_ID);
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Some problem in deleting record.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  onChangeaddUpload = event => {
    this.setState({
      addProductFile: [...this.state.addProductFile, ...event.target.files]
    });
  };

  onChangeeditUpload = event => {
    this.setState({
      editProductFile: [...this.state.editProductFile, ...event.target.files]
    });
  };

  onChangeAdd(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      addProductDesc: newContent
    });
  }

  onChangeEdit(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      editProductDesc: newContent
    });
  }

  GetConnectedAgreements() {
    this.setState({
      Connected_Agreements: []
    });
    const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getConnectedAgreements`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcagent');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);
        this.setState({
          Connected_Agreements: res.data,
          loading: false
        });
      });
  }

  addConnectedAgreementSetValues = selectedOption => {
    this.setState({ selectedOption });
    if (selectedOption !== null) {
      selectedOption.forEach(element => {
        this.setState({
          addConnectedAgreementID: [
            ...this.state.addConnectedAgreementID,
            element.value
          ]
        });
      });
    } else {
      this.setState({ addConnectedAgreementID: '' });
    }
  };

  editConnectedAgreementSetValues = selectedEditOption => {
    this.setState({ selectedEditOption });
    if (selectedEditOption !== null) {
      console.log('selectedEditOption', selectedEditOption);
      this.setState({ editConnectedAgreementID: '' });
      let selectedAgreements = '';
      selectedEditOption.forEach(element => {
        selectedAgreements += element.value + ',';
      });
      this.setState({
        editConnectedAgreementID: selectedAgreements.replace(/,\s*$/, '')
      });
    } else {
      this.setState({ editConnectedAgreementID: '' });
    }
  };

  componentWillMount = () => {};

  componentDidMount = () => {
    this.GetAllProducts();
    //this.GetConnectedAgreements();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading, selectedOption, selectedEditOption } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Products
                </h4>
              </div>
              {/** 
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    this.setState({ selectedOption: null });
                    $('#add_product').show();
                  }}
                >
                  Add Product
                </button>
              </div>*/}
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='product_id'
                          data={this.state.Products}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleAdd} encType='multipart/form-data'>
          <div
            className='modal myModal AddProduct'
            id='add_product'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Add Product</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_product').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='addProductTitle'
                        className='col-sm-2 control-labels'
                      >
                        Product Title:
                      </label>
                      <input
                        type='text'
                        id='addProductTitle'
                        className='form-control col-md-9'
                        name='addProductTitle'
                        value={this.state.addProductTitle}
                        onChange={this.handleChange}
                        maxLength='150'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addProductAgreements'
                        className='col-sm-2 control-labels'
                      >
                        Connected Agreement(s):
                      </label>
                      <Select
                        id='ddladdConnectedAgreement'
                        value={selectedOption}
                        className='col-md-9'
                        onChange={this.addConnectedAgreementSetValues}
                        placeholder='Select Connected Agreement(s)'
                        options={this.state.Connected_Agreements}
                        isMulti='true'
                        isSearchable='true'
                        closeMenuOnSelect='false'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addProductDesc'
                        className='col-sm-2 control-labels'
                      >
                        Product Description:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <CKEditor
                          type='classic'
                          data={this.state.addProductDesc}
                          onChange={this.onChangeAdd}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addProductRate'
                        className='col-sm-2 control-labels'
                      >
                        Default Rate:
                      </label>
                      <input
                        type='text'
                        pattern='^[-+]?[0-9]\d*(\.\d+)?$'
                        id='addProductRate'
                        className='form-control col-md-2'
                        name='addProductRate'
                        value={this.state.addProductRate}
                        title='Sample 0.0015'
                        onChange={this.handleChange}
                      />
                      <span style={{ padding: '5px' }}>$</span>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addProductFile'
                        className='col-sm-2 control-labels'
                      >
                        Upload Files:
                      </label>
                      <input
                        type='file'
                        id='addProductFile'
                        className=' col-md-9'
                        name='addProductFile'
                        onChange={this.onChangeaddUpload}
                        style={{ paddingLeft: 0 }}
                        accept='.xls,.xlsx,.txt,.doc,.docx,.ppt,.pptx,.png,.jpg,.jpeg,.pdf,.zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
                        multiple
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addisActive'
                        className='col-sm-2 control-labels'
                      >
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addisActive'
                          name='addisActive'
                          checked={this.state.addisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addisDashboard'
                        className='col-sm-2 control-labels'
                      >
                        Show on Dashboard:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addisDashboard'
                          name='addisDashboard'
                          checked={this.state.addisDashboard}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#add_product').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit} encType='multipart/form-data'>
          <div
            className='modal myModal EditProduct'
            id='edit_product'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Edit Product</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_product').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='editProductTitle'
                        className='col-sm-2 control-labels'
                      >
                        Product Title:
                      </label>
                      <input
                        type='text'
                        id='editProductTitle'
                        className='form-control col-md-9'
                        name='editProductTitle'
                        value={this.state.editProductTitle}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='ddleditConnectedAgreement'
                        className='col-sm-2 control-labels'
                      >
                        Connected Agreement(s):
                      </label>
                      <Select
                        id='ddleditConnectedAgreement'
                        value={selectedEditOption}
                        className='col-md-9'
                        onChange={this.editConnectedAgreementSetValues}
                        placeholder='Select Connected Agreement(s)'
                        options={this.state.Connected_Agreements}
                        isMulti='true'
                        isSearchable='true'
                        closeMenuOnSelect='false'
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editProductDesc'
                        className='col-sm-2 control-labels'
                      >
                        Product Description:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.editProductDesc}
                          onChange={this.onChangeEdit}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editProductRate'
                        className='col-sm-2 control-labels'
                      >
                        Default Rate:
                      </label>
                      <input
                        type='text'
                        pattern='^[-+]?[0-9]\d*(\.\d+)?$'
                        id='editProductRate'
                        className='form-control col-md-2'
                        name='editProductRate'
                        value={this.state.editProductRate}
                        title='Sample 0.0015'
                        onChange={this.handleChange}
                      />
                      <span style={{ padding: '5px' }}>$</span>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editProductFile'
                        className='col-sm-2 control-labels'
                      >
                        Upload Files:
                      </label>
                      <input
                        type='file'
                        id='editProductFile'
                        className='col-md-9'
                        name='editProductFile'
                        onChange={this.onChangeeditUpload}
                        style={{ paddingLeft: 0 }}
                        accept='.xls,.xlsx,.txt,.doc,.docx,.ppt,.pptx,.png,.jpg,.jpeg,.pdf,.zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
                        multiple
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editisActive'
                        className='col-sm-2 control-labels'
                      >
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editisActive'
                          name='editisActive'
                          checked={this.state.editisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editisDashboard'
                        className='col-sm-2 control-labels'
                      >
                        Show on Dashboard:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editisDashboard'
                          name='editisDashboard'
                          checked={this.state.editisDashboard}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#edit_product').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div
          className='modal myModal ProductFiles '
          id='product_files'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <form id='frmProductFiles'>
            <div className='modal-dialog modal-dialog-centered  modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Product Files</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#product_files').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='col-lg-12'>
                      <div className='example-wrap'>
                        <div className='example'>
                          {loading ? (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                src='../assets/images/tcc_loading.gif'
                                style={{ width: '128px' }}
                                alt='...'
                              />
                            </div>
                          ) : (
                            <div>
                              <ToolkitProvider
                                keyField='product_file_id'
                                data={this.state.Select_Product_Files}
                                columns={this.state.columnsProductFiles}
                                search
                                columnToggle
                              >
                                {props => (
                                  <div>
                                    {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                    <SearchBar
                                      {...props.searchProps}
                                      className='custome-search-field'
                                      style={{ color: '#000' }}
                                      placeholder='Search ...'
                                    />
                                    <hr />
                                    <BootstrapTable
                                      wrapperClasses='table-responsive'
                                      {...props.baseProps}
                                      filter={filterFactory()}
                                      pagination={paginationFactory({
                                        sizePerPage: 10,
                                        nextPageText: 'Next',
                                        prePageText: 'Previous',
                                        sizePerPageList: [
                                          {
                                            text: '10 Per Page',
                                            value: 10
                                          },
                                          {
                                            text: '20 Per Page',
                                            value: 20
                                          },
                                          {
                                            text: 'All Record',
                                            value: 100
                                          }
                                        ]
                                      })}
                                      striped
                                      hover
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AgentManageProducts;
