import React, { useState } from 'react';
import { Route, Redirect, NavLink } from 'react-router-dom';
import lifecycle from 'react-pure-lifecycle';
import { AuthConsumer } from '../../context/AuthContextAgent';
import $ from 'jquery';
import notie from 'notie';
import axios from 'axios';
import InputMask from 'react-input-mask';
import AgentLogin from '../../pages/agent_module/AgentLogin';
import './AgentMainLayout.css';

const methods = {
  componentDidMount(props) {
    document.body.classList.remove('page-login-v2', 'layout-full', 'page-dark');
    document.body.classList.add('site-navbar-small');
  }
};

const AgentMainLayout = ({ children, ...rest }) => {
  let [_txtOldPassword, setOldPassword] = useState('');
  let [_txtNewPassword, setNewPassword] = useState('');
  let [_txtConfirmPassword, setConfirmPassword] = useState('');

  const [updateKey, setUpdateKey] = useState(1);
  const refreshControl = () => setUpdateKey(updateKey + 1);

  const [values, setValues] = useState({
    agent_comp_name: '',
    agent_name: '',
    agent_phone_no: '',
    agent_email: '',
    agent_title: '',
    agent_address: '',
    ambassador_id: ''
  });

  function handleEdit(e) {
    e.preventDefault();
    try {
      axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/getAgentByID`,
        data: values,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            refreshControl();
            $('#edit_agent').hide();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  function changePassword(event) {
    event.preventDefault();
    const matches = _txtNewPassword === _txtConfirmPassword;
    matches
      ? console.log('matched')
      : notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'New Password & Confirm Password Not Same',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });

    if (matches) {
      try {
        let objPasswordChange = {
          agent_email: localStorage.getItem('tcc_agent_email'),
          Agent_ID: localStorage.getItem('tcc_agent_ID'),
          old_password: _txtOldPassword,
          new_password: _txtNewPassword
        };
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}agents/api/changePassword`,
          data: objPasswordChange,

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
          .then(res => {
            if (res.data.logout === true) {
              return <Redirect to='/tcagent' />;
            }

            if (res.data.success === false) {
              return notie.alert({
                type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: res.data.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              });
            }

            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#change_password').hide();
          })
          .catch(function(response) {
            //handle error
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: response.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          });
      } catch (error) {
        notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: error,
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
    }
  }

  function inputPassword(event) {
    if (event.target.name === 'txtOldPassword') {
      setOldPassword((_txtOldPassword = event.target.value));
    } else if (event.target.name === 'txtNewPassword') {
      setNewPassword((_txtNewPassword = event.target.value));
    } else if (event.target.name === 'txtConfirmPassword') {
      setConfirmPassword((_txtConfirmPassword = event.target.value));
    }
  }
  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  function getAgentData() {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}agents/api/getAgentByID`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          setValues({
            ...values,
            agent_comp_name: res.data[0].agent_comp_name,
            agent_name: res.data[0].agent_name,
            agent_phone_no: res.data[0].agent_phone_no,
            agent_fax: res.data[0].agent_fax,
            agent_email: res.data[0].agent_email,
            agent_title: res.data[0].agent_title,
            agent_address: res.data[0].agent_address,
            ambassador_id: res.data[0].ambassador_id
          });

          $('#edit_agent').show();
        });
    } catch (error) {}
  }

  return (
    <AuthConsumer>
      {({
        isAuthAgent,
        Agent_ID,
        login,
        logout,
        agent_comp_name,
        Comp_Logo,
        isSiteinfo,
         Quotation_JSON
      }) => (
        <React.Fragment>
          <nav className='site-navbar navbar navbar-default navbar-fixed-top navbar-mega'>
            <div className='navbar-header'>
              <button
                type='button'
                className='navbar-toggler collapsed'
                data-target='#site-navbar-collapse'
                data-toggle='collapse'
              >
                <i className='icon wb-more-horizontal' aria-hidden='true' />
              </button>

              <div
                className='navbar-brand navbar-brand-center site-gridmenu-toggle'
                data-toggle='gridmenu'
              >
                <img className='navbar-brand-logo' alt='logo' src={Comp_Logo} />
              </div>
            </div>

            <div className='navbar-container container-fluid'>
              <div
                className='collapse navbar-collapse navbar-collapse-toolbar'
                id='site-navbar-collapse'
              >
                <ul className='nav navbar-toolbar navbar-toolbar-right'>
                  <li className='nav-item dropdown' style={{ display: 'none' }}>
                    <NavLink
                      to='agent_dashboard'
                      className='nav-link'
                      role='button'
                    >
                      {' '}
                      <span className='fa fa-home' />
                      Dashboard
                    </NavLink>
                  </li>

                  <li className='nav-item dropdown'>
                    <NavLink
                      to='agent_clients'
                      className='nav-link'
                      role='button'
                    >
                      {' '}
                      <span className='fa fa-users'>&nbsp;</span>
                      Clients
                    </NavLink>
                  </li>

                  <li className='nav-item dropdown d-block d-none d-sm-block d-md-none'>
                    <a
                      className='nav-link'
                      href='javascript:void(0)'
                      agent_title='Change Password'
                      role='button'
                      onClick={() => $('#change_password').show()}
                    >
                      <i className='fa fa-key' aria-hidden='true' />
                      Change Password
                    </a>
                  </li>

                  <li className='nav-item dropdown d-block d-none d-sm-block d-md-none'>
                    <a
                      className='nav-link'
                      href='javascript:void(0)'
                      agent_title='Log Out'
                      role='button'
                      onClick={logout}
                    >
                      <i className='fa fa-power-off' aria-hidden='true' />
                      Log
                    </a>
                  </li>
                </ul>
                <ul className='nav navbar-toolbar navbar-right navbar-toolbar-right'>
                  <li className='nav-item hidden-float' id='toggleMenubar'>
                    <a
                      className='nav-link'
                      data-toggle='dropdown'
                      href='#'
                      aria-expanded='false'
                      data-animation='scale-up'
                      role='button'
                    >
                      <i className='icon wb-user-circle' aria-hidden='true' />
                    </a>
                    <div className='dropdown-menu' role='menu'>
                      <a
                        className='dropdown-item'
                        href='javascript:void(0)'
                        role='menuitem'
                        onClick={() => $('#change_password').show()}
                      >
                        <i className='icon wb-lock' aria-hidden='true' />
                        Change Password
                      </a>
                      <a
                        className='dropdown-item'
                        href='javascript:void(0)'
                        role='menuitem'
                        onClick={() => getAgentData()}
                      >
                        <i className='icon wb-user' aria-hidden='true' /> Edit
                        Profile
                      </a>
                      <div className='dropdown-divider' role='presentation' />

                      <a
                        className='dropdown-item'
                        href='javascript:void(0)'
                        role='menuitem'
                        onClick={logout}
                      >
                        <i className='icon wb-power' aria-hidden='true' />{' '}
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className='page flx clr'>
            <div
              className='page-content container'
              style={{ minHeight: '580px' }}
            >
              {children}
            </div>
          </div>
          <form id='frmChangePassword' onSubmit={changePassword}>
            <div className='modal myModal' id='change_password'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 id='heading' className='modal-agent_title'>
                      Change Password
                    </h4>
                    <button
                      type='button'
                      className='close'
                      onClick={() => $('#change_password').hide()}
                    >
                      &times;
                    </button>
                  </div>

                  <div className='modal-body'>
                    <div className='container-fluid'>
                      <div className='form-group row '>
                        <label
                          htmlFor='txtOldPassword'
                          className='col-md-4 label-clr mt-2'
                        >
                          Old Password:
                        </label>
                        <input
                          type='password'
                          id='txtOldPassword'
                          className='form-control col-md-6'
                          name='txtOldPassword'
                          placeholder='Enter Old Password'
                          onChange={inputPassword}
                          required
                        />
                      </div>
                      <div className='form-group row '>
                        <label
                          htmlFor='txtNewPassword'
                          className='col-md-4 label-clr mt-2'
                        >
                          New Password:
                        </label>
                        <input
                          type='password'
                          id='txtNewPassword'
                          className='form-control col-md-6'
                          name='txtNewPassword'
                          placeholder='Enter New Password'
                          onChange={inputPassword}
                          required
                        />
                      </div>
                      <div className='form-group row '>
                        <label
                          htmlFor='txtConfirmPassword'
                          className='col-md-4 label-clr mt-2'
                        >
                          Confirm Password:
                        </label>
                        <input
                          type='password'
                          id='txtConfirmPassword'
                          className='form-control col-md-6'
                          name='txtConfirmPassword'
                          placeholder='Re-Enter New Password'
                          onChange={inputPassword}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-warning'
                      onClick={() => $('#change_password').hide()}
                    >
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-success'>
                      Save{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/** EDIT FORM */}
          <form onSubmit={handleEdit}>
            <div
              className='modal myModal edit_agent'
              id='edit_agent'
              style={{
                display: 'none',
                paddingLeft: '15px',
                overflow: 'auto'
              }}
            >
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 className='modal-title'>Edit Agent</h4>
                    <button
                      type='button'
                      className='close'
                      onClick={() => $('#edit_agent').hide()}
                    >
                      ×
                    </button>
                  </div>

                  <div className='modal-body'>
                    <div className='container-fluid'>
                      <div className='form-group row'>
                        <label htmlFor='ambassador_id' className='col-md-4'>
                          Ambassador ID:
                        </label>
                        <input
                          type='text'
                          id='editAmbassadorID'
                          className='form-control col-md-6'
                          name='editAmbassadorID'
                          value={values.ambassador_id}
                          onChange={handleInputChange}
                          readOnly
                          maxLength='150'
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='agent_comp_name' className='col-md-4'>
                          Agent Company:
                        </label>
                        <input
                          type='text'
                          id='agent_comp_name'
                          className='form-control col-md-6'
                          name='agent_comp_name'
                          value={values.agent_comp_name}
                          onChange={handleInputChange}
                          required
                          maxLength='150'
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='agent_name' className='col-md-4'>
                          Agent Name:
                        </label>
                        <input
                          type='text'
                          id='agent_name'
                          className='form-control col-md-6'
                          name='agent_name'
                          value={values.agent_name}
                          onChange={handleInputChange}
                          required
                          maxLength='150'
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='agent_title' className='col-md-4'>
                          Agent Title:
                        </label>
                        <input
                          type='text'
                          id='agent_title'
                          className='form-control col-md-6'
                          name='agent_title'
                          value={values.agent_title}
                          onChange={handleInputChange}
                          required
                          maxLength='100'
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='agent_email' className='col-md-4'>
                          Email Address:
                        </label>
                        <input
                          type='email'
                          id='agent_email'
                          className='form-control col-md-6'
                          name='agent_email'
                          value={values.agent_email}
                          readOnly
                          maxLength='250'
                        />
                      </div>

                      <div className='form-group row'>
                        <label htmlFor='agent_phone_no' className='col-md-4'>
                          Contact Number:
                        </label>
                        <InputMask
                          mask='99999999999'
                          pattern='.{10,}'
                          maskChar={null}
                          id='agent_phone_no'
                          className='form-control col-md-6'
                          name='agent_phone_no'
                          placeholder='Contact Number 2134567890'
                          title='Contact Number 2134567890'
                          value={values.agent_phone_no}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='agent_fax' className='col-md-4'>
                          Fax Number:
                        </label>

                        <InputMask
                          mask='99999999999'
                          pattern='.{10,}'
                          maskChar={null}
                          id='agent_fax'
                          className='form-control col-md-6'
                          name='agent_fax'
                          placeholder='Fax Number 12134567890'
                          title='Fax Number 12134567890'
                          value={values.agent_fax}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='agent_address' className='col-md-4'>
                          Address :
                        </label>
                        <textarea
                          id='agent_address'
                          className='form-control col-md-6'
                          name='agent_address'
                          placeholder='Address'
                          value={values.agent_address}
                          onChange={handleInputChange}
                          required
                          maxLength='250'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => $('#edit_agent').hide()}
                    >
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-success'>
                      Save{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <footer className='site-footer'>
            <div className='site-footer-legal'>
              <span id='spagent_comp_name'>
                Hungry Tech & Automation LLC. A wholly owned subsidiary of
                Hungry Tech Inc.
                <a
                  href='http://www.
Hungry1.com'
                  target='_blank'
                >
                  {' '}
                  www. Hungry1.com
                </a>
              </span>
            </div>
          </footer>
        </React.Fragment>
      )}
    </AuthConsumer>
  );
};

const AgentMainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({ isAuthAgent, Agent_ID, isSiteInfo,Quotation_JSON }) => (
        <Route
          render={matchProps =>
            isAuthAgent ? (
              <AgentMainLayout>
                <Component
                  {...matchProps}
                  Agent_ID={Agent_ID}
                  isSiteInfo={isSiteInfo}
                  Quotation_JSON={Quotation_JSON}
                />
              </AgentMainLayout>
            ) : (
              <Redirect to='/tcagent' component={AgentLogin} />
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(AgentMainLayoutRoute);
