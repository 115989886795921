import React from "react";
import notie from "notie";
import axios from "axios";
import $ from "jquery";

const AuthContext = React.createContext();

class AuthProviderDemo extends React.Component {
  constructor() {
    super();

    const api = `${process.env.REACT_APP_BASE_URL}quotation_json/api/getQuotaionJSON`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.json = JSON.parse(
          JSON.parse(JSON.stringify(res.data[0].quotation_json))
        );
        this.setState({
          Quotation_JSON: JSON.parse(
            JSON.parse(JSON.stringify(res.data[0].quotation_json))
          )
        });
      });

    this.state = {
      isAuthDemo: false,
      User_ID: 1,
      Demo_ID: 1,
      Demo_Name: "Demo Agent",
      Demo_Email: "demodemo@hungryautomation.com",
      Demo_Title: "Demo Agent",
      isSiteInfo: 1,
      Comp_Name: "Demo Company",
      Support_Email: "demo@hungryautomation.com",
      Comp_Phone_No: "1234567890",
      Comp_Address: "USA",
      Comp_Logo:
        "https://system.hungryautomation.com:5050/setting_images/1573684676638-HungryAutomation_Logo.png",
      Comp_Logo_Dashboard:
        "https://system.hungryautomation.com:5050/setting_images/1573684676638-HungryAutomation_Logo.png",
      Meta_Tags:
        "<title>Hungry Tech & Automation- Food Service Software and Robotics Solutions</title>"
    };

    //GET SETTING DATA

    this.demoLogin = this.demoLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.Reset_Password = this.Reset_Password.bind(this);
    this.Complete_Signup = this.Complete_Signup.bind(this);
  }

  demoLogin = async (e, Demo_Email, Demo_Password, isAuto) => {
    this.setState({
      isAuthDemo: true,
      User_ID: 1,
      Demo_ID: 1,
      Demo_Name: "Demo Agent",
      Demo_Email: "demodemo@hungryautomation.com",
      Demo_Title: "Demo Agent",
      isSiteInfo: 1,
      Comp_Name: "Demo Company",
      Support_Email: "demo@hungryautomation.com",
      Comp_Phone_No: "1234567890",
      Comp_Address: "USA",
      Comp_Logo:
        "https://system.hungryautomation.com:5050/setting_images/1573684676638-HungryAutomation_Logo.png",
      Comp_Logo_Dashboard:
        "https://system.hungryautomation.com:5050/setting_images/1573684676638-HungryAutomation_Logo.png",
      Meta_Tags:
        "<title>Hungry Tech & Automation- Food Service Software and Robotics Solutions</title>"
    });
  };

  Complete_Signup(event, User_Password, User_Confirm_Password, Token) {
    event.preventDefault();
    try {
    } catch (error) {}
  }

  Reset_Password(event, Demo_Password, Demo_Confirm_Password, Token) {
    event.preventDefault();
    try {
    } catch (error) {}
  }

  Verify_User(token) {
    try {
    } catch (error) {}
  }

  logout() {
    this.setState({ isAuthDemo: false });
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthDemo: this.state.isAuthDemo,
          isSiteInfo: this.state.isSiteInfo,
          Demo_ID: this.state.Demo_ID,
          Demo_Name: this.state.Demo_Name,
          Demo_Email: this.state.Demo_Email,
          Demo_Title: this.state.Demo_Title,
          demoLogin: this.demoLogin,
          Complete_Signup: this.Complete_Signup,
          Reset_Password: this.Reset_Password,
          logout: this.logout,
          Comp_Name: this.state.Comp_Name,
          Support_Email: this.state.Support_Email,
          Comp_Phone_No: this.state.Comp_Phone_No,
          Comp_Address: this.state.Comp_Address,
          Comp_Logo: this.state.Comp_Logo,
          Meta_Tags: this.state.Meta_Tags,
          Comp_Logo_Dashboard: this.state.Comp_Logo_Dashboard,
          Quotation_JSON: this.state.Quotation_JSON
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProviderDemo, AuthConsumer };
