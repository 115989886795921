import React, { Component } from 'react';
import { AuthConsumer } from '../../../context/AuthContextAgent';
import queryString from 'query-string';
import axios from 'axios';

class CompleteAgentSignup extends Component {
  constructor(props) {
    super(props);

    localStorage.setItem('tcc_agent_token', '');
    localStorage.setItem('tcc_agent_id', '');
    localStorage.setItem('tcc_agent_email', '');

    this.state = {
      hasError: false,
      token: '',
      addPassword: '',
      addConfirmPassword: '',
      isValid: true,
      PageLoad: false
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  componentWillMount = () => {};

  componentDidMount = () => {
    const values = queryString.parse(this.props.location.search);

    if (values.token !== '' && values.token !== undefined) {
      this.setState({ token: values.token });
      this.verifyLink(values.token);
    } else {
      this.setState({
        isValid: false,
        PageLoad: true
      });
    }
  };

  verifyLink(_Token) {
    const api = `${process.env.REACT_APP_BASE_URL}agents/api/complete_signup`;
    try {
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${_Token}`
          }
        })
        .then(res => {
          //console.log(res.data);

          if (res.data.success) {
            this.setState({
              isValid: true
            });
          } else {
            this.setState({
              isValid: false
            });
          }
          this.setState({
            PageLoad: true
          });
        });
    } catch (error) {}
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <AuthConsumer>
        {({ isAuthUser, Complete_Signup, Comp_Name, Support_Email }) => (
          <div>
            {this.state.PageLoad === true ? (
              <React.Fragment>
                <div className='col-md-12'>
                  <div className='example-wrap'>
                    <div className='example'>
                      <div
                        className='nav-tabs-horizontal nav-tabs-inverse'
                        data-plugin='tabs'
                      >
                        <ul
                          className='nav nav-tabs nav-tabs-solid'
                          role='tablist'
                        >
                          <li className='nav-item' role='presentation'>
                            <a
                              className='nav-link active show'
                              data-toggle='tab'
                              href='#exampleIconifiedTabsOne'
                              aria-controls='exampleIconifiedTabsOne'
                              role='tab'
                              aria-selected='true'
                            >
                              <i
                                className='icon fas fa-user-plus'
                                aria-hidden='true'
                              />
                              Complete Signup Process
                            </a>
                          </li>
                        </ul>
                        <div className='tab-content pt-15'>
                          <div
                            className='tab-pane active show'
                            id='exampleIconifiedTabsOne'
                            role='tabpanel'
                          >
                            {/*<h3 className='font-size-24'>User Sign In</h3>*/}
                            {this.state.isValid === true ? (
                              <React.Fragment>
                                <form
                                  onSubmit={e =>
                                    Complete_Signup(
                                      e,
                                      this.state.addPassword,
                                      this.state.addConfirmPassword,
                                      this.state.token
                                    )
                                  }
                                >
                                  <div className='form-group'>
                                    <label
                                      className='sr-only'
                                      htmlFor='txtPassword'
                                    >
                                      Password
                                    </label>
                                    <input
                                      type='password'
                                      className='form-control'
                                      id='addPassword'
                                      name='addPassword'
                                      placeholder='Password'
                                      pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                                      title='Min 8 Chars must contain 1 Number, 1 Lowercase & 1 Uppercase'
                                      required
                                      onChange={this.handleChange}
                                      value={this.state.addPassword}
                                    />
                                    <span
                                      style={{
                                        fontSize: '10px',
                                        marginLeft: '5px'
                                      }}
                                    >
                                      8+ characters. Mixed Case, Numbers and
                                      Symbols
                                    </span>
                                  </div>
                                  <div className='form-group'>
                                    <label
                                      className='sr-only'
                                      htmlFor='txtPassword'
                                    >
                                      Confirm Password
                                    </label>
                                    <input
                                      type='password'
                                      className='form-control'
                                      id='addConfirmPassword'
                                      name='addConfirmPassword'
                                      placeholder='Confirm Password'
                                      required
                                      onChange={this.handleChange}
                                      value={this.state.addConfirmPassword}
                                    />
                                  </div>
                                  <button
                                    type='submit'
                                    className='btn btn-success btn-block'
                                  >
                                    Continue
                                  </button>
                                </form>
                              </React.Fragment>
                            ) : (
                              <form>
                                <div className='form-group'>
                                  Sorry! this link has been expired. Please
                                  contact&nbsp;
                                  <a
                                    style={{ margin: '0' }}
                                    href={'mailto:' + Support_Email}
                                  >
                                    {Support_Email}
                                  </a>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-12'>
                  <div style={{ textAlign: 'center' }}>
                   Hungry Tech & Automation LLC. A wholly owned subsidiary of Hungry Tech Inc.
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default CompleteAgentSignup;
