import React from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "react-sidebar";
import $ from "jquery";
import axios from "axios";
import notie from "notie";
import InputMask from "react-input-mask";
import SignatureCanvas from "react-signature-canvas";
const mql = window.matchMedia(`(min-width: 800px)`);

const styles = {
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },
  content: {
    padding: "16px"
  }
};
class UserLeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Comp_Logo: props.Comp_Logo,
      docked: mql.matches,
      open: false,
      User_ID: props.User_ID,
      logout: props.logout,
      editCompName: "",
      editFirstName: "",
      editPhoneNo: "",
      editEmail: "",
      editTitle: "",
      editAddress: "",
      editSignature: "",
      editSignatureFile: "",
      editSignatureFileDisplay: "",
      editSign: "",
      editSignCheck: false,
      loading: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeeditUploadSign = this.onChangeeditUploadSign.bind(this);
    this.handleeditUpload = this.handleeditUpload.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleEditClear(e) {
    e.preventDefault();
    this.editSignaturePad.clear();
    this.setState({ editSign: "" });
  }

  handleeditUpload = () => {
    this.setState({ editSignCheck: !this.state.editSignCheck });
    if (this.state.editSignCheck) {
      $("#diveditUploadSign").show();
      $(".diveditSign").hide();
    } else {
      $("#diveditUploadSign").hide();
      $(".diveditSign").show();
    }
  };

  onChangeeditUploadSign = event => {
    //getting file object
    let file = event.target.files[0];

    const types = ["image/png", "image/jpeg"];
    let imgSize = 70000;
    // let imgWidth = 200;
    // let imgHeight = 200;

    let err = "";
    // list allow mime type
    if (file === undefined) {
      return false;
    }
    if (types.every(type => file.type !== type)) {
      // create error message and assign to container
      err += file.type + " is Not a Supported Format. Please Try PNG or JGPEG ";

      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }

    if (file.size > imgSize) {
      err += file.type + " is Too Large. Please Pick a MAX 70KB File";
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: err,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }

    if (err !== "") {
      event.target.value = null;
      console.log(err);
      return false;
    }

    this.setState({
      editSignatureFile: event.target.files[0]
    });

    console.log(this.state.editSignatureFile);
  };

  onSetOpen(open) {
    this.setState({ open });
  }

  toggleOpen(ev) {
    if (ev) {
      //ev.preventDefault();
    }
    this.setState({ open: !this.state.open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false
    });
  }

  getUserData(_User_ID) {
    try {
      this.setState({ loading: true });
      const api = `${process.env.REACT_APP_BASE_URL}users/api/getUserByUserID`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/client_login");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          this.setState({
            editCompName: res.data[0].comp_name,
            editFirstName: res.data[0].first_name,
            editPhoneNo: res.data[0].phone_number,
            editEmail: res.data[0].email,
            editTitle: res.data[0].title,
            editAddress: res.data[0].address,
            editSignatureFileDisplay: res.data[0].user_signature_file
          });
          $("#edit_user").show();
          this.setState({ loading: false });
        });
    } catch (error) {}
  }

  handleEdit(e) {
    e.preventDefault();
    try {
      this.setState({ loading: true });

      var body = new FormData();

      body.append("comp_name", this.state.editCompName);
      body.append("first_name", this.state.editFirstName);
      body.append("phone_number", this.state.editPhoneNo);
      body.append("title", this.state.editTitle);
      body.append("address", this.state.editAddress);
      body.append("siganture_type", this.state.editSignCheck);
      body.append(
        "user_signature",
        this.editSignaturePad.toDataURL("image/png")
      );
      body.append("user_signature_file", this.state.editSignatureFile);

      axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}users/api/`,
        data: body,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/client_login");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (res.data.success === true) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });

            $("#edit_user").hide();
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({ loading: false });
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        {loading ? <div className="loadingDiv" /> : ""}
        <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega">
          <div className="navbar-header">
            {!this.state.docked && (
              <button
                type="button"
                className="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
                onClick={this.toggleOpen}
                style={styles.contentHeaderMenuLink}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="hamburger-bar" />
              </button>
            )}
            <button
              type="button"
              className="navbar-toggler collapsed"
              data-target="#site-navbar-collapse"
              data-toggle="collapse"
            >
              <i className="icon wb-more-horizontal" aria-hidden="true" />
            </button>
            <div className="navbar-brand navbar-brand-center">
              <a href="https://hungryautomation.com">
                <img
                  className="navbar-brand-logo"
                  src={this.state.Comp_Logo}
                  alt="..."
                />
              </a>
            </div>
          </div>

          <div className="navbar-container container-fluid">
            <div
              className="collapse navbar-collapse navbar-collapse-toolbar"
              id="site-navbar-collapse"
            >
              <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link navbar-avatar"
                    data-toggle="dropdown"
                    href="#"
                    aria-expanded="false"
                    data-animation="scale-up"
                    role="button"
                  >
                    <i className="icon wb-user-circle" aria-hidden="true" />
                  </a>
                  <div className="dropdown-menu" role="menu">
                    <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      role="menuitem"
                      onClick={() => this.getUserData(this.state.User_ID)}
                    >
                      <i className="icon wb-user" aria-hidden="true" /> Edit
                      Profile
                    </a>
                    <a
                      className="dropdown-item"
                      href="javascript:void(0)"
                      role="menuitem"
                      onClick={() => $("#change_password").show()}
                    >
                      <i className="icon fa fa-key" aria-hidden="true" /> Change
                      Password
                    </a>
                    <div className="dropdown-divider" role="presentation" />
                    <a
                      className="dropdown-item"
                      role="menuitem"
                      onClick={() => this.state.logout()}
                    >
                      <i className="icon wb-power" aria-hidden="true" /> Log out
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Sidebar
          children=""
          sidebar={
            <div
              style={{
                height: "100%",
                backgroundColor: "#224B70"
              }}
            >
              <div className="site-menubar-user">
                <NavLink
                  id="lnkdashboard"
                  to="client_dashboard"
                  onClick={this.toggleOpen}
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i
                    className="site-menu-icon wb-dashboard"
                    aria-hidden="true"
                  />
                  <span className="site-menu-title">DASHBOARD</span>
                </NavLink>
                <NavLink
                  id="lnkservice"
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  to={{
                    pathname: "client_services",
                    state: { User_ID: this.state.User_ID }
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i
                    className="site-menu-icon  wb-library"
                    aria-hidden="true"
                  />
                  <span className="site-menu-title">PRODUCTS & SERVICES</span>
                </NavLink>
                <NavLink
                  id="lnkagreement"
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  to={{
                    pathname: "client_agreements",
                    state: { User_ID: this.state.User_ID }
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i className="site-menu-icon wb-file" aria-hidden="true" />
                  <span className="site-menu-title">AGREEMENTS</span>
                </NavLink>
                {this.props.Service == "Prepay" ? (
                  ""
                ) : (
                  <NavLink
                    id="lnkcredit"
                    style={{ color: "rgba(163, 175, 183, .9)" }}
                    to={{
                      pathname: "client_credit"
                    }}
                    onClick={this.toggleOpen}
                    className="nav-link"
                    role="button"
                  >
                    {" "}
                    <i
                      className="site-menu-icon  wb-contract"
                      aria-hidden="true"
                    />
                    <span className="site-menu-title"> CREDIT APP </span>
                  </NavLink>
                )}
                {/**
                <NavLink
                  id='lnkorder'
                  style={{ color: 'rgba(163, 175, 183, .9)', display: '' }}
                  to={{
                    pathname: 'client_order'
                  }}
                  onClick={this.toggleOpen}
                  className='nav-link'
                  role='button'
                >
                  {' '}
                  <i className='site-menu-icon  wb-order' aria-hidden='true' />
                  <span className='site-menu-title'> SITE INFO </span>
                </NavLink>*/}

                <NavLink
                  id="lnksiteinfo"
                  style={{ color: "rgba(163, 175, 183, .9)", display: "" }}
                  to={{
                    pathname: "client_site_info"
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i className="site-menu-icon  wb-order" aria-hidden="true" />
                  <span className="site-menu-title"> SITE INFO</span>
                </NavLink>

                <NavLink
                  id="lnkpicture"
                  style={{ color: "rgba(163, 175, 183, .9)", display: "" }}
                  to={{
                    pathname: "client_gallery"
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i className="site-menu-icon  wb-image" aria-hidden="true" />
                  <span className="site-menu-title"> GALLERY </span>
                </NavLink>

                <NavLink
                  id="lnkquotations"
                  style={{ color: "rgba(163, 175, 183, .9)", display: "" }}
                  to={{
                    pathname: "client_quotations"
                  }}
                  onClick={this.toggleOpen}
                  className="nav-link"
                  role="button"
                >
                  {" "}
                  <i
                    className="site-menu-icon   wb-payment"
                    aria-hidden="true"
                  />
                  <span className="site-menu-title"> QUOTATION </span>
                </NavLink>
                <a
                  className="nav-link"
                  style={{ color: "rgba(163, 175, 183, .9)" }}
                  href="javascript:void(0)"
                  onClick={() => this.state.logout()}
                >
                  <i className="site-menu-icon wb-power" aria-hidden="true" />
                  <span className="site-menu-title">LOG OUT</span>
                </a>
              </div>
            </div>
          }
          open={this.state.open}
          docked={this.state.docked}
          onSetOpen={this.onSetOpen}
        />
        {/** EDIT FORM */}

        <form onSubmit={this.handleEdit}>
          <div
            className="modal edit_user myModal"
            id="edit_user"
            style={{
              display: "none",
              paddingLeft: "15px",
              overflow: "auto"
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Edit Profile</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#edit_user").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label htmlFor="editEmail" className="col-md-3">
                        Company Email:
                      </label>
                      <input
                        type="text"
                        id="editEmail"
                        className="form-control col-md-7"
                        name="editEmail"
                        value={this.state.editEmail}
                        disabled
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editCompName" className="col-md-3">
                        Company Name:
                      </label>
                      <input
                        type="text"
                        id="editCompName"
                        className="form-control col-md-7"
                        name="editCompName"
                        value={this.state.editCompName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editTitle" className="col-md-3">
                        Title:
                      </label>
                      <input
                        type="text"
                        id="editTitle"
                        className="form-control col-md-7"
                        name="editTitle"
                        value={this.state.editTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editFirstName" className="col-md-3">
                        Full Name:
                      </label>
                      <input
                        type="text"
                        id="editFirstName"
                        className="form-control col-md-7"
                        name="editFirstName"
                        value={this.state.editFirstName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editPhoneNo" className="col-md-3">
                        Contact Number:
                      </label>

                      <InputMask
                        mask="99999999999"
                        pattern=".{10,}"
                        maskChar={null}
                        id="editPhoneNo"
                        className="form-control col-md-7"
                        name="editPhoneNo"
                        placeholder="Contact Number 2134567890"
                        title="Contact Number 2134567890"
                        value={this.state.editPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="editAddress" className="col-md-3">
                        Address:
                      </label>
                      <textarea
                        id="editAddress"
                        className="form-control col-md-7"
                        name="editAddress"
                        placeholder="Service Address: City, State, Zip"
                        value={this.state.editAddress}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3">Signature:</label>
                      {this.state.editSignatureFileDisplay === null ? (
                        "No Signature"
                      ) : (
                        <img
                          style={{ height: "50px" }}
                          alt="Signature"
                          src={`${process.env.REACT_APP_BASE_URL}${this.state.editSignatureFileDisplay}`}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4" htmlFor="chkeditUpload">
                        Manual Signature: (check box to add signature)
                      </label>
                      <div className="isnotified:0,">
                        <input
                          type="checkbox"
                          id="chkeditUpload"
                          checked={this.state.editSignCheck}
                          onChange={this.handleeditUpload}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">or</label>
                    </div>
                    <div id="diveditUploadSign" className="form-group row">
                      <label htmlFor="editSignatureFile" className="col-md-4">
                        Upload Signature:
                      </label>
                      <input
                        type="file"
                        id="editSignatureFile"
                        className="col-md-6"
                        name="editSignatureFile"
                        onChange={this.onChangeeditUploadSign}
                        style={{ paddingLeft: 0 }}
                        accept="image/*"
                      />
                    </div>
                    <div
                      className="form-group row diveditSign"
                      style={{ display: "none" }}
                    >
                      <label htmlFor="editSignature" className="col-md-4">
                        Sign Here:
                      </label>
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: 300,
                          height: 200,
                          className: "sigCanvas col-md-6"
                        }}
                        ref={ref => (this.editSignaturePad = ref)}
                      />
                    </div>
                    <div
                      className="form-group row diveditSign"
                      style={{ display: "none" }}
                    >
                      <label className="col-md-4" />

                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={this.handleEditClear.bind(this)}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#edit_user").hide()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default UserLeftMenu;
