import React, { Component } from 'react';
import { AuthConsumer } from '../../../context/AuthContextDemo';
import $ from 'jquery';
import notie from 'notie';
import axios from 'axios';
import './Demo.css';
class DemodemoLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.demoForgotPassword = this.demoForgotPassword.bind(this);
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    return true;
  }

  demoForgotPassword(e) {
    e.preventDefault();

    try {
      let objforgotPassword = {
        demo_email: this.state.txtForgotEmail
      };
      
            this.setState({ ForgotEmail: this.state.txtForgotEmail });
            $('#forgot_password').hide();
            $('#forgot_password_message').show();
            this.setState({ txtForgotEmail: '' });

    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }
  render() {
    return (
      <AuthConsumer>
        {({ isAuthDemo, demoLogin, logout, Comp_Name, Support_Email }) => (
          <div>
            {isAuthDemo === false ? (
              <div>
                <h3 className='font-size-24'>Demo Sign In</h3>
                <form
                  onSubmit={e =>
                    demoLogin(
                      e,
                      document.getElementById('inputUserName').value,
                      document.getElementById('inputPassword').value,
                      false
                    )
                  }
                >
                  <div className='form-group'>
                    <label className='sr-only' htmlFor='inputUserName'>
                      User Name
                    </label>
                    <input
                      className='form-control'
                      id='inputUserName'
                      name='inputUserName'
                      placeholder='User Name'
                      onChange={this.handleChange}
                      value={this.state.value}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label className='sr-only' htmlFor='inputPassword'>
                      Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='inputPassword'
                      name='inputPassword'
                      placeholder='Password'
                      onChange={this.handleChange}
                      value={this.state.value}
                      required
                    />
                  </div>
                  <div className='form-group clearfix'>
                    {/* <div className='checkbox-custom checkbox-inline checkbox-primary float-left'>
                  <input type='checkbox' id='rememberMe' name='rememberMe' />
                  <label htmlFor='rememberMe'>Remember Me</label>
        </div>**/}
                    <a
                      href='javascrip:void(0)'
                      style={{ cursor: 'pointer' }}
                      className='float-right'
                      onClick={() => $('#forgot_password').show()}
                    >
                      Forgot password?
                    </a>
                    <a
                      href='/'
                      style={{ cursor: 'pointer' }}
                      className='float-right'
                    >
                      Goto Client Login
                    </a>
                  </div>
                  <button
                    type='submit'
                    data-time='1.5'
                    className='btn btn-primary btn-block'
                  >
                    Sign in
                  </button>
                </form>
                <form
                  id='frmChangePassword'
                  onSubmit={this.demoForgotPassword}
                >
                  <div className='modal myMode' id='forgot_password'>
                    <div className='modal-dialog modal-dialog-centered'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h4 id='heading' className='modal-title'>
                            Forgot Password?
                          </h4>
                          <button
                            type='button'
                            className='close'
                            onClick={() => $('#forgot_password').hide()}
                          >
                            ×
                          </button>
                        </div>

                        <div className='modal-body'>
                          <div className='container-fluid'>
                            <div className='form-group row '>
                              <label
                                htmlFor='txtForgotEmail'
                                className='col-md-2 label-clr mt-2'
                              >
                                Email:
                              </label>
                              <input
                                type='email'
                                id='txtForgotEmail'
                                className='form-control col-md-8'
                                name='txtForgotEmail'
                                placeholder='Enter Email Address'
                                required
                                onChange={this.handleChange}
                                value={this.state.txtForgotEmail}
                              />
                            </div>
                            <div className='form-group'>
                              Enter the email used to register your account
                              Trouble with Email Address ? contact &nbsp;
                              <a
                                style={{ margin: '0' }}
                                href={'mailto:' + Support_Email}
                              >
                                {Support_Email}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-warning'
                            onClick={() => $('#forgot_password').hide()}
                          >
                            Cancel
                          </button>
                          <button type='submit' className='btn btn-success'>
                            Send{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className='modal myMode' id='forgot_password_message'>
                  <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 id='heading' className='modal-title ml-10'>
                          Check Your Email
                        </h4>
                        <button
                          type='button'
                          className='close'
                          onClick={() => $('#forgot_password_message').hide()}
                        >
                          ×
                        </button>
                      </div>

                      <div className='modal-body'>
                        <div className='container-fluid'>
                          <div className='form-group'>
                            Instructions on changing your password were sent to
                            you at:
                            <br />
                            <br />
                            <strong>{this.state.ForgotEmail}</strong>
                            <br />
                            <br />
                            When you receive this email, click on Reset Password
                            link which will return you to {Comp_Name} website.
                          </div>
                        </div>
                      </div>
                      <div
                        className='modal-footer'
                        style={{ paddingRight: '15px' }}
                      >
                        <button
                          type='button'
                          className='btn btn-warning'
                          onClick={() => $('#forgot_password_message').hide()}
                        >
                          Close
                        </button>
                        <button
                          type='button'
                          className='btn btn-info'
                          style={{ display: 'none' }}
                        >
                          I don't have access to this email address
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default DemodemoLogin;
