import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import InputMask from 'react-input-mask';
import './ManageAgents.css';
const { SearchBar } = Search;

class ManageAgent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addAmbassadorID: '',
      addAgentCompName: '',
      addAgentName: '',
      addAgentTitle: '',
      addAgentEmail: '',
      addAgentPhoneNo: '',
      addAgentFax: '',
      addAgentAddress: '',
      addisActive: 0,
      addisSiteInfo: 0,
      editAmbassadorID: '',
      editAgentCompName: '',
      editAgentName: '',
      editAgentTitle: '',
      editAgentEmail: '',
      editAgentPassword: '',
      editAgentPhoneNo: '',
      editAgentFax: '',
      editAgentAddress: '',
      editisActive: 0,
      editisSiteInfo: 0,
      Agent_ID: '',
      Agents: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'agent_name',
          text: 'Agent Name',
          sort: true
        },
        {
          dataField: 'agent_title',
          text: 'Agent Title',
          sort: true
        },

        {
          dataField: 'isactive',
          text: 'Active',
          sort: true
        },
        {
          dataField: 'status',
          text: 'Verified',
          sort: true,
          formatter: this.viewActiveFormatter,
          headerAttrs: { width: 95, style: { textAlign: 'center' } },
          attrs: {
            style: { textAlign: 'center' }
          }
        },
        {
          dataField: 'edit',
          text: 'Edit',
          sort: false,
          formatter: this.agentFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          sort: false,
          formatter: this.agentDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAgentData = this.getAgentData.bind(this);
  }

  GetAllAgent() {
    const api = `${process.env.REACT_APP_BASE_URL}agents/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Agents: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAdd(e) {
    e.preventDefault();

    try {
      let objAgent = {
        ambassador_id: this.state.addAmbassadorID,
        agent_comp_name: this.state.addAgentCompName,
        agent_name: this.state.addAgentName,
        agent_title: this.state.addAgentTitle,
        agent_email: this.state.addAgentEmail,
        agent_phone_no: this.state.addAgentPhoneNo,
        agent_fax: this.state.addAgentFax,
        agent_address: this.state.addAgentAddress,
        isactive: this.state.addisActive,
        is_siteinfo: this.state.addisSiteInfo
      };

      //console.log('this.state.addSignCheck', this.state.addSignCheck);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/`,
        data: objAgent,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
          $('#add_agent').hide();
          //$('.modal-backdrop').remove();
          this.GetAllAgent();

          this.setState({
            addAmbassadorID: '',
            addAgentCompName: '',
            addAgentName: '',
            addAgentTitle: '',
            addAgentEmail: '',
            addAgentPhoneNo: '',
            addAgentFax: '',
            addAgentAddress: '',
            addisActive: 1,
            addisSiteInfo: 0
          });
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  agentFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getAgentData(row.agent_id)}
        />
      </div>
    );
  };

  agentDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Are you sure to delete this record ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deleteAgentData(row.agent_id) // optional
            })
          }
        />
      </div>
    );
  };

  viewActiveFormatter = (cell, row) => {
    return row.isagentverified === 'Yes' ? (
      'Yes'
    ) : (
      <div
        style={{
          textAlign: 'left',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          onClick={() =>
            notie.confirm({
              text: 'Are you sure you want to RESEND the verification email?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.resendVerifyEmail(
                  row.agent_id,
                  row.agent_email,
                  row.agent_name,
                  row.agent_title,
                  row.agent_comp_name
                ) // optional
            })
          }
        >
          Resend <span className='fa fa-envelope' />
        </span>
      </div>
    );
  };

  resendVerifyEmail(
    _Agent_ID,
    _Agent_Email,
    _Agent_Name,
    _Agent_Title,
    _Agent_Comp_Name
  ) {
    try {
      let objAgent = {
        agent_id: _Agent_ID,
        agent_email: _Agent_Email,
        agent_name: _Agent_Name,
        agent_title: _Agent_Title,
        comp_name: _Agent_Comp_Name
      };

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/adminResendVerifiedEmail`,
        data: objAgent,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      }).then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        } else {
          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  handleEdit(e) {
    e.preventDefault();
    try {
      let objUdpateAgent = {
        agent_id: this.state.Agent_ID,
        ambassador_id: this.state.editAmbassadorID,
        agent_comp_name: this.state.editAgentCompName,
        agent_name: this.state.editAgentName,
        agent_title: this.state.editAgentTitle,

        agent_phone_no: this.state.editAgentPhoneNo,
        agent_fax: this.state.editAgentFax,
        //agent_email: this.state.editAgentEmail,
        agent_password: this.state.editAgentPassword,
        agent_address: this.state.editAgentAddress,
        isactive: this.state.editisActive,
        is_siteinfo: this.state.editisSiteInfo
      };

      axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}agents/api/`,
        data: objUdpateAgent,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
          $('#edit_agent').hide();

          this.GetAllAgent();

          this.setState({
            editAmbassadorID: '',
            editAgentCompName: '',
            editAgentName: '',
            editAgentTitle: '',
            editAgentEmail: '',
            editAgentPassword: '',
            editAgentPhoneNo: '',
            editAgentFax: '',
            editAgentAddress: '',
            editisActive: 0,
            editisSiteInfo: 0
          });
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getAgentData(_Agent_ID) {
    try {
      this.setState({
        editAmbassadorID: '',
        editAgentCompName: '',
        editAgentName: '',
        editAgentTitle: '',
        editAgentEmail: '',
        editAgentPassword: '',
        editAgentPhoneNo: '',
        editAgentFax: '',
        editAgentAddress: '',
        editisActive: 0,
        editisSiteInfo: 0
      });

      this.setState({ Agent_ID: _Agent_ID });
      const api = `${process.env.REACT_APP_BASE_URL}agents/api/getAgent?agent_id=${_Agent_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $('#edit_agent').show();
          this.setState({
            editAmbassadorID: res.data[0].ambassador_id,
            editAgentCompName: res.data[0].agent_comp_name,
            editAgentName: res.data[0].agent_name,
            editAgentTitle: res.data[0].agent_title,

            editAgentEmail: res.data[0].agent_email,
            editAgentPhoneNo: res.data[0].agent_phone_no,
            editAgentFax: res.data[0].agent_fax,
            editAgentAddress: res.data[0].agent_address,

            editisActive: res.data[0].isactive,
            editisSiteInfo: res.data[0].is_siteinfo
          });
        });
    } catch (error) {}
  }

  deleteAgentData(_Agent_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}agents/api/?agent_id=${_Agent_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Agent Deleted Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllAgent();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Some problem in deleting record.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    this.GetAllAgent();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Agents
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => $('#add_agent').show()}
                >
                  Add Agent
                </button>
              </div>
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='agent_id'
                          data={this.state.Agents}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleAdd}>
          <div
            className='modal myModal AddAgent'
            id='add_agent'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Add Agent</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_agent').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row' style={{ display: 'none' }}>
                      <label htmlFor='addAmbassadorID' className='col-md-4'>
                        Ambassador ID:
                      </label>
                      <input
                        type='text'
                        id='addAmbassadorID'
                        className='form-control col-md-6'
                        name='addAmbassadorID'
                        value={this.state.addAmbassadorID}
                        onChange={this.handleChange}
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentCompName' className='col-md-4'>
                        Agent Company:
                      </label>
                      <input
                        type='text'
                        id='addAgentCompName'
                        className='form-control col-md-6'
                        name='addAgentCompName'
                        value={this.state.addAgentCompName}
                        onChange={this.handleChange}
                        maxLength='150'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentName' className='col-md-4'>
                        Agent Name:
                      </label>
                      <input
                        type='text'
                        id='addAgentName'
                        className='form-control col-md-6'
                        name='addAgentName'
                        value={this.state.addAgentName}
                        onChange={this.handleChange}
                        maxLength='150'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentTitle' className='col-md-4'>
                        Agent Title:
                      </label>
                      <input
                        type='text'
                        id='addAgentTitle'
                        className='form-control col-md-6'
                        name='addAgentTitle'
                        value={this.state.addAgentTitle}
                        onChange={this.handleChange}
                        required
                        maxLength='100'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentEmail' className='col-md-4'>
                        Email Address:
                      </label>
                      <input
                        type='email'
                        id='addAgentEmail'
                        className='form-control col-md-6'
                        name='addAgentEmail'
                        value={this.state.addAgentEmail}
                        onChange={this.handleChange}
                        maxLength='200'
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentPhoneNo' className='col-md-4'>
                        Contact Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='addAgentPhoneNo'
                        className='form-control col-md-6'
                        name='addAgentPhoneNo'
                        placeholder='Contact Number 2134567890'
                        title='Contact Number 2134567890'
                        value={this.state.addAgentPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentFax' className='col-md-4'>
                        Fax Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='addAgentFax'
                        className='form-control col-md-6'
                        name='addAgentFax'
                        placeholder='Fax Number 2134567890'
                        title='Fax Number 2134567890'
                        value={this.state.addAgentFax}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addAgentAddress' className='col-md-4'>
                        Address :
                      </label>
                      <textarea
                        id='addAgentAddress'
                        className='form-control col-md-6'
                        name='addAgentAddress'
                        placeholder='Address'
                        value={this.state.addAgentAddress}
                        onChange={this.handleChange}
                        maxLength='250'
                        required
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='addisActive' className='col-md-4'>
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addisActive'
                          name='addisActive'
                          checked={this.state.addisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='addisSiteInfo' className='col-md-4'>
                        Add Site Info:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addisSiteInfo'
                          name='addisSiteInfo'
                          checked={this.state.addisSiteInfo}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#add_agent').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit}>
          <div
            className='modal myModal edit_agent'
            id='edit_agent'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Edit Agent</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_agent').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row' style={{ display: 'none' }}>
                      <label htmlFor='editAmbassadorID' className='col-md-4'>
                        Ambassador ID:
                      </label>
                      <input
                        type='text'
                        id='editAmbassadorID'
                        className='form-control col-md-6'
                        name='editAmbassadorID'
                        value={this.state.editAmbassadorID}
                        onChange={this.handleChange}
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentCompName' className='col-md-4'>
                        Agent Company:
                      </label>
                      <input
                        type='text'
                        id='editAgentCompName'
                        className='form-control col-md-6'
                        name='editAgentCompName'
                        value={this.state.editAgentCompName}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentName' className='col-md-4'>
                        Agent Name:
                      </label>
                      <input
                        type='text'
                        id='editAgentName'
                        className='form-control col-md-6'
                        name='editAgentName'
                        value={this.state.editAgentName}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentTitle' className='col-md-4'>
                        Agent Title:
                      </label>
                      <input
                        type='text'
                        id='editAgentTitle'
                        className='form-control col-md-6'
                        name='editAgentTitle'
                        value={this.state.editAgentTitle}
                        onChange={this.handleChange}
                        required
                        maxLength='100'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentEmail' className='col-md-4'>
                        Email Address:
                      </label>
                      <input
                        type='email'
                        id='editAgentEmail'
                        className='form-control col-md-6'
                        name='editAgentEmail'
                        value={this.state.editAgentEmail}
                        onChange={this.handleChange}
                        readOnly
                        maxLength='250'
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentPassword' className='col-md-4'>
                        Password:
                      </label>
                      <input
                        type='password'
                        className='form-control col-md-6'
                        id='editAgentPassword'
                        name='editAgentPassword'
                        placeholder='Password'
                        pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                        title='Min 8 Chars must contain 1 Number, 1 Lowercase & 1 Uppercase'
                        onChange={this.handleChange}
                        value={this.state.editAgentPassword}
                      />
                      <span
                        style={{
                          fontSize: '10px',
                          marginLeft: '185px'
                        }}
                      >
                        8+ characters. Mixed Case, Numbers and Symbols
                      </span>
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentPhoneNo' className='col-md-4'>
                        Contact Number:
                      </label>
                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='editAgentPhoneNo'
                        className='form-control col-md-6'
                        name='editAgentPhoneNo'
                        placeholder='Contact Number 2134567890'
                        title='Contact Number 2134567890'
                        value={this.state.editAgentPhoneNo}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentFax' className='col-md-4'>
                        Fax Number:
                      </label>

                      <InputMask
                        mask='99999999999'
                        pattern='.{10,}'
                        maskChar={null}
                        id='editAgentFax'
                        className='form-control col-md-6'
                        name='editAgentFax'
                        placeholder='Fax Number 12134567890'
                        title='Fax Number 12134567890'
                        value={this.state.editAgentFax}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editAgentAddress' className='col-md-4'>
                        Address :
                      </label>
                      <textarea
                        id='editAgentAddress'
                        className='form-control col-md-6'
                        name='editAgentAddress'
                        placeholder='Address'
                        value={this.state.editAgentAddress}
                        onChange={this.handleChange}
                        required
                        maxLength='250'
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='editisActive' className='col-md-4'>
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editisActive'
                          name='editisActive'
                          checked={this.state.editisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label htmlFor='editisSiteInfo' className='col-md-4'>
                        Add Site info:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editisSiteInfo'
                          name='editisSiteInfo'
                          checked={this.state.editisSiteInfo}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#edit_agent').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageAgent;
