import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import CKEditor from 'ckeditor4-react';
import Select from 'react-select';
import './ManageAgreements.css';

const { SearchBar } = Search;
const CKEconfig = {
  doctype: 'html',
  fullPage: true,
  allowedContent: true,
  skin:'kama',
  embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
  image2_alignClasses: ['image-align-left', 'image-align-center', 'image-align-right'],
  image2_disableResizer: true,
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing']
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
    },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
    { name: 'font', groups: ['font'] },
    '/',
    '/'
  ],
  extraPlugins: 'print,format,font,justify,emoji,embed,autoembed,image2'

};
class ManageAgreements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addAgreementTitle: '',
      addAgreementContent: '',
      addAgreementInstruction: '',
      addIsActive: 0,
      addIsSignUp: 0,
      addIsNDA: 0,
      addIsMandatory: 0,
      addVendorSignorID: '',
      editAgreementTitle: '',
      editAgreementContent: '',
      editAgreementInstruction: '',
      editIsActive: 0,
      editIsSignUp: 0,
      editIsNDA: 0,
      editIsMandatory: 0,
      editIsPublished: 0,
      editVendorSignorID: '',
      Agreement_ID: '',
      previewAgreement: '',
      agreements: [],
      Vendor_Signor: [],
      loading: false,
      Disable_Button: '',

      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'vendor_signor_name',
          text: 'Vendor Signor',
          sort: true
        },
        {
          dataField: 'agreement_title',
          text: 'Agreement Title',
          sort: true
        },
        {
          dataField: 'isactive',
          text: 'Active',
          sort: true
        },
        {
          dataField: 'issignup',
          text: 'Sign Up',
          sort: true
        },
        {
          dataField: 'isnda',
          text: 'Enforce',
          sort: true
        },
        {
          dataField: 'ismandatory',
          text: 'Mandatory',
          sort: true
        },
        {
          dataField: 'ispublished',
          text: 'Published',
          sort: true
        },
        {
          dataField: 'preview',
          text: 'View',
          sort: false,
          formatter: this.agreementPreviewFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'edit',
          text: 'Edit',
          sort: false,
          formatter: this.agreementFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          sort: false,
          formatter: this.agreementDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        }
      ],
      htmlInputTextbox:
        '[{"type":"textbox","id":"txtbillname","style":"width:300px","req":"true"}]',
      htmlInputTextarea:
        '[{"type":"textarea","id":"txtbilladdress","style":"width:300px;height:250px","req":"true"}]',
      htmlInputCheckbox:
        '[{"type":"checkbox","id":"chkexemption","data":"\'Federal Taxes\',\' Local Taxes\',\'State Taxes\',\'State Universal Service Fund\',\'All of the Above\'"}]'
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleAddPublish = this.handleAddPublish.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditPublish = this.handleEditPublish.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.getAgreementData = this.getAgreementData.bind(this);
    this.viewAgreementData = this.viewAgreementData.bind(this);

    this.addVendorSetValues = this.addVendorSetValues.bind(this);
  }

  GetAllAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}agreements/api`;
    this.setState({
      loading: true
    });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          agreements: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onChangeAdd(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      addAgreementContent: newContent
    });
  }

  onChangeEdit(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      editAgreementContent: newContent
    });
  }

  handleAdd(e) {
    e.preventDefault();
    try {
      if (
        this.state.addVendorSignorID === '' ||
        this.state.addVendorSignorID === undefined
      ) {
        $('#ddladdVendorSignor').focus();
        return false;
      }

      this.setState({ Disable_Button: 'disabled' });
      let objAgreement = {
        vendor_signor_id: this.state.addVendorSignorID.value,
        agreement_title: this.state.addAgreementTitle,
        agreement_content: this.state.addAgreementContent,
        agreement_instruction: this.state.addAgreementInstruction,
        isactive: this.state.addIsActive,
        issignup: this.state.addIsSignUp,
        isnda: this.state.addIsNDA,
        ismandatory: this.state.addIsMandatory
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agreements/api/`,
        data: objAgreement,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === false) {
            return this.props.history.push({
              pathname: '/tcaccess'
            });
          }
          if (res.data.success === false) {
            this.setState({ Disable_Button: '' });
            return notie.alert({
              type: 'warning',
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Agreement Added Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('.css-1uccc91-singleValue').html('Select Vendor Signor');
            $('#add_new_agreement').hide();

            this.GetAllAgreements();

            this.setState({
              addVendorSignorID: '',
              addAgreementTitle: '',
              addAgreementContent: '',
              addAgreementInstruction: '',
              addIsActive: 0,
              addIsSignUp: 0,
              addIsNDA: 0,
              addIsMandatory: 0
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({ Disable_Button: '' });
        })
        .catch(function(response) {
          this.setState({ Disable_Button: '' });
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      this.setState({ Disable_Button: '' });
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  handleAddPublish(e) {
    e.preventDefault();
    try {
      if (
        this.state.addVendorSignorID === '' ||
        this.state.addVendorSignorID === undefined
      ) {
        $('#ddladdVendorSignor').focus();
        return false;
      }
      this.setState({ Disable_Button: 'disbaled' });

      let objAgreement = {
        vendor_signor_id: this.state.addVendorSignorID.value,
        agreement_title: this.state.addAgreementTitle,
        agreement_content: this.state.addAgreementContent,
        agreement_instruction: this.state.addAgreementInstruction,
        isactive: this.state.addIsActive,
        issignup: this.state.addIsSignUp,
        isnda: this.state.addIsNDA,
        ismandatory: this.state.addIsMandatory,
        ispublished: 1
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}agreements/api/AgreementPublish`,
        data: objAgreement,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            this.setState({ Disable_Button: '' });
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Agreement Added & Published Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_new_agreement').hide();

            this.GetAllAgreements();
            this.GetAllVendorSignor();
            this.setState({
              addVendorSignorID: '',
              addAgreementTitle: '',
              addAgreementContent: '',
              addAgreementInstruction: '',
              addIsActive: 0,
              addIsSignUp: 0,
              addIsNDA: 0,
              addIsMandatory: 0
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({ Disable_Button: '' });
        })
        .catch(function(response) {
          this.setState({ Disable_Button: '' });
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      this.setState({ Disable_Button: '' });
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  agreementFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getAgreementData(row.agreement_id)}
        />
      </div>
    );
  };

  agreementDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text:
                'By deleting this agreement, you are only removing the ability for New Clients to sign it. A copy of any signed agreements will remain in PDF',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deleteAgreementData(row.agreement_id) // optional
            })
          }
        />
      </div>
    );
  };

  agreementPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-eye'
          onClick={() => this.viewAgreementData(row.agreement_id)}
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();
    if (
      this.state.editVendorSignorID === '' ||
      this.state.editVendorSignorID === undefined
    ) {
      $('#ddleditVendorSignor').focus();
      return false;
    }
    try {
      this.setState({ Disable_Button: 'disabled' });

      let objAgreement = {
        vendor_signor_id: this.state.editVendorSignorID,
        agreement_title: this.state.editAgreementTitle,
        agreement_content: this.state.editAgreementContent,
        agreement_instruction: this.state.editAgreementInstruction,
        isactive: this.state.editIsActive,
        issignup: this.state.editIsSignUp,
        isnda: this.state.editIsNDA,
        ismandatory: this.state.editIsMandatory,
        agreement_id: this.state.Agreement_ID
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };

      let URL = `${process.env.REACT_APP_BASE_URL}agreements/api/`;
      axios
        .put(URL, objAgreement, headers)
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          this.setState({ Disable_Button: '' });
          if (res.data.success === false) {
            this.setState({ Disable_Button: '' });
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Agreement Updated Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              editVendorSignorID: '',
              editAgreementTitle: '',
              editAgreementContent: '',
              editAgreementInstruction: '',
              editIsActive: 0,
              editIsSignUp: 0,
              editIsNDA: 0,
              editIsMandatory: 0
            });

            $('#edit_agreement').hide();

            this.GetAllAgreements();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({ Disable_Button: '' });
        })
        .catch(function(response) {
          this.setState({ Disable_Button: '' });
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      this.setState({ Disable_Button: '' });
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  handleEditPublish(e) {
    e.preventDefault();
    if (
      this.state.editVendorSignorID === '' ||
      this.state.editVendorSignorID === undefined
    ) {
      $('#ddleditVendorSignor').focus();
      return false;
    }
    try {
      this.setState({ Disable_Button: 'disabled' });

      let objAgreement = {
        vendor_signor_id: this.state.editVendorSignorID,
        agreement_title: this.state.editAgreementTitle,
        agreement_content: this.state.editAgreementContent,
        agreement_instruction: this.state.editAgreementInstruction,
        isactive: this.state.editIsActive,
        issignup: this.state.editIsSignUp,
        isnda: this.state.editIsNDA,
        ismandatory: this.state.editIsMandatory,
        agreement_id: this.state.Agreement_ID,
        ispublished: 1
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };

      let URL = `${process.env.REACT_APP_BASE_URL}agreements/api/AgreementPublish`;
      axios
        .put(URL, objAgreement, headers)
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          this.setState({ Disable_Button: '' });
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Agreement Updated & Published  Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              editVendorSignorID: '',
              editAgreementTitle: '',
              editAgreementContent: '',
              editAgreementInstruction: '',
              editIsActive: 0,
              editIsSignUp: 0,
              editIsNDA: 0,
              editIsMandatory: 0
            });

            $('#edit_agreement').hide();

            this.GetAllAgreements();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          this.setState({ Disable_Button: '' });
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      this.setState({ Disable_Button: '' });
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getAgreementData(_Agreement_ID) {
    try {
      this.setState({
        editVendorSignorID: '',
        editAgreementTitle: '',
        editAgreementContent: '',
        editAgreementInstruction: '',
        editIsActive: 0,
        editIsSignUp: 0,
        editIsNDA: 0,
        editIsMandatory: 0,
        Agreement_ID: _Agreement_ID,
        loading: true
      });

      const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getAgreement?agreement_id=${_Agreement_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          this.setState({
            editVendorSignorID: res.data[0].vendor_signor_id,
            editAgreementTitle: res.data[0].agreement_title,
            editAgreementContent: res.data[0].agreement_content,
            editAgreementInstruction: res.data[0].agreement_instruction,
            editIsActive: res.data[0].isactive,
            editIsSignUp: res.data[0].issignup,
            editIsNDA: res.data[0].isnda,
            editIsMandatory: res.data[0].ismandatory,
            editIsPublished: res.data[0].ispublished,
            loading: false
          });

          $('#edit_agreement').show();
        });
    } catch (error) {}
  }

  deleteAgreementData(_Agreement_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}agreements/api/?agreement_id=${_Agreement_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllAgreements();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Some problem in deleting record.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  viewAgreementData(_Agreement_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}agreements/api/getAgreement?agreement_id=${_Agreement_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({ previewAgreement: res.data[0].agreement_content });
          $('#preview_agreement').show();
        });
    } catch (error) {}
  }

  GetAllVendorSignor() {
    const api = `${process.env.REACT_APP_BASE_URL}vendor_signor/api/DDVendorSignor`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);
        this.setState({
          Vendor_Signor: res.data,
          loading: false
        });
      });
  }

  addVendorSetValues = selectedAddOption => {
    console.log(`Option selected:`, selectedAddOption.value);
    this.setState({ addVendorSignorID: selectedAddOption });
    console.log(
      'this.state.addVendorSignorID',
      this.state.addVendorSignorID.value
    );
  };

  editVendorSetValues = selectedOption => {
    //console.log(`Option selected:`, selectedOption.value);
    this.setState({ editVendorSignorID: selectedOption.value });
  };

  componentWillMount = () => {
    //console.log('ManageAgreements will mount');
  };

  componentDidMount = () => {
    //console.log('ManageAgreements mounted');
    this.GetAllVendorSignor();
    this.GetAllAgreements();
  };
  copyToClipboard(element) {
    var $temp = $('<input>');
    $('body').append($temp);
    $temp.val($(element).text()).select();
    document.execCommand('copy');
    $temp.remove();
    return false;
  }
  componentWillReceiveProps = nextProps => {
    //console.log('ManageAgreements will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('ManageAgreements will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('ManageAgreements did update');
  };

  componentWillUnmount = () => {
    //console.log('ManageAgreements will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Agreements
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => $('#add_new_agreement').show()}
                >
                  Add New Agreement
                </button>
              </div>
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='agreement_id'
                          data={this.state.agreements}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                wrapperClasses='table-responsive'
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal myModal AddAgreement'
          id='add_new_agreement'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <form
              id='frmAdd'
              onSubmit={this.handleAdd}
              style={{ width: '100%' }}
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Add New Agreement</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_new_agreement').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='ddladdVendorSignor'
                        className='col-sm-2 control-labels'
                      >
                        Select Vendor Signor:
                      </label>
                      <Select
                        required
                        aria-required='true'
                        id='ddladdVendorSignor'
                        style={{ width: '200px' }}
                        onChange={this.addVendorSetValues}
                        placeholder='Select Vendor Signor'
                        options={this.state.Vendor_Signor}
                        value={this.state.addVendorSignorID}
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addAgreementTitle'
                        className='col-sm-2 control-labels'
                      >
                        Agreement Title:
                      </label>
                      <input
                        type='text'
                        id='addAgreementTitle'
                        className='form-control col-md-3'
                        name='addAgreementTitle'
                        onChange={this.handleChange}
                        value={this.state.addAgreementTitle}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addAgreementInstruction'
                        className='col-sm-2 control-labels'
                      >
                        Agreement Instruction:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <textarea
                          id='addAgreementInstruction'
                          className='form-control col-md-7'
                          name='addAgreementInstruction'
                          value={this.state.addAgreementInstruction}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addAgreementContent'
                        className='col-sm-2 control-labels'
                      >
                        Agreement Content:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.addAgreementContent}
                          onChange={this.onChangeAdd}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label className='col-sm-10 control-labels'>
                        All custom input fields should have a unique value ID
                        and unique value for checkbox Data attribute.
                      </label>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        Client Place Holders:
                      </label>
                      <button
                        type='button'
                        id='CLIENT_SIGNOR_TITLE'
                        onClick={() =>
                          this.copyToClipboard('#CLIENT_SIGNOR_TITLE')
                        }
                      >
                        [[CLIENT_SIGNOR_TITLE]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_SIGNOR_NAME'
                        onClick={() =>
                          this.copyToClipboard('#CLIENT_SIGNOR_NAME')
                        }
                      >
                        [[CLIENT_SIGNOR_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_COMPANY_NAME'
                        onClick={() =>
                          this.copyToClipboard('#CLIENT_COMPANY_NAME')
                        }
                      >
                        [[CLIENT_COMPANY_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_EMAIL'
                        onClick={() => this.copyToClipboard('#CLIENT_EMAIL')}
                      >
                        [[CLIENT_EMAIL]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_ADDRESS'
                        onClick={() => this.copyToClipboard('#CLIENT_ADDRESS')}
                      >
                        [[CLIENT_ADDRESS]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CONTRACT_DATE'
                        onClick={() => this.copyToClipboard('#CONTRACT_DATE')}
                      >
                        [[CONTRACT_DATE]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='CLIENT_SIGN'
                        onClick={() => this.copyToClipboard('#CLIENT_SIGN')}
                      >
                        [[CLIENT_SIGN]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        Vendor Place Holders:
                      </label>
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_TITLE'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_TITLE')
                        }
                      >
                        [[VENDOR_SIGNOR_TITLE]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_NAME'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_NAME')
                        }
                      >
                        [[VENDOR_SIGNOR_NAME]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_ADDRESS'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_ADDRESS')
                        }
                      >
                        [[VENDOR_SIGNOR_ADDRESS]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_SIGN'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_SIGN')
                        }
                      >
                        [[VENDOR_SIGNOR_SIGN]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_COMPANY_NAME'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_COMPANY_NAME')
                        }
                      >
                        [[VENDOR_SIGNOR_COMPANY_NAME]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'></label>
                      <button
                        type='button'
                        id='VENDOR_CONTRACT_DATE'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_CONTRACT_DATE')
                        }
                      >
                        [[VENDOR_CONTRACT_DATE]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        User Place Holders:
                      </label>
                      <button
                        type='button'
                        id='USER_COMPANY_NAME'
                        onClick={() =>
                          this.copyToClipboard('#USER_COMPANY_NAME')
                        }
                      >
                        [[USER_COMPANY_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='USER_COMPANY_NAME_SHORT'
                        onClick={() =>
                          this.copyToClipboard('#USER_COMPANY_NAME_SHORT')
                        }
                      >
                        [[USER_COMPANY_NAME_SHORT]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='USER_ADDRESS'
                        onClick={() => this.copyToClipboard('#USER_ADDRESS')}
                      >
                        [[USER_ADDRESS]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        HTML Input for Textbox:
                      </label>
                      Sample : {this.state.htmlInputTextbox}
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        HTML Input for Textarea:
                      </label>
                      Sample : {this.state.htmlInputTextarea}
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        HTML Input for Checkbox:
                      </label>
                      Sample : {this.state.htmlInputCheckbox}
                    </div>
                    <div className='form-group row'>
                      <label
                        className='col-sm-2 control-labels'
                        htmlFor='addIsActive'
                      >
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addIsActive'
                          name='addIsActive'
                          checked={this.state.addIsActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addIsSignUp'
                        className='col-sm-2 control-labels'
                      >
                        Sign Up:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addIsSignUp'
                          name='addIsSignUp'
                          checked={this.state.addIsSignUp}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addIsNDA'
                        className='col-sm-2 control-labels'
                      >
                        Enforce:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addIsNDA'
                          name='addIsNDA'
                          checked={this.state.addIsNDA}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addIsMandatory'
                        className='col-sm-2 control-labels'
                      >
                        Mandatory:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addIsMandatory'
                          name='addIsMandatory'
                          checked={this.state.addIsMandatory}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    disabled={this.state.Disable_Button}
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#add_new_agreement').hide()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={this.state.Disable_Button}
                    type='submit'
                    id='addAgreement'
                    className='btn btn-success'
                  >
                    Save File{' '}
                  </button>
                  <button
                    disabled={this.state.Disable_Button}
                    type='submit'
                    id='addPublishAgreement'
                    className='btn btn-primary'
                    onClick={this.handleAddPublish}
                  >
                    Publish All{' '}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/** EDIT FORM */}
        <form id='frmEdit' onSubmit={this.handleEdit} style={{ width: '100%' }}>
          <div
            className='modal edit_agreement myModal'
            id='edit_agreement'
            style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Edit Agreement</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_agreement').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='addAgreementTitle'
                        className='col-sm-2 control-labels'
                      >
                        Select Vendor Signor:
                      </label>
                      <Select
                        id='ddleditVendorSignor'
                        placeholder='Select Vendor Signor'
                        options={this.state.Vendor_Signor}
                        style={{ width: '200px' }}
                        value={this.state.Vendor_Signor.find(op => {
                          return op.value === this.state.editVendorSignorID;
                        })}
                        onChange={this.editVendorSetValues}
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editAgreementTitle'
                        className='col-sm-2 control-labels'
                      >
                        Agreement Title:
                      </label>
                      <input
                        type='text'
                        id='editAgreementTitle'
                        className='form-control col-md-3'
                        name='editAgreementTitle'
                        value={this.state.editAgreementTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editAgreementInstruction'
                        className='col-sm-2 control-labels'
                      >
                        Agreement Instruction:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <textarea
                          id='editAgreementInstruction'
                          className='form-control col-md-7'
                          name='editAgreementInstruction'
                          value={this.state.editAgreementInstruction}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className='form-group row'>
                      <label
                        htmlFor='editAgreementContent'
                        className='col-sm-2 control-labels'
                      >
                        Agreement Content:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.editAgreementContent}
                          onChange={this.onChangeEdit}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label className='col-sm-10 control-labels'>
                        All custom input fields should have a unique value ID
                        and unique value for checkbox Data attribute.
                      </label>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        Client Place Holders:
                      </label>
                      <button
                        type='button'
                        id='CLIENT_SIGNOR_TITLE'
                        onClick={() =>
                          this.copyToClipboard('#CLIENT_SIGNOR_TITLE')
                        }
                      >
                        [[CLIENT_SIGNOR_TITLE]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_SIGNOR_NAME'
                        onClick={() =>
                          this.copyToClipboard('#CLIENT_SIGNOR_NAME')
                        }
                      >
                        [[CLIENT_SIGNOR_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_COMPANY_NAME'
                        onClick={() =>
                          this.copyToClipboard('#CLIENT_COMPANY_NAME')
                        }
                      >
                        [[CLIENT_COMPANY_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_EMAIL'
                        onClick={() => this.copyToClipboard('#CLIENT_EMAIL')}
                      >
                        [[CLIENT_EMAIL]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CLIENT_ADDRESS'
                        onClick={() => this.copyToClipboard('#CLIENT_ADDRESS')}
                      >
                        [[CLIENT_ADDRESS]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='CONTRACT_DATE'
                        onClick={() => this.copyToClipboard('#CONTRACT_DATE')}
                      >
                        [[CONTRACT_DATE]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='CLIENT_SIGN'
                        onClick={() => this.copyToClipboard('#CLIENT_SIGN')}
                      >
                        [[CLIENT_SIGN]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        Vendor Place Holders:
                      </label>
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_TITLE'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_TITLE')
                        }
                      >
                        [[VENDOR_SIGNOR_TITLE]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_NAME'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_NAME')
                        }
                      >
                        [[VENDOR_SIGNOR_NAME]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_ADDRESS'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_ADDRESS')
                        }
                      >
                        [[VENDOR_SIGNOR_ADDRESS]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_SIGN'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_SIGN')
                        }
                      >
                        [[VENDOR_SIGNOR_SIGN]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='VENDOR_SIGNOR_COMPANY_NAME'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_SIGNOR_COMPANY_NAME')
                        }
                      >
                        [[VENDOR_SIGNOR_COMPANY_NAME]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'></label>
                      <button
                        type='button'
                        id='VENDOR_CONTRACT_DATE'
                        onClick={() =>
                          this.copyToClipboard('#VENDOR_CONTRACT_DATE')
                        }
                      >
                        [[VENDOR_CONTRACT_DATE]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        User Place Holders:
                      </label>
                      <button
                        type='button'
                        id='USER_COMPANY_NAME'
                        onClick={() =>
                          this.copyToClipboard('#USER_COMPANY_NAME')
                        }
                      >
                        [[USER_COMPANY_NAME]]
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        id='USER_COMPANY_NAME_SHORT'
                        onClick={() =>
                          this.copyToClipboard('#USER_COMPANY_NAME_SHORT')
                        }
                      >
                        [[USER_COMPANY_NAME_SHORT]]
                      </button>
                      &nbsp;{' '}
                      <button
                        type='button'
                        id='USER_ADDRESS'
                        onClick={() => this.copyToClipboard('#USER_ADDRESS')}
                      >
                        [[USER_ADDRESS]]
                      </button>
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        HTML Input for Textbox:
                      </label>
                      Sample : {this.state.htmlInputTextbox}
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        HTML Input for Textarea:
                      </label>
                      Sample : {this.state.htmlInputTextarea}
                    </div>
                    <div className='form-group row'>
                      <label className='col-sm-2 control-labels'>
                        HTML Input for Checkbox:
                      </label>
                      Sample : {this.state.htmlInputCheckbox}
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editIsActive'
                        className='col-sm-2 control-labels'
                      >
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editIsActive'
                          name='editIsActive'
                          checked={this.state.editIsActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editIsSignUp'
                        className='col-sm-2 control-labels'
                      >
                        Sign Up:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editIsSignUp'
                          name='editIsSignUp'
                          checked={this.state.editIsSignUp}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editIsNDA'
                        className='col-sm-2 control-labels'
                      >
                        Enforce:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editIsNDA'
                          name='editIsNDA'
                          checked={this.state.editIsNDA}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editIsMandatory'
                        className='col-sm-2 control-labels'
                      >
                        Mandatory:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editIsMandatory'
                          name='editIsMandatory'
                          checked={this.state.editIsMandatory}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    disabled={this.state.Disable_Button}
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#edit_agreement').hide()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={this.state.Disable_Button}
                    id='editAgreement'
                    type='submit'
                    className='btn btn-success'
                  >
                    Save File{' '}
                  </button>
                  <button
                    disabled={this.state.Disable_Button}
                    type='button'
                    id='editPublishAgreement'
                    className='btn btn-primary'
                    onClick={this.handleEditPublish}
                  >
                    Publish All{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** PREWVIEW AGREEMENT*/}
        <div
          className='modal preview_agreement myModal'
          id='preview_agreement'
          style={{ display: 'none' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Agreement Preview</h4>
                <button
                  type='button'
                  className='close'
                  onClick={() => $('#preview_agreement').hide()}
                >
                  ×
                </button>
              </div>

              <div className='modal-body'>
                <div className='container-fluid'>
                  <div className='form-group row'>
                    <div
                      style={{
                        width: '100%',
                        height: 'auto',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 100px)'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.previewAgreement
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageAgreements;
