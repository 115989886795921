import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import lifecycle from 'react-pure-lifecycle';
import { AuthConsumer } from '../../context/AuthContext';

const methods = {
  componentDidMount(props) {
    document.body.classList.add('page-login-v2', 'layout-full', 'page-dark');
    document.body.classList.remove(
      'site-navbar-small',
      'animsition',
      'site-menubar-unfold'
    );
  }
};

const UserLoginLayout = ({ children, ...rest }) => {
  return (
    <AuthConsumer>
      {({ Comp_Logo_Dashboard, Client_Marketing_Content }) => (
        <React.Fragment>
          <div className='page'>
            <div className='page-content'>
              <div className='page-brand-info'>
                <div className='brand'>
                  {Comp_Logo_Dashboard === '' ? (
                    ''
                  ) : (
                    <img
                      className='brand-img'
                      src={Comp_Logo_Dashboard}
                      alt='...'
                    />
                  )}
                </div>
                <div className='brand'>
                  <p className='text-white'>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: Client_Marketing_Content
                      }}
                    />
                  </p>
                </div>
              </div>

              <div className='page-login-main'>{children}</div>
            </div>
          </div>
        </React.Fragment>
      )}
    </AuthConsumer>
  );
};

const UserLoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({ isAuthUser, User_ID, Comp_Name }) => (
        <Route
          render={matchProps =>
            isAuthUser ? (
              <Redirect
                to={{
                  pathname: '/client_dashboard',
                  state: { user_id: User_ID }
                }}
              />
            ) : (
              <UserLoginLayout Comp_Name={Comp_Name}>
                <Component {...matchProps} />
              </UserLoginLayout>
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(UserLoginLayoutRoute);
