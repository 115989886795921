import React, { Component } from "react";
import * as Survey from "survey-react";
import * as SurveyPDF from "survey-pdf";
import axios from "axios";
import notie from "notie";
import "survey-react/survey.css";
import "./DemoDashboard.css";

class DemoDashboard extends Component {
  constructor(props) {
    super(props);
    Survey.StylesManager.applyTheme("orange");
    Survey.showProgressBar = "bottom";

    this.json = this.props.Quotation_JSON;
    console.log("this.json", this.json);
    this.json_old = {
      "title": "Quotation",
      "completedhtml":
        "<p><h4>Thank you for sharing this information with us.</h4></p>",
      "pages": [
        {
          "name": "page1",
          "elements": [
            {
              "type": "panel",
              "name": "panel1",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "quote_type",
                  "title": "Type of quote required",
                  "isRequired": true,
                  "choices": ["Ballpark", "Budgetary", "Firm"]
                },
                {
                  "type": "html",
                  "name": "quote_type_detail",
                  "html":
                    "<table><tr><td><ul><li>Ballpark: your project is at early stage</li><li>Budgetary: your project is approved but no funds are approved at this time</li><li>Firm: your project scope and requirements are fully defined and funds are approved, ready to purchase</li></ul></td></table>"
                }
              ]
            }
          ]
        },
        {
          "name": "page2",
          "elements": [
            {
              "type": "panel",
              "name": "panel2",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "equipment_of_interest",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Equipment of interest",
                  "isRequired": true,
                  "hasOther": true,
                  "choices": [
                    "Bagger",
                    "Bag closer",
                    "Case packer",
                    "Palletizer",
                    "Depalletizer",
                    "Stretch hooder/wrapper",
                    "Processing equipment for peat, mulch, or soil",
                    "Field equipment for peat bog"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page3",
          "elements": [
            {
              "type": "panel",
              "name": "panel3",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "type_of_bagger_required",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Type of bagger required ",
                  "isRequired": true,
                  "hasOther": true,
                  "choices": [
                    "Open-Mouth Bagger",
                    "FFS Bagger",
                    "Valve Bag Filler",
                    "Bulk Bag Filler",
                    "Compression Bagger",
                    "Not sure, need recommendation"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page4",
          "elements": [
            {
              "type": "panel",
              "name": "panel4",
              "elements": [
                {
                  "type": "dropdown",
                  "name": "bags_per_hour_required",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bags per hour required for the {quote_type}",
                  "isRequired": true,
                  "choices": [
                    {
                      "value": "item1",
                      "text": "60-300 bags per hour"
                    },
                    {
                      "value": "item2",
                      "text": "300-600 bags per hour"
                    },
                    {
                      "value": "item3",
                      "text": "600-900 bags per hour"
                    },
                    {
                      "value": "item4",
                      "text": "900-1200 bags per hour"
                    },
                    {
                      "value": "item5",
                      "text": "1200-1500 bags per hour"
                    },
                    {
                      "value": "item6",
                      "text": "1500-1800 bags per hour"
                    },
                    {
                      "value": "item7",
                      "text": "1800-2100 bags per hour"
                    },
                    {
                      "value": "item8",
                      "text": "2100+ bags per hour"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page5",
          "elements": [
            {
              "type": "panel",
              "name": "panel5",
              "elements": [
                {
                  "type": "text",
                  "name": "bulk_material_to_be_bagged",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bulk material to be bagged",
                  "isRequired": true
                }
              ]
            }
          ]
        },
        {
          "name": "page6",
          "elements": [
            {
              "type": "panel",
              "name": "panel6",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "bulk_material_type",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bulk material type",
                  "isRequired": true,
                  "choices": [
                    "Powder",
                    "Coarse aggregate",
                    "Granular",
                    "Fibrous",
                    "Pellet",
                    "Flake"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page7",
          "elements": [
            {
              "type": "panel",
              "name": "panel7",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "bag_weight_range",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bag weight range",
                  "isRequired": true,
                  "choices": [
                    "0-1kg (0-2lb)",
                    "1-5kg (2-10lb)",
                    "5-9kg (10-20lb)",
                    "9-20kg (20-45lb)",
                    "20-50kg (45-110lb)",
                    "450-2000kg (1000-4400lb)"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page8",
          "elements": [
            {
              "type": "panel",
              "name": "panel8",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "project_timeframe",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Project timeframe",
                  "isRequired": true,
                  "choices": [
                    "1-4 months",
                    "4-8 months",
                    "8-12 months",
                    "12+ months"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page9",
          "elements": [
            {
              "type": "panel",
              "name": "panel9",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "project_goal ",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Project goal",
                  "isRequired": true,
                  "hasOther": true,
                  "choices": [
                    "Adding line capacity",
                    "Automate existing process",
                    "Building new facility",
                    "Changing existing equipment",
                    "Starting a company"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page10",
          "elements": [
            {
              "type": "panel",
              "name": "panel10",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "project_range",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Your budget range (in $USD or €EUR)",
                  "isRequired": true,
                  "choices": [
                    "25,000-50,000",
                    "50,000-100,000",
                    "100,000-200,000",
                    "200,000-500,000",
                    "500,000-1,000,000",
                    "1,000,000-2,000,000",
                    "2,000,000+"
                  ]
                },
                {
                  "type": "html",
                  "name": "project_range_detail",
                  "html":
                    "<table><tr><td>*We consider $USD for quote inquiries from North or South America and €EUR for Europe, Asia, Africa, or Oceania.</td></table>"
                }
              ]
            }
          ]
        },
        {
          "name": "page11",
          "elements": [
            {
              "type": "panel",
              "name": "panel11",
              "elements": [
                {
                  "type": "comment",
                  "name": "optional_info",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary",
                  "title":
                    "Do you have additional information to provide about the project?"
                }
              ]
            }
          ]
        }
      ],
      "pagePrevtext": "Previous",
      "pageNexttext": "Continue",
      "completetext": "Finish"
    };
    // ,{
    //   "type": 'file',
    //   "name": 'image',
    //   "title": 'Upload Data Project File if available',
    //   allowMultiple: true,
    //   imageWidth: 150,
    //   acceptedTypes: 'jpg,jpge,png,pdf',
    //   waitForUpload: true,
    //   maxSize: 102400,
    //   needConfirmRemoveFile: true
    // }
    this.state = {
      hasError: false,
      User_Quotation_JSON: {},
      loading: false
    };
  }

  GetUserQuotaion(survey) {
    const api = `${process.env.REACT_APP_BASE_URL}user_quotation_json/api/getQuotaionJSON`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        survey.data = JSON.parse(
          JSON.parse(JSON.stringify(res.data[0].user_quotation_json))
        );
      });
  }

  componentDidMount = () => {};

  saveSurveyToPdf(filename, surveyModel, pdfWidth, pdfHeight) {
    var options = {
      fontSize: 14,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10
      },
      format: [pdfWidth, pdfHeight]
    };
    var surveyPDF = new SurveyPDF.SurveyPDF(this.json, options);
    surveyPDF.data = surveyModel.data;
    surveyPDF.save(filename);
  }

  onComplete(survey, options) {
    //Write survey results into database
    console.log("Survey results: ", JSON.stringify(survey.data));
    document.querySelector("#surveyResult").textContent =
      "Result JSON:\n" + JSON.stringify(survey.data, null, 3);

    var pdfWidth = 210;
    var pdfHeight = 297;

    var optionPDF = {
      fontSize: 15,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10
      },
      format: [pdfWidth, pdfHeight]
    };
    var pdfJSON = {
      "title": "Quotation",
      "completedhtml":
        "<p><h4>Thank you for sharing this information with us.</h4></p>",
      "pages": [
        {
          "name": "page1",
          "elements": [
            {
              "type": "panel",
              "name": "panel1",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "quote_type",
                  "title": "Type of quote required",
                  "isRequired": true,
                  "choices": ["Ballpark", "Budgetary", "Firm"]
                },
                {
                  "type": "html",
                  "name": "quote_type_detail",
                  "html":
                    "<table><tr><td><ul><li>Ballpark: your project is at early stage</li><li>Budgetary: your project is approved but no funds are approved at this time</li><li>Firm: your project scope and requirements are fully defined and funds are approved, ready to purchase</li></ul></td></table>"
                }
              ]
            }
          ]
        },
        {
          "name": "page2",
          "elements": [
            {
              "type": "panel",
              "name": "panel2",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "equipment_of_interest",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Equipment of interest",
                  "isRequired": true,
                  "hasOther": true,
                  "choices": [
                    "Bagger",
                    "Bag closer",
                    "Case packer",
                    "Palletizer",
                    "Depalletizer",
                    "Stretch hooder/wrapper",
                    "Processing equipment for peat, mulch, or soil",
                    "Field equipment for peat bog"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page3",
          "elements": [
            {
              "type": "panel",
              "name": "panel3",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "type_of_bagger_required",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Type of bagger required ",
                  "isRequired": true,
                  "hasOther": true,
                  "choices": [
                    "Open-Mouth Bagger",
                    "FFS Bagger",
                    "Valve Bag Filler",
                    "Bulk Bag Filler",
                    "Compression Bagger",
                    "Not sure, need recommendation"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page4",
          "elements": [
            {
              "type": "panel",
              "name": "panel4",
              "elements": [
                {
                  "type": "dropdown",
                  "name": "bags_per_hour_required",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bags per hour required for the {quote_type}",
                  "isRequired": true,
                  "choices": [
                    {
                      "value": "item1",
                      "text": "60-300 bags per hour"
                    },
                    {
                      "value": "item2",
                      "text": "300-600 bags per hour"
                    },
                    {
                      "value": "item3",
                      "text": "600-900 bags per hour"
                    },
                    {
                      "value": "item4",
                      "text": "900-1200 bags per hour"
                    },
                    {
                      "value": "item5",
                      "text": "1200-1500 bags per hour"
                    },
                    {
                      "value": "item6",
                      "text": "1500-1800 bags per hour"
                    },
                    {
                      "value": "item7",
                      "text": "1800-2100 bags per hour"
                    },
                    {
                      "value": "item8",
                      "text": "2100+ bags per hour"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page5",
          "elements": [
            {
              "type": "panel",
              "name": "panel5",
              "elements": [
                {
                  "type": "text",
                  "name": "bulk_material_to_be_bagged",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bulk material to be bagged",
                  "isRequired": true
                }
              ]
            }
          ]
        },
        {
          "name": "page6",
          "elements": [
            {
              "type": "panel",
              "name": "panel6",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "bulk_material_type",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bulk material type",
                  "isRequired": true,
                  "choices": [
                    "Powder",
                    "Coarse aggregate",
                    "Granular",
                    "Fibrous",
                    "Pellet",
                    "Flake"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page7",
          "elements": [
            {
              "type": "panel",
              "name": "panel7",
              "elements": [
                {
                  "type": "checkbox",
                  "name": "bag_weight_range",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Bag weight range",
                  "isRequired": true,
                  "choices": [
                    "0-1kg (0-2lb)",
                    "1-5kg (2-10lb)",
                    "5-9kg (10-20lb)",
                    "9-20kg (20-45lb)",
                    "20-50kg (45-110lb)",
                    "450-2000kg (1000-4400lb)"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page8",
          "elements": [
            {
              "type": "panel",
              "name": "panel8",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "project_timeframe",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Project timeframe",
                  "isRequired": true,
                  "choices": [
                    "1-4 months",
                    "4-8 months",
                    "8-12 months",
                    "12+ months"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page9",
          "elements": [
            {
              "type": "panel",
              "name": "panel9",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "project_goal ",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Project goal",
                  "isRequired": true,
                  "hasOther": true,
                  "choices": [
                    "Adding line capacity",
                    "Automate existing process",
                    "Building new facility",
                    "Changing existing equipment",
                    "Starting a company"
                  ]
                }
              ]
            }
          ]
        },
        {
          "name": "page10",
          "elements": [
            {
              "type": "panel",
              "name": "panel10",
              "elements": [
                {
                  "type": "radiogroup",
                  "name": "project_range",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary or {quote_type} = Firm",
                  "title": "Your budget range (in $USD or €EUR)",
                  "isRequired": true,
                  "choices": [
                    "25,000-50,000",
                    "50,000-100,000",
                    "100,000-200,000",
                    "200,000-500,000",
                    "500,000-1,000,000",
                    "1,000,000-2,000,000",
                    "2,000,000+"
                  ]
                },
                {
                  "type": "html",
                  "name": "project_range_detail",
                  "html":
                    "<table><tr><td>*We consider $USD for quote inquiries from North or South America and €EUR for Europe, Asia, Africa, or Oceania.</td></table>"
                }
              ]
            }
          ]
        },
        {
          "name": "page11",
          "elements": [
            {
              "type": "panel",
              "name": "panel11",
              "elements": [
                {
                  "type": "comment",
                  "name": "optional_info",
                  "visibleIf":
                    "{quote_type} = Ballpark or {quote_type} = Budgetary",
                  "title":
                    "Do you have additional information to provide about the project?"
                }
              ]
            }
          ]
        }
      ],
      "pagePrevtext": "Previous",
      "pageNexttext": "Continue",
      "completetext": "Finish"
    };
    var surveyPDF = new SurveyPDF.SurveyPDF(pdfJSON, optionPDF);
    surveyPDF.data = {
      "quote_type": "Ballpark",
      "optional_info": "w",
      "project_goal ": "Automate existing process",
      "project_range": "25,000-50,000",
      "bag_weight_range": ["9-20kg (20-45lb)"],
      "project_timeframe": "1-4 months",
      "bulk_material_type": ["Powder"],
      "equipment_of_interest": ["Case packer"],
      "bags_per_hour_required": "item2",
      "type_of_bagger_required": ["Open-Mouth Bagger"],
      "bulk_material_to_be_bagged": "qq"
    };
    surveyPDF.mode = "display";
    surveyPDF.save("quotaion.pdf");
  }

  surveyValueChanged(sender, options) {
    var el = document.getElementById(options.name);
    if (el) {
      el.value = options.value;
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    const { loading } = this.state;

    var model = new Survey.Model(this.json);
    this.GetUserQuotaion(model);

    return (
      <div>
        <div className="row">
          <div className="col-md-12 card ">
            <div className="container-fluid">
              <div className="col-md-9">
                <h4 className="ml-3" id="heading">
                  Demo Dashboard
                </h4>
              </div>
              <hr />
              <div className="col-lg-12">
                <div className="example-wrap">
                  <div className="example">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="../assets/images/tcc_loading.gif"
                          style={{ width: "128px" }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div>
                        <div id="surveyResult"></div>
                        <div id="divSurvey">
                          <Survey.Survey
                            model={model}
                            onComplete={this.onComplete}
                            onValueChanged={this.surveyValueChanged}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemoDashboard;
