import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import notie from "notie";
import $ from "jquery";
import CKEditor from "ckeditor4-react";
import "./ManageSiteDetail.css";

const { SearchBar } = Search;
const CKEconfig = {
  allowedContent: true,
  skin: "kama",
  embed_provider:
    "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}",
  image2_alignClasses: [
    "image-align-left",
    "image-align-center",
    "image-align-right"
  ],
  image2_disableResizer: true,
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"]
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
    },
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
    { name: "font", groups: ["font"] },
    "/",
    "/"
  ],
  extraPlugins: "print,format,font,justify,emoji,embed,autoembed,image2"
};

class ManageSiteDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,

      User_ID: this.props.location.state.User_ID,
      User_FullName: this.props.location.state.User_FullName,
      User_Email: this.props.location.state.User_Email,
      Comp_Name: this.props.location.state.Comp_Name,
      Site_Name: this.props.location.state.Site_Name,
      Site_Master_ID: this.props.location.state.Site_Master_ID,

      addSiteDetaillName: "",
      addSiteDetailContentTitle: "",
      addSiteDetailContentOrder: 1,
      addSiteDetailContent: "",
      addIsActive: 0,
      editSiteDetailName: "",
      editSiteDetailContentTitle: "",
      editSiteDetailContentOrder: 1,
      editSiteDetailContent: "",
      editIsActive: 0,
      Site_Detail_ID: "",
      previewSiteDetail: "",
      Site_Detail: [],
      loading: true,
      columns: [
        {
          dataField: "serial_number",
          text: "Sr #",
          sort: true
        },
        {
          dataField: "site_detail_name",
          text: "Site Detail Title",
          sort: true
        },
        {
          dataField: "site_detail_content_title",
          text: "Site Detail Content Title",
          sort: true
        },
        {
          dataField: "site_detail_order",
          text: "Site Detail Order",
          sort: true
        },
        {
          dataField: "isactive",
          text: "Active",
          sort: true
        },
        {
          dataField: "preview",
          text: "View",
          sort: false,
          formatter: this.SiteDetailPreviewFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        },
        {
          dataField: "send",
          text: "",
          sort: true,
          formatter: this.viewSendFormatter,
          headerAttrs: { width: 150, style: { textAlign: "center" } },
          attrs: {
            style: { textAlign: "center" }
          }
        },
        {
          dataField: "edit",
          text: "",
          sort: false,
          formatter: this.SiteDetailFormatter,
          headerAttrs: { width: 150 },
          attrs: { width: 150, className: "EditRow" }
        },
        {
          dataField: "delete",
          text: "Delete",
          sort: false,
          formatter: this.SiteDetailDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.getSiteDetailData = this.getSiteDetailData.bind(this);
    this.viewSiteDetailData = this.viewSiteDetailData.bind(this);
    this.viewSendFormatter = this.viewSendFormatter.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  viewSendFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "left",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          onClick={() =>
            notie.confirm({
              text: "Are you sure you want to SEND the email?",
              submitText: "Yes",
              cancelText: "No",
              position: "top",
              submitCallback: () =>
                this.sendEmail(
                  row.site_detail_id,
                  row.site_detail_name,
                  row.site_detail_content_title
                )
            })
          }
        >
          Send <span className="fa fa-envelope" />
        </span>
      </div>
    );
  };

  sendEmail(_Site_Detail_ID, _Site_Detail_Name, _Site_Detail_Content_Title) {
    try {
      let objSiteDetail = {
        site_name: this.state.Site_Name,
        site_detail_id: _Site_Detail_ID,
        site_detail_name: _Site_Detail_Name,
        site_detail_content_title: _Site_Detail_Content_Title,
        full_name: this.state.User_FullName,
        email: this.state.User_Email
      };

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}site_detail/api/adminSendNotifyEmail`,
        data: objSiteDetail,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      }).then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        } else {
          notie.alert({
            type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  GetAllSiteDetails() {
    const api = `${process.env.REACT_APP_BASE_URL}site_detail/api?site_master_id=${this.state.Site_Master_ID}`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Site_Detail: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onChangeAdd(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      addSiteDetailContent: newContent
    });
  }

  onChangeEdit(evt) {
    //console.log('onChange fired with event info: ', evt.editor.getData());
    var newContent = evt.editor.getData();
    this.setState({
      editSiteDetailContent: newContent
    });
  }

  handleAdd(e) {
    e.preventDefault();
    try {
      let objSiteDetail = {
        site_master_id: this.state.Site_Master_ID,
        site_detail_name: this.state.addSiteDetaillName,
        site_detail_content_title: this.state.addSiteDetailContentTitle,
        site_detail_order: this.state.addSiteDetailContentOrder,
        site_detail_content: this.state.addSiteDetailContent,
        isactive: this.state.addIsActive
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}site_detail/api/`,
        data: objSiteDetail,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return this.props.history.push({
              pathname: "/tcaccess"
            });
          }
          if (res.data.success === false) {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          } else {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            $("#add_new_site_detail").hide();

            this.GetAllSiteDetails();

            this.setState({
              addSiteDetaillName: "",
              addSiteDetailContent: "",
              addSiteDetailContentTitle: "",
              addSiteDetailContentOrder: 1,
              addIsActive: 1
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  SiteDetailFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-edit"
          onClick={() => this.getSiteDetailData(row.site_detail_id)}
        />
      </div>
    );
  };

  SiteDetailDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-trash"
          onClick={() =>
            notie.confirm({
              text: "Are you sure to delete this record ?",
              submitText: "Yes", // optional, default = 'Yes'
              cancelText: "No", // optional, default = 'Cancel'
              position: "top", // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.deleteSiteDetailData(row.site_detail_id) // optional
            })
          }
        />
      </div>
    );
  };

  SiteDetailPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-eye"
          onClick={() => this.viewSiteDetailData(row.site_detail_id)}
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();

    try {
      let objSiteDetail = {
        site_detail_id: this.state.editSiteDetailID,
        site_detail_name: this.state.editSiteDetailName,
        site_detail_content_title: this.state.editSiteDetailContentTitle,
        site_detail_order: this.state.editSiteDetailContentOrder,
        site_detail_content: this.state.editSiteDetailContent,
        isactive: this.state.editisActive
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      };

      let URL = `${process.env.REACT_APP_BASE_URL}site_detail/api/`;
      axios
        .put(URL, objSiteDetail, headers)
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              editSiteDetailID: "",
              editSiteDetailName: "",
              editSiteDetailContent: ""
            });

            $("#edit_site_detail").hide();

            this.GetAllSiteDetails();
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getSiteDetailData(_Site_Detail_ID) {
    try {
      this.setState({
        editSiteDetailID: "",
        editSiteDetailName: "",
        editSiteDetailContentTitle: "",
        editSiteDetailContentOrder: 1,
        editSiteDetailContent: "",
        editisActive: 1
      });
      this.setState({ Site_Detail_ID: _Site_Detail_ID });
      const api = `${process.env.REACT_APP_BASE_URL}site_detail/api/getSiteDetail?site_detail_id=${_Site_Detail_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          this.setState({
            editSiteDetailID: res.data[0].site_detail_id,
            editSiteDetailName: res.data[0].site_detail_name,
            editSiteDetailContentTitle: res.data[0].site_detail_content_title,
            editSiteDetailContentOrder: res.data[0].site_detail_order,
            editSiteDetailContent: res.data[0].site_detail_content,
            editisActive: res.data[0].isactive
          });

          $("#edit_site_detail").show();
        });
    } catch (error) {}
  }

  deleteSiteDetailData(_Site_Detail_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}site_detail/api/?site_detail_id=${_Site_Detail_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllSiteDetails();
          }
        });
    } catch (error) {}
  }

  viewSiteDetailData(_Site_Detail_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}site_detail/api/getSiteDetail?site_detail_id=${_Site_Detail_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            previewSiteDetail: res.data[0].site_detail_content
          });
          $("#preview_site_detail").show();
        });
    } catch (error) {}
  }

  componentWillMount = () => {
    //console.log('ManageSiteDetail will mount');
  };

  componentDidMount = () => {
    //console.log('ManageSiteDetail mounted');

    this.GetAllSiteDetails();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('ManageSiteDetail will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('ManageSiteDetail will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('ManageSiteDetail did update');
  };

  componentWillUnmount = () => {
    //console.log('ManageSiteDetail will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 card ">
            <div className="row">
              <div className="col-md-9">
                <h4 className="ml-3" id="heading">
                  {this.state.Comp_Name} - {this.state.Site_Name} Detail
                </h4>
              </div>
              <div className="col-md-3 col-sm-5 text-right text-sm-left ">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => $("#add_new_site_detail").show()}
                  style={{ display: "" }}
                >
                  Add New Site Detail
                </button>
              </div>
            </div>
            <hr />
            <div className="container-fluid">
              <div className="col-lg-12">
                <div className="example-wrap">
                  <div className="example">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="assets/images/tcc_loading.gif"
                          style={{ width: "128px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField="site_detail_id"
                          data={this.state.Site_Detail}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className="custome-search-field"
                                style={{ color: "#000" }}
                                placeholder="Search ..."
                              />
                              <hr />
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal myModal addSiteDetail"
          id="add_new_site_detail"
          style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form
              id="frmAdd"
              onSubmit={this.handleAdd}
              style={{ width: "100%" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title ml-40">Add New Site Detail</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#add_new_site_detail").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="addSiteDetaillName"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Title:
                      </label>
                      <input
                        type="text"
                        id="addSiteDetaillName"
                        className="form-control col-md-3"
                        name="addSiteDetaillName"
                        onChange={this.handleChange}
                        value={this.state.addSiteDetaillName}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addSiteDetailContentTitle"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Content Title:
                      </label>
                      <input
                        type="text"
                        id="addSiteDetailContentTitle"
                        className="form-control col-md-3"
                        name="addSiteDetailContentTitle"
                        onChange={this.handleChange}
                        value={this.state.addSiteDetailContentTitle}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addSiteDetailContentOrder"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Content Order:
                      </label>
                      <input
                        type="number"
                        min="0"
                        id="addSiteDetailContentOrder"
                        className="form-control col-md-3"
                        name="addSiteDetailContentOrder"
                        onChange={this.handleChange}
                        value={this.state.addSiteDetailContentOrder}
                        required
                      />
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="addSiteDetailContent"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Content:
                      </label>
                      <div className="col-md-9" style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.addSiteDetailContent}
                          onChange={this.onChangeAdd}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addIsActive"
                        className="col-sm-2 control-labels"
                      >
                        Active:
                      </label>
                      <div className="col-md-9">
                        <input
                          type="checkbox"
                          id="addIsActive"
                          name="addIsActive"
                          checked={this.state.addIsActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#add_new_site_detail").hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="addSiteDetail"
                    className="btn btn-success"
                  >
                    Save{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/** EDIT FORM */}
        <form id="frmEdit" onSubmit={this.handleEdit} style={{ width: "100%" }}>
          <div
            className="modal edit_site_detail myModal"
            id="edit_site_detail"
            style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title ml-40">Edit Site Detail</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#edit_site_detail").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="editSiteDetailName"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Title:
                      </label>
                      <input
                        type="text"
                        id="editSiteDetailName"
                        className="form-control col-md-3"
                        name="editSiteDetailName"
                        value={this.state.editSiteDetailName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editSiteDetailContentTitle"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Content Title:
                      </label>
                      <input
                        type="text"
                        id="editSiteDetailContentTitle"
                        className="form-control col-md-3"
                        name="editSiteDetailContentTitle"
                        onChange={this.handleChange}
                        value={this.state.editSiteDetailContentTitle}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editSiteDetailContentOrder"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Content Order:
                      </label>
                      <input
                        type="number"
                        min="0"
                        id="editSiteDetailContentOrder"
                        className="form-control col-md-3"
                        name="editSiteDetailContentOrder"
                        onChange={this.handleChange}
                        value={this.state.editSiteDetailContentOrder}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editSiteDetailContent"
                        className="col-sm-2 control-labels"
                      >
                        Site Detail Content:
                      </label>
                      <div className="col-md-9" style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.editSiteDetailContent}
                          onChange={this.onChangeEdit}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editisActive"
                        className="col-sm-2 control-labels"
                      >
                        Active:
                      </label>
                      <div className="col-md-9">
                        <input
                          type="checkbox"
                          id="editisActive"
                          name="editisActive"
                          checked={this.state.editisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#edit_site_detail").hide()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** PREWVIEW SITE DETAILS*/}
        <div
          className="modal preview_site_detail myModal"
          id="preview_site_detail"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Site Detail Preview</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => $("#preview_site_detail").hide()}
                >
                  ×
                </button>
              </div>

              <div className="modal-body">
                <div className="container-fluid">
                  <div className="form-group row">
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 100px)"
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.previewSiteDetail
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageSiteDetail;
