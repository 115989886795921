import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import notie from "notie";
//Import React Scrit Libraray to load Google object
import Script from "react-load-script";
import * as Survey from "survey-react";
import * as SurveyPDF from "survey-pdf";

import $ from "jquery";
import "./AgentManageQuotations.css";
import AgentQuotaionServey from "./AgentQuotationServey";

const { SearchBar } = Search;

class AgentManageQuotations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,

      User_ID: this.props.location.state.User_ID,
      User_FullName: this.props.location.state.User_FullName,
      Comp_Name: this.props.location.state.Comp_Name,
      Quotations: [],
      Quotation_JSON: this.props.Quotation_JSON,
      _Quotation_JSON: "",
      _Answer_JSON: "",
      Answer_JSON: {},
      addSiteName: "",
      city: "",
      addSiteAddress: "",
      loading: true,
      columns: [
        {
          dataField: "serial_number",
          text: "Sr #",
          sort: true
        },
        {
          dataField: "site_name",
          text: "Site Name",
          sort: true
        },
        {
          dataField: "site_address",
          text: "Site Address",
          sort: true
        },
        {
          dataField: "preview",
          text: "View",
          sort: false,
          formatter: this.UserQuotationPreviewFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        },
        {
          dataField: "delete",
          text: "Delete",
          sort: false,
          formatter: this.UserQuotationDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: "center" } },
          attrs: { width: 75, style: { textAlign: "center" } }
        }
      ]
    };

    Survey.StylesManager.applyTheme("orange");
    Survey.showProgressBar = "bottom";

    this.GetUserQuotations = this.GetUserQuotations.bind(this);
    this.viewUserQuotationData = this.viewUserQuotationData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  GetUserQuotations() {
    const api = `${process.env.REACT_APP_BASE_URL}user_quotation_json/api/getAgentUserQuotationsByUserID?user_id=${this.state.User_ID}`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_agent_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Quotations: res.data,
          loading: false
        });
      });
  }

  UserQuotationDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-trash"
          onClick={() =>
            notie.confirm({
              text: "Are you sure to delete this record ?",
              submitText: "Yes", // optional, default = 'Yes'
              cancelText: "No", // optional, default = 'Cancel'
              position: "top", // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.deleteUserQuotationData(row.user_quotation_id) // optional
            })
          }
        />
      </div>
    );
  };

  UserQuotationPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-eye"
          onClick={() => {
            const questionJSON = JSON.parse(
              JSON.parse(JSON.stringify(row.quotation_json))
            );
            console.log("questionJSON", questionJSON.isSinglePage);
            questionJSON.isSinglePage = true;
            console.log("questionJSON", questionJSON.isSinglePage);
            this.viewUserQuotationData(
              questionJSON,
              JSON.parse(JSON.parse(JSON.stringify(row.user_quotation_json)))
            );
          }}
        />
      </div>
    );
  };

  deleteUserQuotationData(_User_Quotation_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}user_quotation_json/api/Agent/?user_quotation_id=${_User_Quotation_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_agent_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetUserQuotations();
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  viewUserQuotationData(_Quotation_JSON, _Answer_JSON) {
    try {
      this.setState({
        _Quotation_JSON: _Quotation_JSON,
        _Answer_JSON: _Answer_JSON
      });
      $("#preview_quotation_detail").show();
    } catch (error) {
      console.log("error", error);
    }
  }

  componentWillMount = () => {
    //console.log('AgentManageQuotations will mount');
  };

  componentDidMount = () => {
    //console.log('AgentManageQuotations mounted');

    this.GetUserQuotations();
  };

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  saveSurveyToPdf(filename, surveyModel, pdfWidth, pdfHeight) {
    var options = {
      fontSize: 14,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10
      },
      format: [pdfWidth, pdfHeight]
    };
    var surveyPDF = new SurveyPDF.SurveyPDF(this.json, options);
    surveyPDF.data = surveyModel.data;
    surveyPDF.save(filename);
  }

  FormValidation(params) {
    if ($("addSiteName").val() === "") {
      return false;
    } else {
      return true;
    }
  }

  onComplete(survey, options) {
    //Write survey results into database

    //console.log("Survey results: ", JSON.stringify(survey.data));

    try {
      let objAnswerJSON = {
        quotation_id: 1,
        user_quotation_json: survey.data,
        site_name: this.state.addSiteName,
        site_address: this.state.addSiteAddress,
        user_id: this.state.User_ID
      };

      //console.log('this.state.addSignCheck', this.state.addSignCheck);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}user_quotation_json/api/Agent/`,
        data: objAnswerJSON,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_agent_token")}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }

          notie.alert({
            type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.setState({
            addSiteName: "",
            addSiteAddress: ""
          });
          document.getElementById("addSiteAddress").value = "";
          $("#add_new_quote").hide();
          this.GetUserQuotations();
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  surveyValueChanged(sender, options) {
    var el = document.getElementById(options.name);
    if (el) {
      el.value = options.value;
    }
  }

  surveyUploadFiles(survey, options) {
    options.files.map(file => {
      return {
        file: file,
        content: "filename"
      };
    });

    // var objPicture = new FormData();
    // options.files.forEach(function(file) {
    //   objPicture.append("upload", file);
    // });

    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_BASE_URL}uploader/api/`,
    //   data: objPicture,

    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
    //   }
    // }).then(res => {
    //   if (res.data.success === false) {
    //     notie.alert({
    //       type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
    //       text: res.data.message,
    //       stay: false, // optional, default = false
    //       time: 3, // optional, default = 3, minimum = 1,
    //       position: "top" // optional, default = 'top', enum: ['top', 'bottom']
    //     });
    //     return false;
    //   } else {
    //     options.callback("success", [
    //       {
    //         file: options.files[0],
    //         content: res.data.filename
    //       }
    //     ]);
    //   }
    // });
  }

  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      //types: ["(cities)"]
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("addSiteAddress"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields(["address_components", "formatted_address"]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };

  handlePlaceSelect = () => {
    // Extract City From Address Object

    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      this.setState({
        city: address[0].long_name,
        addSiteAddress: addressObject.formatted_address
      });
    }
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading, Quotation_JSON, addSiteName, addSiteAddress } = this.state;

    var model = new Survey.Model(Quotation_JSON);

    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCi-NEC22R8UsLb858gdTZY4SjGF1r7gMg&libraries=places"
          onLoad={this.handleScriptLoad}
        />
        <div className="row">
          <div className="col-md-12 card ">
            <div className="row">
              <div className="col-md-9">
                <h4 className="ml-3" id="heading">
                  {this.state.Comp_Name} - Quotations
                </h4>
              </div>
            </div>
            <hr />
            <div className="container-fluid">
              <div className="col-lg-12">
                <div className="example-wrap">
                  <div className="row">
                    <div className="col-md-9"></div>
                    <div className="col-md-3 col-sm-5 text-right text-sm-left ">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => $("#add_new_quote").show()}
                        style={{ display: "" }}
                      >
                        Add New Quotation
                      </button>
                    </div>
                  </div>
                  <div className="example">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="assets/images/tcc_loading.gif"
                          style={{ width: "128px" }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField="user_quotation_id"
                          data={this.state.Quotations}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className="custome-search-field"
                                style={{ color: "#000" }}
                                placeholder="Search ..."
                              />
                              <hr />
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** ADD NEW QUOTATION */}
        <div
          className="modal myModal addQuotation"
          id="add_new_quote"
          style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title ml-10">Add New Quotation</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => $("#add_new_quote").hide()}
                >
                  ×
                </button>
              </div>

              <div className="modal-body">
                <div className="container-fluid">
                  <div className="form-group row">
                    <label
                      htmlFor="addSiteName"
                      className="col-sm-2 control-labels"
                    >
                      Site Name:
                    </label>

                    <input
                      type="text"
                      id="addSiteName"
                      className="form-control col-md-3"
                      name="addSiteName"
                      value={this.state.addSiteName}
                      onChange={this.handleChange}
                      required
                      maxLength="150"
                    />
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="addSiteAddress"
                      className="col-sm-2 control-labels"
                    >
                      Site Address:
                    </label>

                    <input
                      type="text"
                      className="form-control col-md-3"
                      name="addSiteAddress"
                      id="addSiteAddress"
                      required
                    />
                  </div>

                  {addSiteName !== "" && addSiteAddress !== "" ? (
                    <div className="form-group row">
                      {" "}
                      <Survey.Survey
                        model={model}
                        onComplete={this.onComplete}
                        onValueChanged={this.surveyValueChanged}
                        onUploadFiles={this.surveyUploadFiles}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => $("#add_new_quote").hide()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/** EDIT QUOTAION */}
        <form id="frmEdit" onSubmit={this.handleEdit} style={{ width: "100%" }}>
          <div
            className="modal edit_cms myModal"
            id="edit_cms"
            style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title ml-40">Edit CMS</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#edit_quote").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="editSiteName"
                        className="col-sm-2 control-labels"
                      >
                        Site Name:
                      </label>
                      <input
                        type="text"
                        id="editSiteName"
                        className="form-control col-md-3"
                        name="editSiteName"
                        value={this.state.editSiteName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editCMSContentTitle"
                        className="col-sm-2 control-labels"
                      >
                        Site Address:
                      </label>
                      <input
                        type="text"
                        id="editSiteAddress"
                        className="form-control col-md-3"
                        name="editSiteAddress"
                        onChange={this.handleChange}
                        value={this.state.editSiteAddress}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editCMSContent"
                        className="col-sm-2 control-labels"
                      >
                        SURVEY
                      </label>
                      <div
                        className="col-md-9"
                        style={{ paddingLeft: 0 }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#edit_cms").hide()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        {/** PREWVIEW QUOTATION*/}
        <div
          className="modal preview_quotation_detail myModal"
          id="preview_quotation_detail"
          style={{
            display: "none",
            paddingLeft: "15px",
            overflow: "auto"
          }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Quotation Preview</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => $("#preview_quotation_detail").hide()}
                >
                  ×
                </button>
              </div>

              <div className="modal-body">
                <div className="container-fluid">
                  <div className="form-group row">
                    {this.state._Quotation_JSON == "" ? (
                      ""
                    ) : (
                      <AgentQuotaionServey
                        _Quotation_JSON={this.state._Quotation_JSON}
                        _Answer_JSON={this.state._Answer_JSON}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentManageQuotations;
