import React, { PureComponent } from 'react';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';

class clsSurvey extends PureComponent {
  state = { trimmedDataURL: null };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
    Survey.StylesManager.applyTheme('orange');
    Survey.showProgressBar = 'bottom';
    this.json = {
      completeText: 'Finish',
      pageNextText: 'Continue',
      pagePrevText: 'Previous',
      title: 'Software developer survey.',
      pages: [
        {
          title: 'What operating system do you use?',
          questions: [
            {
              type: 'checkbox',
              name: 'opSystem',
              title: 'OS',
              hasOther: true,
              isRequired: true,
              choices: ['Windows', 'Linux', 'Macintosh OSX']
            }
          ]
        },
        {
          title: 'What language(s) are you currently using?',
          questions: [
            {
              type: 'checkbox',
              name: 'langs',
              title: 'Please select from the list',
              colCount: 4,
              isRequired: true,
              choices: [
                'Javascript',
                'Java',
                'Python',
                'CSS',
                'PHP',
                'Ruby',
                'C++',
                'C',
                'Shell',
                'C#',
                'Objective-C',
                'R',
                'VimL',
                'Go',
                'Perl',
                'CoffeeScript',
                'TeX',
                'Swift',
                'Scala',
                'Emacs Lisp',
                'Haskell',
                'Lua',
                'Clojure',
                'Matlab',
                'Arduino',
                'Makefile',
                'Groovy',
                'Puppet',
                'Rust',
                'PowerShell'
              ]
            }
          ]
        },
        {
          title: 'Please enter your name and e-mail',
          questions: [
            {
              type: 'text',
              name: 'name',
              title: 'Name:'
            },
            {
              type: 'text',
              name: 'email',
              title: 'Your e-mail'
            }
          ]
        }
      ],
      completedHtml:
        '<p><h4>Thank you for sharing this information with us.</h4></p>'
    };
  }

  onComplete(survey, options) {
    //Write survey results into database
    console.log('Survey results: ' + JSON.stringify(survey.data));
    document.querySelector('#surveyResult').textContent =
      'Result JSON:\n' + JSON.stringify(survey.data, null, 3);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    var model = new Survey.Model(this.json);

    return (
      <div className='SignaturePadWrapper'>
        <div style={{ height: 'auto', width: '100%' }}>
          <div className='container-fluid'></div>
          <div id='surveyResult'></div>
          <Survey.Survey model={model} onComplete={this.onComplete} />
        </div>
      </div>
    );
  }
}

export default clsSurvey;
