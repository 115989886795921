import React from "react";
import notie from "notie";
import axios from "axios";
import moment from "moment";
import "./UserSiteInfo.css";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class UserSiteInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Comp_Name: this.props.Comp_Name,
      Comp_Phone_No: this.props.Comp_Phone_No,
      Comp_Address: this.props.Comp_Address,

      Order_Form_Instruction: "",
      Order_Form_Contact_Info_Title: "",
      Order_Form_Contact_Info: "",
      Order_Form_Site_Info_Title: "",
      Order_Form_Site_Info: "",
      Factory_Acceptance_Checklist: "",
      Customer_Site_Acceptance_Plan: "",
      Current_Date: moment().format("MM-DD-YYYY"),
      Site_Master: [],
      Site_Detail: [],
      Site_Master_Tabs: [],

      Site_Master_Tabs_HTML: [],
      Site_Master_TabPanel_HTML: [],

      customer_company_name: "",
      customer_title: "",
      customer_full_name: "",
      customer_contact_number: "",
      customer_address: "",

      hdCalled: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.GetSiteMaster = this.GetSiteMaster.bind(this);
    this.GetSiteDetail = this.GetSiteDetail.bind(this);
  }

  GetSiteMaster() {
    const api = `${process.env.REACT_APP_BASE_URL}site_master/api/getClientSiteMaster`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/client_login");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);

        this.setState({
          Site_Master: res.data
        });

        this.GetSiteDetail();
      });
  }

  GetSiteDetail() {
    const api = `${process.env.REACT_APP_BASE_URL}site_detail/api/getClientSiteDetail`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_user_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/client_login");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        //console.log(res.data);

        this.setState({
          Site_Detail: res.data
        });

        this.state.Site_Master.map((item, key) => {
          this.setState({
            Site_Master_Tabs_HTML: [
              ...this.state.Site_Master_Tabs_HTML,
              <Tab key={item.site_master_id}>{item.site_master_name}</Tab>
            ]
          });

          let Site_Master_ID = item.site_master_id;

          let tab_data = this.state.Site_Detail.filter(function(
            _Site_Master_ID
          ) {
            return _Site_Master_ID.site_master_id === Site_Master_ID;
          });

          this.setState({
            Site_Master_TabPanel_HTML: [
              ...this.state.Site_Master_TabPanel_HTML,
              this.drawTabs(tab_data, Site_Master_ID)
            ]
          });
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    localStorage.setItem(target.name, value);
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleOptionChange = changeEvent => {
    const target = changeEvent.target;

    const value = target.type === "radio" ? target.value : target.checked;

    const name = target.name;
    console.log("target.name", target.name, "target.value", value);
    this.setState({
      [name]: value
    });
  };

  componentDidMount = () => {
    $(".active_left_menu").removeClass("active_left_menu");
    $("#lnksiteinfo").addClass("active_left_menu");

    this.GetSiteMaster();
  };

  drawTabs(items, Site_Master_ID) {
    return (
      <TabPanel key={Site_Master_ID}>
        <Tabs forceRenderTabPanel>
          <TabList>
            {items.map((value, key) => (
              <Tab key={key}> {value.site_detail_content_title}</Tab>
            ))}
          </TabList>
          {items.map((value, key) => (
            <TabPanel key={value.site_detail_id}>
              <div
                dangerouslySetInnerHTML={{
                  __html: value.site_detail_content
                }}
              />{" "}
            </TabPanel>
          ))}
        </Tabs>
      </TabPanel>
    );
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { Site_Master_Tabs_HTML, Site_Master_TabPanel_HTML } = this.state;

    {
      /**
    const Site_Master_Tabs_HTML = [];
    const Site_Master_TabPanel_HTML = [];

    this.state.Site_Master.map((item, key) => {
      Site_Master_Tabs_HTML.push(
        <Tab key={item.site_master_id}>{item.site_master_name}</Tab>
      );
      let Site_Master_ID = item.site_master_id;

      let tab_data = this.state.Site_Detail.filter(function(_Site_Master_ID) {
        return _Site_Master_ID.site_master_id === Site_Master_ID;
      });
      Site_Master_TabPanel_HTML.push(this.drawTabs(tab_data, Site_Master_ID));
    });*/
    }
    return (
      <div>
        <div className="loadingDiv" style={{ display: "none" }} />
        <div className="page-header">
          <h1 className="page-title">Site Info</h1>
        </div>
        <input
          type="hidden"
          id="hdCalled"
          name="hdCalled"
          onChange={this.handleChange}
        />
        <div className="page-content">
          <div className="panel">
            <div className="panel-body">
              <Tabs id="divTabs" forceRenderTabPanel defaultIndex={0}>
                <TabList>{Site_Master_Tabs_HTML}</TabList>
                {Site_Master_TabPanel_HTML}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserSiteInfo;
