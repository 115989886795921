import React, { useState } from "react";
import { Route, Redirect, NavLink } from "react-router-dom";
import lifecycle from "react-pure-lifecycle";
import { AuthConsumer } from "../../context/AuthContextDemo";
import $ from "jquery";
import notie from "notie";
import axios from "axios";
import InputMask from "react-input-mask";
import DemoLogin from "../../pages/demo_module/DemoLogin";
import "./DemoMainLayout.css";

const methods = {
  componentDidMount(props) {
    document.body.classList.remove("page-login-v2", "layout-full", "page-dark");
    document.body.classList.add("site-navbar-small");
  }
};

const DemoMainLayout = ({ children, ...rest }) => {
  let [_txtOldPassword, setOldPassword] = useState("");
  let [_txtNewPassword, setNewPassword] = useState("");
  let [_txtConfirmPassword, setConfirmPassword] = useState("");

  const [updateKey, setUpdateKey] = useState(1);
  const refreshControl = () => setUpdateKey(updateKey + 1);

  const [values, setValues] = useState({
    demo_comp_name: "",
    demo_name: "",
    demo_phone_no: "",
    demo_email: "",
    demo_title: "",
    demo_address: "",
    ambassador_id: ""
  });

  function handleEdit(e) {
    e.preventDefault();
    try {
      notie.alert({
        type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: "Profile Updated Successfully",
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
      refreshControl();
      $("#edit_demo").hide();
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  function changePassword(event) {
    event.preventDefault();
    const matches = _txtNewPassword === _txtConfirmPassword;
    matches
      ? console.log("matched")
      : notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "New Password & Confirm Password Not Same",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });

    if (matches) {
      try {
        notie.alert({
          type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: "Password Changed Successfully.",
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
        $("#change_password").hide();
      } catch (error) {
        notie.alert({
          type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: error,
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: "top" // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
    }
  }

  function inputPassword(event) {
    if (event.target.name === "txtOldPassword") {
      setOldPassword((_txtOldPassword = event.target.value));
    } else if (event.target.name === "txtNewPassword") {
      setNewPassword((_txtNewPassword = event.target.value));
    } else if (event.target.name === "txtConfirmPassword") {
      setConfirmPassword((_txtConfirmPassword = event.target.value));
    }
  }
  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  function getDemoData() {
    try {
      setValues({
        ...values,
        demo_comp_name: "Demo Company",
        demo_name: "Demo Name",
        demo_phone_no: "1234567890",
        demo_fax: "1234567890",
        demo_email: "demo@hungryautomation.com",
        demo_title: "Demo Title",
        demo_address: "USA",
        ambassador_id: "demo"
      });

      $("#edit_demo").show();
    } catch (error) {}
  }

  return (
    <AuthConsumer>
      {({
        isAuthDemo,
        Demo_ID,
        login,
        logout,
        demo_comp_name,
        Comp_Logo,
        isSiteinfo,
        Quotation_JSON
      }) => (
        <React.Fragment>
          <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggler collapsed"
                data-target="#site-navbar-collapse"
                data-toggle="collapse"
              >
                <i className="icon wb-more-horizontal" aria-hidden="true" />
              </button>

              <div
                className="navbar-brand navbar-brand-center site-gridmenu-toggle"
                data-toggle="gridmenu"
              >
                <img className="navbar-brand-logo" alt="logo" src={Comp_Logo} />
              </div>
            </div>

            <div className="navbar-container container-fluid">
              <div
                className="collapse navbar-collapse navbar-collapse-toolbar"
                id="site-navbar-collapse"
              >
                <ul className="nav navbar-toolbar navbar-toolbar-right">
                  <li className="nav-item dropdown" style={{ display: "" }}>
                    <NavLink
                      to="demo_dashboard"
                      className="nav-link"
                      role="button"
                    >
                      {" "}
                      <span className="fa fa-home" />
                      Dashboard
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <NavLink
                      to="demo_clients"
                      className="nav-link"
                      role="button"
                    >
                      {" "}
                      <span className="fa fa-users">&nbsp;</span>
                      Clients
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown d-block d-none d-sm-block d-md-none">
                    <a
                      className="nav-link"
                      href="javascript:void(0)"
                      demo_title="Change Password"
                      role="button"
                      onClick={() => $("#change_password").show()}
                    >
                      <i className="fa fa-key" aria-hidden="true" />
                      Change Password
                    </a>
                  </li>

                  <li className="nav-item dropdown d-block d-none d-sm-block d-md-none">
                    <a
                      className="nav-link"
                      href="javascript:void(0)"
                      demo_title="Log Out"
                      role="button"
                      onClick={logout}
                    >
                      <i className="fa fa-power-off" aria-hidden="true" />
                      Log
                    </a>
                  </li>
                </ul>
                <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
                  <li className="nav-item hidden-float" id="toggleMenubar">
                    <a
                      className="nav-link"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="false"
                      data-animation="scale-up"
                      role="button"
                    >
                      <i className="icon wb-user-circle" aria-hidden="true" />
                    </a>
                    <div className="dropdown-menu" role="menu">
                      <a
                        className="dropdown-item"
                        href="javascript:void(0)"
                        role="menuitem"
                        onClick={() => $("#change_password").show()}
                      >
                        <i className="icon wb-lock" aria-hidden="true" />
                        Change Password
                      </a>
                      <a
                        className="dropdown-item"
                        href="javascript:void(0)"
                        role="menuitem"
                        onClick={() => getDemoData()}
                      >
                        <i className="icon wb-user" aria-hidden="true" /> Edit
                        Profile
                      </a>
                      <div className="dropdown-divider" role="presentation" />

                      <a
                        className="dropdown-item"
                        href="javascript:void(0)"
                        role="menuitem"
                        onClick={logout}
                      >
                        <i className="icon wb-power" aria-hidden="true" />{" "}
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="page flx clr">
            <div
              className="page-content container"
              style={{ minHeight: "580px" }}
            >
              {children}
            </div>
          </div>
          <form id="frmChangePassword" onSubmit={changePassword}>
            <div className="modal myModal" id="change_password">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 id="heading" className="modal-demo_title">
                      Change Password
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => $("#change_password").hide()}
                    >
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="container-fluid">
                      <div className="form-group row ">
                        <label
                          htmlFor="txtOldPassword"
                          className="col-md-4 label-clr mt-2"
                        >
                          Old Password:
                        </label>
                        <input
                          type="password"
                          id="txtOldPassword"
                          className="form-control col-md-6"
                          name="txtOldPassword"
                          placeholder="Enter Old Password"
                          onChange={inputPassword}
                          required
                        />
                      </div>
                      <div className="form-group row ">
                        <label
                          htmlFor="txtNewPassword"
                          className="col-md-4 label-clr mt-2"
                        >
                          New Password:
                        </label>
                        <input
                          type="password"
                          id="txtNewPassword"
                          className="form-control col-md-6"
                          name="txtNewPassword"
                          placeholder="Enter New Password"
                          onChange={inputPassword}
                          required
                        />
                      </div>
                      <div className="form-group row ">
                        <label
                          htmlFor="txtConfirmPassword"
                          className="col-md-4 label-clr mt-2"
                        >
                          Confirm Password:
                        </label>
                        <input
                          type="password"
                          id="txtConfirmPassword"
                          className="form-control col-md-6"
                          name="txtConfirmPassword"
                          placeholder="Re-Enter New Password"
                          onChange={inputPassword}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => $("#change_password").hide()}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-success">
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/** EDIT FORM */}
          <form onSubmit={handleEdit}>
            <div
              className="modal myModal edit_demo"
              id="edit_demo"
              style={{
                display: "none",
                paddingLeft: "15px",
                overflow: "auto"
              }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Demo</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => $("#edit_demo").hide()}
                    >
                      ×
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="container-fluid">
                      <div className="form-group row">
                        <label htmlFor="ambassador_id" className="col-md-4">
                          Ambassador ID:
                        </label>
                        <input
                          type="text"
                          id="editAmbassadorID"
                          className="form-control col-md-6"
                          name="editAmbassadorID"
                          value={values.ambassador_id}
                          onChange={handleInputChange}
                          readOnly
                          maxLength="150"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="demo_comp_name" className="col-md-4">
                          Demo Company:
                        </label>
                        <input
                          type="text"
                          id="demo_comp_name"
                          className="form-control col-md-6"
                          name="demo_comp_name"
                          value={values.demo_comp_name}
                          onChange={handleInputChange}
                          required
                          maxLength="150"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="demo_name" className="col-md-4">
                          Demo Name:
                        </label>
                        <input
                          type="text"
                          id="demo_name"
                          className="form-control col-md-6"
                          name="demo_name"
                          value={values.demo_name}
                          onChange={handleInputChange}
                          required
                          maxLength="150"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="demo_title" className="col-md-4">
                          Demo Title:
                        </label>
                        <input
                          type="text"
                          id="demo_title"
                          className="form-control col-md-6"
                          name="demo_title"
                          value={values.demo_title}
                          onChange={handleInputChange}
                          required
                          maxLength="100"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="demo_email" className="col-md-4">
                          Email Address:
                        </label>
                        <input
                          type="email"
                          id="demo_email"
                          className="form-control col-md-6"
                          name="demo_email"
                          value={values.demo_email}
                          readOnly
                          maxLength="250"
                        />
                      </div>

                      <div className="form-group row">
                        <label htmlFor="demo_phone_no" className="col-md-4">
                          Contact Number:
                        </label>
                        <InputMask
                          mask="99999999999"
                          pattern=".{10,}"
                          maskChar={null}
                          id="demo_phone_no"
                          className="form-control col-md-6"
                          name="demo_phone_no"
                          placeholder="Contact Number 2134567890"
                          title="Contact Number 2134567890"
                          value={values.demo_phone_no}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="demo_fax" className="col-md-4">
                          Fax Number:
                        </label>

                        <InputMask
                          mask="99999999999"
                          pattern=".{10,}"
                          maskChar={null}
                          id="demo_fax"
                          className="form-control col-md-6"
                          name="demo_fax"
                          placeholder="Fax Number 12134567890"
                          title="Fax Number 12134567890"
                          value={values.demo_fax}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="demo_address" className="col-md-4">
                          Address :
                        </label>
                        <textarea
                          id="demo_address"
                          className="form-control col-md-6"
                          name="demo_address"
                          placeholder="Address"
                          value={values.demo_address}
                          onChange={handleInputChange}
                          required
                          maxLength="250"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => $("#edit_demo").hide()}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-success">
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <footer className="site-footer">
            <div className="site-footer-legal">
              <span id="spdemo_comp_name">
                Hungry Tech & Automation LLC. A wholly owned subsidiary of
                Hungry Tech Inc.
                <a
                  href="http://www.
Hungry1.com"
                  target="_blank"
                >
                  {" "}
                  www. Hungry1.com
                </a>
              </span>
            </div>
          </footer>
        </React.Fragment>
      )}
    </AuthConsumer>
  );
};

const DemoMainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthConsumer>
      {({ isAuthDemo, Demo_ID, isSiteInfo, Quotation_JSON }) => (
        <Route
          render={matchProps =>
            isAuthDemo ? (
              <DemoMainLayout>
                <Component
                  {...matchProps}
                  Demo_ID={Demo_ID}
                  isSiteInfo={isSiteInfo}
                  Quotation_JSON={Quotation_JSON}
                />
              </DemoMainLayout>
            ) : (
              <Redirect to="/tcdemo" component={DemoLogin} />
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
};

export default lifecycle(methods)(DemoMainLayoutRoute);
