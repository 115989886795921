import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import notie from "notie";
import $ from "jquery";
import CKEditor from "ckeditor4-react";
import "./ManageCannedEmail.css";

const { SearchBar } = Search;
const CKEconfig = {
  doctype: "html",
  fullPage: true,
  allowedContent: true,
  skin: "kama",
  embed_provider:
    "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}",
  image2_alignClasses: [
    "image-align-left",
    "image-align-center",
    "image-align-right"
  ],
  image2_disableResizer: true,
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"]
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
    },
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
    { name: "font", groups: ["font"] },
    "/",
    "/"
  ],
  extraPlugins: "print,format,font,justify,emoji,embed,autoembed,image2"
};
class ManageCannedEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addCannedEmailTitle: "",
      addCannedEmailSubject: "",
      addCannedEmailContent: "",
      addIsActive: 0,
      editCannedEmailTitle: "",
      editCannedEmailSubject: "",
      editCannedEmailContent: "",
      editIsActive: 0,
      Canned_Email_ID: "",
      previewCannedEmail: "",
      Canned_Emails: [],
      loading: true,
      columns: [
        {
          dataField: "serial_number",
          text: "Sr #",
          sort: true
        },
        {
          dataField: "canned_email_title",
          text: "Canned Email Title",
          sort: true
        },
        {
          dataField: "canned_email_subject",
          text: "Canned Email Subject",
          sort: true
        },

        {
          dataField: "edit",
          text: "",
          sort: false,
          formatter: this.cannedemailFormatter,
          headerAttrs: { width: 150 },
          attrs: { width: 150, className: "EditRow" }
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.getCannedEmailData = this.getCannedEmailData.bind(this);
    this.viewCannedEmailData = this.viewCannedEmailData.bind(this);
  }

  GetAllCannedEmails() {
    const api = `${process.env.REACT_APP_BASE_URL}canned_email/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = "/tcaccess");
        }
        if (res.data.success === false) {
          return notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Canned_Emails: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onChangeAdd(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      addCannedEmailContent: newContent
    });
  }

  onChangeEdit(evt) {
    //console.log('onChange fired with event info: ', evt.editor.getData());
    var newContent = evt.editor.getData();
    this.setState({
      editCannedEmailContent: newContent
    });
  }

  handleAdd(e) {
    e.preventDefault();
    try {
      let objCannedEmail = {
        canned_email_id: this.state.addCannedEmailID,
        canned_email_title: this.state.addCannedEmailTitle,
        canned_email_subject: this.state.addCannedEmailSubject,
        canned_email_template: this.state.addCannedEmailContent
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}canned_email/api/`,
        data: objCannedEmail,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return this.props.history.push({
              pathname: "/tcaccess"
            });
          }
          if (res.data.success === false) {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Canned Email Added Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            $("#add_new_canned_email").hide();

            this.GetAllCannedEmails();

            this.setState({
              addCannedEmailTitle: "",
              addCannedEmailSubject: "",
              addCannedEmailContent: ""
            });
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  cannedemailFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-edit"
          onClick={() => this.getCannedEmailData(row.canned_email_id)}
        />
      </div>
    );
  };

  cannedemailDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-trash"
          onClick={() =>
            notie.confirm({
              text: "Are you sure to delete this record ?",
              submitText: "Yes", // optional, default = 'Yes'
              cancelText: "No", // optional, default = 'Cancel'
              position: "top", // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.deleteCannedEmailData(row.canned_email_id) // optional
            })
          }
        />
      </div>
    );
  };

  cannedemailPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <span
          className="fa fa-eye"
          onClick={() => this.viewCannedEmailData(row.canned_email_id)}
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();

    try {
      let objCannedEmail = {
        canned_email_id: this.state.editCannedEmailID,
        canned_email_title: this.state.editCannedEmailTitle,
        canned_email_subject: this.state.editCannedEmailSubject,
        canned_email_template: this.state.editCannedEmailContent
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
        }
      };

      let URL = `${process.env.REACT_APP_BASE_URL}canned_email/api/`;
      axios
        .put(URL, objCannedEmail, headers)
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Canned Email Updated Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              editCannedEmailID: "",
              editCannedEmailTitle: "",
              editCannedEmailSubject: "",
              editCannedEmailContent: ""
            });

            $("#edit_canned_email").hide();

            this.GetAllCannedEmails();
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: "top" // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: "top" // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getCannedEmailData(_Canned_Email_ID) {
    try {
      this.setState({
        editCannedEmailID: "",
        editCannedEmailTitle: "",
        editCannedEmailContent: ""
      });
      this.setState({ Canned_Email_ID: _Canned_Email_ID });
      const api = `${process.env.REACT_APP_BASE_URL}canned_email/api/getCannedEmail?canned_email_id=${_Canned_Email_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          this.setState({
            editCannedEmailID: res.data[0].canned_email_id,
            editCannedEmailTitle: res.data[0].canned_email_title,
            editCannedEmailSubject: res.data[0].canned_email_subject,
            editCannedEmailContent: res.data[0].canned_email_template
          });

          $("#edit_canned_email").show();
        });
    } catch (error) {}
  }

  deleteCannedEmailData(_Canned_Email_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}canned_email/api/?canned_email_id=${_Canned_Email_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: "success", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Canned Email Deleted Successfully",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllCannedEmails();
          } else {
            notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: "Some problem in deleting record.",
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  viewCannedEmailData(_Canned_Email_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}canned_email/api/getCannedEmail?canned_email_id=${_Canned_Email_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tcc_token")}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = "/tcaccess");
          }
          if (res.data.success === false) {
            return notie.alert({
              type: "error", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            previewCannedEmail: res.data[0].canned_email_template
          });
          $("#preview_canned_email").show();
        });
    } catch (error) {}
  }

  componentWillMount = () => {
    //console.log('ManageCannedEmail will mount');
  };

  componentDidMount = () => {
    //console.log('ManageCannedEmail mounted');

    this.GetAllCannedEmails();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('ManageCannedEmail will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('ManageCannedEmail will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('ManageCannedEmail did update');
  };

  componentWillUnmount = () => {
    //console.log('ManageCannedEmail will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12 card ">
            <div className="row">
              <div className="col-md-9">
                <h4 className="ml-3" id="heading">
                  Manage Canned Email
                </h4>
              </div>
              <div className="col-md-3 col-sm-5 text-right text-sm-left ">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => $("#add_new_canned_email").show()}
                >
                  Add New Canned Email
                </button>
              </div>
            </div>
            <hr />
            <div className="container-fluid">
              <div className="col-lg-12">
                <div className="example-wrap">
                  <div className="example">
                    {loading ? (
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="assets/images/tcc_loading.gif"
                          style={{ width: "128px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField="canned_email_id"
                          data={this.state.Canned_Emails}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className="custome-search-field"
                                style={{ color: "#000" }}
                                placeholder="Search ..."
                              />
                              <hr />
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal myModal addCannedEmail"
          id="add_new_canned_email"
          style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form
              id="frmAdd"
              onSubmit={this.handleAdd}
              style={{ width: "100%" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title ml-40">Add New Canned Email</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#add_new_canned_email").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="addCannedEmailTitle"
                        className="col-sm-2 control-labels"
                      >
                        Email Title:
                      </label>
                      <input
                        type="text"
                        id="addCannedEmailTitle"
                        className="form-control col-md-3"
                        name="addCannedEmailTitle"
                        onChange={this.handleChange}
                        value={this.state.addCannedEmailTitle}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editCannedEmailSubject"
                        className="col-sm-2 control-labels"
                      >
                        Email Subject:
                      </label>
                      <input
                        type="text"
                        id="addCannedEmailSubject"
                        className="form-control col-md-3"
                        name="addCannedEmailSubject"
                        value={this.state.addCannedEmailSubject}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="addCannedEmailContent"
                        className="col-sm-2 control-labels"
                      >
                        Email Content:
                      </label>
                      <div className="col-md-9" style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.addCannedEmailContent}
                          onChange={this.onChangeAdd}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-labels">
                        Email Place Holders:
                      </label>
                      &#123;&#123;comp_name&#125;&#125;
                      &#123;&#123;support_email&#125;&#125;
                      &#123;&#123;client_name&#125;&#125;
                      &#123;&#123;signee_name&#125;&#125;
                      &#123;&#123;vendor_name&#125;&#125;
                      &#123;&#123;agreement_title&#125;&#125;
                      &#123;&#123;agreement_decline_reaason&#125;&#125;
                      &#123;&#123;product_name&#125;&#125;
                      <br />
                      &#123;&#123;site_info_detail_title&#125;&#125;
                      &#123;&#123;agent_name&#125;&#125;
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#add_new_canned_email").hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="addCannedEmail"
                    className="btn btn-success"
                  >
                    Save{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/** EDIT FORM */}
        <form id="frmEdit" onSubmit={this.handleEdit} style={{ width: "100%" }}>
          <div
            className="modal edit_canned_email myModal"
            id="edit_canned_email"
            style={{ display: "none", paddingLeft: "15px", overflow: "auto" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title ml-40">Edit Canned Email</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={() => $("#edit_canned_email").hide()}
                  >
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="form-group row">
                      <label
                        htmlFor="editCannedEmailTitle"
                        className="col-sm-2 control-labels"
                      >
                        Email Title:
                      </label>
                      <input
                        type="text"
                        id="editCannedEmailTitle"
                        className="form-control col-md-3"
                        name="editCannedEmailTitle"
                        value={this.state.editCannedEmailTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="editCannedEmailSubject"
                        className="col-sm-2 control-labels"
                      >
                        Email Subject:
                      </label>
                      <input
                        type="text"
                        id="editCannedEmailSubject"
                        className="form-control col-md-3"
                        name="editCannedEmailSubject"
                        value={this.state.editCannedEmailSubject}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="editCannedEmailContent"
                        className="col-sm-2 control-labels"
                      >
                        Email Content:
                      </label>
                      <div className="col-md-9" style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.editCannedEmailContent}
                          onChange={this.onChangeEdit}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-labels">
                        Email Place Holders:
                      </label>
                      &#123;&#123;comp_name&#125;&#125;
                      &#123;&#123;support_email&#125;&#125;
                      &#123;&#123;client_name&#125;&#125;
                      &#123;&#123;signee_name&#125;&#125;
                      &#123;&#123;vendor_name&#125;&#125;
                      &#123;&#123;agreement_title&#125;&#125;
                      &#123;&#123;agreement_decline_reaason&#125;&#125;
                      &#123;&#123;product_name&#125;&#125;
                      <br />
                      &#123;&#123;site_info_detail_title&#125;&#125;
                      &#123;&#123;agent_name&#125;&#125;
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => $("#edit_canned_email").hide()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageCannedEmail;
