import React, { Component } from 'react';
import notie from 'notie';
import $ from 'jquery';
import axios from 'axios';

class ProductCreditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: true,
      Select_Credit_Form: [],
      Product_ID: props.Product_ID,
      Product_Rate: props.Product_Rate,
      Product_Title: props.Product_Title
    };
    this.getProductCreditForm = this.getProductCreditForm.bind(this);
  }

  getProductCreditForm(_Product_ID) {
    try {
      this.setState({
        Product_ID: _Product_ID
      });
      const api = `${
        process.env.REACT_APP_BASE_URL
      }credit_form/api/getProductCreditForm?product_id=${_Product_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            Select_Credit_Form: res.data,
            loading: false
          });
        });
    } catch (error) {}
  }
  componentWillMount() {
    this.getProductCreditForm(this.state.Product_ID);
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <img
              src='../assets/images/tcc_loading.gif'
              alt='...'
              style={{ width: '40px' }}
            />
          </div>
        ) : (
          <React.Fragment>
            {this.state.Select_Credit_Form.length === 0 ? (
              <React.Fragment>
                <span
                  className='fa fa-pen'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/client_credit',
                      state: {
                        product_id: `{"value":"${this.state.Product_ID}|${
                          this.state.Product_Rate
                        }","label":"${this.state.Product_Title}"}`,
                        product_value: `${this.state.Product_ID}|${
                          this.state.Product_Rate
                        }`
                      }
                    });
                  }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.state.Select_Credit_Form.map((data, index) => (
                  <a
                    key={data.credit_form_id}
                    style={{ cursor: 'pointer' }}
                    className='fa fa-file'
                    target='_blank'
                    href={
                      process.env.REACT_APP_BASE_URL + data.credit_form_path
                    }
                  />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ProductCreditForm;
