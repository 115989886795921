import React, { Component } from "react";
import * as Survey from "survey-react";
import * as SurveyPDF from "survey-pdf";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";

window["$"] = window["jQuery"] = $;

widgets.jqueryuidatepicker(Survey, $);

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey);
//widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);

class AgentQuotationServey extends Component {
  constructor(props) {
    super(props);
    Survey.StylesManager.applyTheme("orange");
    Survey.showProgressBar = "bottom";
  }

  render() {
    //console.log("this.props._Quotation_JSON", this.props._Answer_JSON);
    var model = new Survey.Model(this.props._Quotation_JSON);
    model.data = this.props._Answer_JSON;
    model.mode = "display";
    return (
      <React.Fragment>
        <Survey.Survey model={model} />
      </React.Fragment>
    );
  }
}

export default AgentQuotationServey;
