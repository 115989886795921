import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import CKEditor from 'ckeditor4-react';
import './ManageCMS.css';

const { SearchBar } = Search;
const CKEconfig = {
  allowedContent: true,
  skin:'kama',
  embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
  image2_alignClasses: ['image-align-left', 'image-align-center', 'image-align-right'],
  image2_disableResizer: true,
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing']
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
    },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
    { name: 'font', groups: ['font'] },
    '/',
    '/'
  ],
  extraPlugins: 'print,format,font,justify,emoji,embed,autoembed,image2'
};

CKEconfig.imageUploadUrl = `${process.env.REACT_APP_BASE_URL}uploader/api`;


class ManageCMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addCMSTitle: '',
      addCMSContentTitle: '',
      addCMSContent: '',
      addIsActive: 0,
      editCMSTitle: '',
      editCMSContentTitle: '',
      editCMSContent: '',
      editIsActive: 0,
      Canned_Email_ID: '',
      previewCMS: '',
      Canned_Emails: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'cms_title',
          text: 'CMS Title',
          sort: true
        },
        {
          dataField: 'cms_template_title',
          text: 'CMS Content Title',
          sort: true
        },

        {
          dataField: 'edit',
          text: '',
          sort: false,
          formatter: this.CMSFormatter,
          headerAttrs: { width: 150 },
          attrs: { width: 150, className: 'EditRow' }
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeAdd = this.onChangeAdd.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.getCMSData = this.getCMSData.bind(this);
    this.viewCMSData = this.viewCMSData.bind(this);
  }

  GetAllCMSs() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Canned_Emails: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onChangeAdd(evt) {
    //console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      addCMSContent: newContent
    });
  }

  onChangeEdit(evt) {
    //console.log('onChange fired with event info: ', evt.editor.getData());
    var newContent = evt.editor.getData();
    this.setState({
      editCMSContent: newContent
    });
  }

  handleAdd(e) {
    e.preventDefault();
    try {
      let objCMS = {
        cms_id: this.state.addCMSID,
        cms_title: this.state.addCMSTitle,
        cms_template_title: this.state.addCMSContentTitle,
        cms_template: this.state.addCMSContent
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}cms/api/`,
        data: objCMS,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return this.props.history.push({
              pathname: '/tcaccess'
            });
          }
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'CMS Added Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_new_cms').hide();

            this.GetAllCMSs();

            this.setState({
              addCMSTitle: '',
              addCMSContent: ''
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  CMSFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getCMSData(row.cms_id)}
        />
      </div>
    );
  };

  CMSDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Are you sure to delete this record ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deleteCMSData(row.cms_id) // optional
            })
          }
        />
      </div>
    );
  };

  CMSPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-eye'
          onClick={() => this.viewCMSData(row.cms_id)}
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();

    try {
      let objCMS = {
        cms_id: this.state.editCMSID,
        cms_title: this.state.editCMSTitle,
        cms_template_title: this.state.editCMSContentTitle,
        cms_template: this.state.editCMSContent
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };

      let URL = `${process.env.REACT_APP_BASE_URL}cms/api/`;
      axios
        .put(URL, objCMS, headers)
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'CMS Updated Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.setState({
              editCMSID: '',
              editCMSTitle: '',
              editCMSContent: ''
            });

            $('#edit_cms').hide();

            this.GetAllCMSs();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getCMSData(_Canned_Email_ID) {
    try {
      this.setState({
        editCMSID: '',
        editCMSTitle: '',
        editCMSContentTitle: '',
        editCMSContent: ''
      });
      this.setState({ Canned_Email_ID: _Canned_Email_ID });
      const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=${_Canned_Email_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          this.setState({
            editCMSID: res.data[0].cms_id,
            editCMSTitle: res.data[0].cms_title,
            editCMSContentTitle: res.data[0].cms_template_title,
            editCMSContent: res.data[0].cms_template
          });

          $('#edit_cms').show();
        });
    } catch (error) {}
  }

  deleteCMSData(_Canned_Email_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}cms/api/?cms_id=${_Canned_Email_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.affectedRows === 1) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Canned Email Deleted Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllCMSs();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Some problem in deleting record.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        });
    } catch (error) {}
  }

  viewCMSData(_Canned_Email_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=${_Canned_Email_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          this.setState({
            previewCMS: res.data[0].cms_template
          });
          $('#preview_cms').show();
        });
    } catch (error) {}
  }

  componentWillMount = () => {
    //console.log('ManageCMS will mount');
  };

  componentDidMount = () => {
    //console.log('ManageCMS mounted');

    this.GetAllCMSs();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('ManageCMS will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('ManageCMS will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    //console.log('ManageCMS did update');
  };

  componentWillUnmount = () => {
    //console.log('ManageCMS will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage CMS
                </h4>
              </div>
              <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => $('#add_new_cms').show()}
                  style={{ display: '' }}
                >
                  Add New CMS
                </button>
              </div>
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='cms_id'
                          data={this.state.Canned_Emails}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                wrapperClasses='table-responsive'
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal myModal addCMS'
          id='add_new_cms'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <form
              id='frmAdd'
              onSubmit={this.handleAdd}
              style={{ width: '100%' }}
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Add New CMS</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_new_cms').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='addCMSTitle'
                        className='col-sm-2 control-labels'
                      >
                        CMS Title:
                      </label>
                      <input
                        type='text'
                        id='addCMSTitle'
                        className='form-control col-md-3'
                        name='addCMSTitle'
                        onChange={this.handleChange}
                        value={this.state.addCMSTitle}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='addCMSContentTitle'
                        className='col-sm-2 control-labels'
                      >
                        CMS Content Title:
                      </label>
                      <input
                        type='text'
                        id='addCMSContentTitle'
                        className='form-control col-md-3'
                        name='addCMSContentTitle'
                        onChange={this.handleChange}
                        value={this.state.addCMSContentTitle}
                        required
                      />
                    </div>

                    <div className='form-group row'>
                      <label
                        htmlFor='addCMSContent'
                        className='col-sm-2 control-labels'
                      >
                        CMS Content:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.addCMSContent}
                          onChange={this.onChangeAdd}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#add_new_cms').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' id='addCMS' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/** EDIT FORM */}
        <form id='frmEdit' onSubmit={this.handleEdit} style={{ width: '100%' }}>
          <div
            className='modal edit_cms myModal'
            id='edit_cms'
            style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Edit CMS</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_cms').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label
                        htmlFor='editCMSTitle'
                        className='col-sm-2 control-labels'
                      >
                        CMS Title:
                      </label>
                      <input
                        type='text'
                        id='editCMSTitle'
                        className='form-control col-md-3'
                        name='editCMSTitle'
                        value={this.state.editCMSTitle}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editCMSContentTitle'
                        className='col-sm-2 control-labels'
                      >
                        CMS Content Title:
                      </label>
                      <input
                        type='text'
                        id='editCMSContentTitle'
                        className='form-control col-md-3'
                        name='editCMSContentTitle'
                        onChange={this.handleChange}
                        value={this.state.editCMSContentTitle}
                        required
                      />
                    </div>
                    <div className='form-group row'>
                      <label
                        htmlFor='editCMSContent'
                        className='col-sm-2 control-labels'
                      >
                        CMS Content:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        <CKEditor
                          data={this.state.editCMSContent}
                          onChange={this.onChangeEdit}
                          config={CKEconfig}
                          onBeforeLoad={CKEDITOR =>
                            CKEDITOR !== null
                              ? (CKEDITOR.disableAutoInline = true)
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#edit_cms').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ManageCMS;
