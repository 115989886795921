import React from 'react';
import './Page404.css';

const Page404 = props => (
  <div className='bg-purple'>
    <div className='stars'>
      <div className='custom-navbar'>
        <div className='brand-logo'>
          <img
            src='https://system.hungryautomation.com:5050/setting_images/HungryAutomationLogo.png'
            width='80px'
          />
        </div>
      </div>
      <div className='central-body'>
        <img
          className='image-404'
          src='http://salehriaz.com/404Page/img/404.svg'
          width='300px'
        />
        <a
          href='javascript:void(0);'
          id='btnGoBack'
          onClick={() => {
            if (window.location.href.indexOf('client_') > -1) {
              window.location = document.location.origin + '/';
            } else {
              window.location = document.location.origin + '/tcaccess';
            }
          }}
          className='btn-go-home'
        >
          GO BACK HOME
        </a>
      </div>
      <div className='objects'>
        <img
          className='object_rocket'
          src='http://salehriaz.com/404Page/img/rocket.svg'
          width='40px'
          alt=''
        />
        <div className='earth-moon'>
          <img
            className='object_earth'
            src='http://salehriaz.com/404Page/img/earth.svg'
            width='100px'
            alt=''
          />
          <img
            className='object_moon'
            src='http://salehriaz.com/404Page/img/moon.svg'
            width='80px'
            alt=''
          />
        </div>
        <div className='box_astronaut'>
          <img
            className='object_astronaut'
            src='http://salehriaz.com/404Page/img/astronaut.svg'
            width='140px'
            alt=''
          />
        </div>
      </div>
      <div className='glowing_stars'>
        <div className='star' />
        <div className='star' />
        <div className='star' />
        <div className='star' />
        <div className='star' />
      </div>
    </div>
  </div>
);

export default Page404;
