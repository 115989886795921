import React, { Component } from 'react';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './UserPictures.css';

class UserPictures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      addPictureFile: [],
      Picture_ID: '',
      Pictures: [],
      Select_Picture_Files: [],
      loading: true,
      preview_More_Info: '',
      preview_More_Info_Title: '',
      showIndex: true,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'right',
      showVideo: {}
    };

    this.GetAllPictures = this.GetAllPictures.bind(this);
  }

  GetAllPictures() {
    const api = `${process.env.REACT_APP_BASE_URL}pictures/api/getClientPictures`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Pictures: res.data,
          loading: false
        });
      });
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    $('.active_left_menu').removeClass('active_left_menu');
    $('#lnkpicture').addClass('active_left_menu');
    this.GetAllPictures();
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.slideInterval !== prevState.slideInterval ||
      this.state.slideDuration !== prevState.slideDuration
    ) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  _onImageClick(event) {
    console.debug(
      'clicked on image',
      event.target,
      'at index',
      this._imageGallery.getCurrentIndex()
    );
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className='image-gallery-image'>
        {this.state.showVideo[item.embedUrl] ? (
          <div className='video-wrapper'>
            <a
              className='close-video'
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width='100%'
              height='500'
              src={item.embedUrl}
              frameBorder='0'
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className='play-button'></div>
            <img src={item.original} />
            {item.description && (
              <span
                className='image-gallery-description'
                style={{ right: '0', right: 'initial' }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  render() {
    const images = [];
    this.state.Pictures.map((item, key) => {
      console.log('tem.picture_file_path', item.picture_file_path);
      images.push({
        original: item.picture_file_path,
        thumbnail: item.picture_file_path,
        description: item.picture_title,
        originalTitle: item.picture_title,
        embedUrl:
          item.isvideo === 1
            ? item.video_link
            : '',
        renderItem: item.isvideo === 1 ? this._renderVideo.bind(this) : ''
      });
    });
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div>
          <div className='page-header'>
            <h1 className='page-title'>Gallery</h1>
          </div>

          <div className='page-content'>
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <img
                  src='../assets/images/tcc_loading.gif'
                  style={{ width: '128px' }}
                />
              </div>
            ) : images.length > 0 ? (
              <ImageGallery
                lazyLoad={true}
                items={images}
                ref={i => (this._imageGallery = i)}
                onClick={this._onImageClick.bind(this)}
                onImageLoad={this._onImageLoad}
                onSlide={this._onSlide.bind(this)}
                onPause={this._onPause.bind(this)}
                onScreenChange={this._onScreenChange.bind(this)}
                onPlay={this._onPlay.bind(this)}
                infinite={this.state.infinite}
                showBullets={this.state.showBullets}
                showFullscreenButton={
                  this.state.showFullscreenButton &&
                  this.state.showGalleryFullscreenButton
                }
                showPlayButton={
                  this.state.showPlayButton && this.state.showGalleryPlayButton
                }
                showThumbnails={this.state.showThumbnails}
                showIndex={this.state.showIndex}
                showNav={this.state.showNav}
                isRTL={this.state.isRTL}
                thumbnailPosition={this.state.thumbnailPosition}
                slideDuration={parseInt(this.state.slideDuration)}
                slideInterval={parseInt(this.state.slideInterval)}
                slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                additionalClass='app-image-gallery'
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserPictures;
