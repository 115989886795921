import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import $ from 'jquery';
import notie from 'notie';

const { SearchBar } = Search;

class AdminUserCreditForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      Disable_Button: '',
      User_ID: this.props.location.state.User_ID,
      Credit_Form_ID: '',
      User_FullName: this.props.location.state.User_FullName,
      Comp_Name: this.props.location.state.Comp_Name,
      creditForms: [],
      creditFormsVendorPush: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true,
          headerAttrs: { width: 50 }
        },
        {
          dataField: 'credit_form_status',
          text: 'Status',
          sort: true,
          headerAttrs: { width: 60 }
        },
        {
          dataField: 'create_date',
          text: 'Created Date',
          sort: true,
          headerAttrs: { width: 35 }
        },
        {
          dataField: 'credit_form_PDF',
          text: 'View',
          sort: false,
          formatter: this.creditFormPreviewFormatter,
          headerAttrs: { width: 35, style: { textAlign: 'center' } }
        },
        {
          dataField: 'ispushtovendor',
          text: 'Send to Vendor',
          sort: true,
          headerAttrs: { width: 35, style: { textAlign: 'center' } },
          attrs: { style: { textAlign: 'center' } }
        },
        {
          dataField: 'approve',
          text: 'Approve',
          sort: false,
          formatter: this.creditFormApproveFormatter,
          headerAttrs: { width: 35, style: { textAlign: 'center' } }
        },
        {
          dataField: 'decline',
          text: 'Reject',
          sort: false,
          formatter: this.creditFormDeclineFormatter,
          headerAttrs: { width: 35, style: { textAlign: 'center' } }
        }
      ],
      columnsVendorPush: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'credit_form',
          text: 'Contract Name',
          sort: true,
          formatter: this.creditFormNamePreviewFormatter
        },
        {
          dataField: 'create_date',
          text: 'Created Date',
          sort: true
        },

        {
          dataField: 'credit_form_PDF',
          text: 'View',
          sort: false,
          formatter: this.creditFormPreviewFormatter,
          headerAttrs: { width: 35, style: { textAlign: 'center' } }
        }
      ]
    };

    this.creditFormPreviewFormatter = this.creditFormPreviewFormatter.bind(
      this
    );
    this.creditFormApproveFormatter = this.creditFormApproveFormatter.bind(
      this
    );
    this.creditFormDeclineFormatter = this.creditFormDeclineFormatter.bind(
      this
    );

    this.GetAdminUserCreditForms = this.GetAdminUserCreditForms.bind(this);
    this.GetVendorPushCreditForms = this.GetVendorPushCreditForms.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.handlePushVendor = this.handlePushVendor.bind(this);
  }

  GetAdminUserCreditForms() {
    const api = `${process.env.REACT_APP_BASE_URL}credit_form/api/Admin?user_id=${this.state.User_ID}`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          creditForms: res.data,
          loading: false
        });
      });
  }

  creditFormNamePreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          lineHeight: 'normal'
        }}
      >
        Credit Form
      </div>
    );
  };

  creditFormPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <a
          className='fa fa-eye'
          target='_blank'
          href={process.env.REACT_APP_BASE_URL + row.credit_form_path}
        />
      </div>
    );
  };

  creditFormApproveFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.credit_form_status === 'Pending' ? (
          <span
            className='fa fa-check'
            style={{ color: 'green' }}
            onClick={() =>
              notie.confirm({
                text: 'Warning: DO YOU WANT TO APPROVE THIS CREDIT FROM ?',
                submitText: 'Yes', // optional, default = 'Yes'
                cancelText: 'No', // optional, default = 'Cancel'
                position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: () =>
                  this.approveCreditForm(
                    row.credit_form_id,
                    row.product_title,
                    row.first_name,
                    row.email
                  ) // optional
              })
            }
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  creditFormDeclineFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        {row.credit_form_status === 'Pending' ? (
          <span
            className='fa fa-times'
            style={{ color: 'red' }}
            onClick={() =>
              notie.input({
                text: 'DO YOU WANT TO REJECT THIS CREDIT FORM ?',
                submitText: 'Reject', // optional, default = 'Submit'
                cancelText: 'Cancel', // optional, default = 'Cancel'
                position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
                submitCallback: value => {
                  this.declineCreditForm(
                    row.credit_form_id,
                    row.product_title,
                    row.first_name,
                    row.email,
                    value
                  );
                }, // optional
                placeholder: 'Enter Rejection Reason', // default: ''
                autofocus: 'true', // default: 'true'
                max: '10000', // default: ''
                maxlength: '100', // default: ''
                min: '10', // default: ''
                minlength: '10', // default: ''
                type: 'text'
              })
            }
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  approveCreditForm(
    _Credit_Form_ID,
    _Product_Title,
    _Client_Name,
    _Client_Email
  ) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}credit_form/api/approveCreditForm`;

      let objCreditForm = {
        credit_form_id: _Credit_Form_ID,
        product_title: _Product_Title,
        client_name: _Client_Name,
        client_email: _Client_Email
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };
      axios.put(api, objCreditForm, headers).then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        if (res.data.success === true) {
          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.GetAdminUserCreditForms();
        } else {
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  declineCreditForm(
    _Credit_Form_ID,
    _Product_Title,
    _Client_Name,
    _Client_Email,
    _Decline_Reason
  ) {
    try {
      if (_Decline_Reason === '') {
        return notie.alert({
          type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Rejection Reason is Required.',
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
      const api = `${process.env.REACT_APP_BASE_URL}credit_form/api/declineCreditForm`;

      let objCreditForm = {
        credit_form_id: _Credit_Form_ID,
        product_title: _Product_Title,
        client_name: _Client_Name,
        client_email: _Client_Email,
        decline_reason: _Decline_Reason
      };

      let headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      };
      axios.put(api, objCreditForm, headers).then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        if (res.data.success === true) {
          notie.alert({
            type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
          this.GetAdminUserCreditForms();
        } else {
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
      });
    } catch (error) {}
  }

  componentWillMount = () => {
    //console.log('AdminUserCreditForms will mount');
  };

  GetVendorPushCreditForms() {
    const api = `${process.env.REACT_APP_BASE_URL}credit_form/api/PushToVendor/?user_id=${this.state.User_ID}`;
    this.setState({
      loading: true,
      Disable_Button: 'disabled'
    });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcaccess');
        }
        if (res.data.success === false) {
          this.setState({
            loading: false,
            Disable_Button: ''
          });
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        this.setState({
          selectedCreditForms: [],
          creditFormsVendorPush: res.data,
          loading: false,
          Disable_Button: ''
        });
        $('#push_to_vendor').show();
      });
  }

  onRowSelect(row, isSelected, e) {
    let newArray = isSelected // if isChecked is true
      ? [...this.state.selectedCreditForms, row.credit_form_id] // add element
      : [...this.state.selectedCreditForms].filter(
          e => e !== row.credit_form_id
        ); // remove the elements that are equal to newStatus
    this.setState({ selectedCreditForms: newArray });
    //console.log('newArray', newArray);
  }

  onSelectAll(isSelected, rows) {
    let newArray = [];
    if (isSelected) {
      for (let i = 0; i < rows.length; i++) {
        newArray.push(rows[i].credir_form_id);
      }
    }

    this.setState({ selectedCreditForms: newArray });
    //console.log('newArray', newArray);
  }

  handlePushVendor(e) {
    e.preventDefault();
    this.setState({
      Disable_Button: 'disabled'
    });
    try {
      if (this.state.selectedCreditForms.length === 0) {
        notie.alert({
          type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: 'Please Select Credit Form to Send to Vendor',
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
        this.setState({
          Disable_Button: ''
        });
        return false;
      }
      this.setState({
        Disable_Button: 'disabled'
      });
      $('#btnSendToVendor').attr('disabled', 'disabled');
      let objCreditForm = {
        creditForms: this.state.selectedCreditForms,
        user_id: this.state.User_ID
      };

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}credit_form/api/PushToVendor`,
        data: objCreditForm,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcaccess');
          }
          if (res.data.success === false) {
            this.setState({
              Disable_Button: ''
            });
            $('#btnSendToVendor').attr('disabled', '');
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          if (res.data.success === true) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#btnSendToVendor').attr('disabled', '');
            $('#push_to_vendor').hide();

            this.GetAdminUserCreditForms();

            this.setState({
              selectedCreditForms: [],
              Disable_Button: ''
            });
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#btnSendToVendor').attr('disabled', '');
            this.setState({
              Disable_Button: ''
            });
          }
        })
        .catch(
          () =>
            function(response) {
              notie.alert({
                type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: response.message,
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
        );
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
    $('#btnSendToVendor').attr('disabled', '');
  }

  componentDidMount = () => {
    this.GetAdminUserCreditForms();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true, // enable click to select
      columnWidth: '20px',
      bgColor: 'lightgray',
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    };
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-6'>
                <h4 className='ml-3' id='heading'>
                  {this.state.Comp_Name} Credit Form
                </h4>
              </div>
              <div className='col-md-6 col-sm-6 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-warning'
                  onClick={() => this.GetVendorPushCreditForms()}
                  disabled={this.state.Disable_Button}
                >
                  Send to Vendor
                </button>
              </div>
            </div>

            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='credit_form_id'
                          data={this.state.creditForms}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** PUSH TO VENDOR */}
        <div
          className='modal myModal PushtoVendor '
          id='push_to_vendor'
          style={{ display: 'none', paddingLeft: '15px', overflow: 'auto' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <form
              id='frmVendorPush'
              onSubmit={this.handlePushVendor}
              style={{ width: '100%' }}
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Send to Vendor</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#push_to_vendor').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='col-lg-12'>
                      <div className='example-wrap'>
                        <div className='example'>
                          {loading ? (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                src='../assets/images/tcc_loading.gif'
                                style={{ width: '128px' }}
                                alt='...'
                              />
                            </div>
                          ) : (
                            <div>
                              <ToolkitProvider
                                keyField='credit_form_id'
                                data={this.state.creditFormsVendorPush}
                                columns={this.state.columnsVendorPush}
                                search
                                columnToggle
                              >
                                {props => (
                                  <div>
                                    {/*<ToggleList {...props.columnToggleProps} />
                              <hr />**/}

                                    <SearchBar
                                      {...props.searchProps}
                                      className='custome-search-field'
                                      style={{ color: '#000' }}
                                      
                                      placeholder='Search ...'
                                    />
                                    <hr />
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      wrapperClasses='table-responsive'
                                      {...props.baseProps}
                                      filter={filterFactory()}
                                      pagination={paginationFactory({
                                        sizePerPage: 5,
                                        nextPageText: 'Next',
                                        prePageText: 'Previous',
                                        sizePerPageList: [
                                          {
                                            text: '5 Per Page',
                                            value: 5
                                          },
                                          {
                                            text: '10 Per Page',
                                            value: 10
                                          },
                                          {
                                            text: '20 Per Page',
                                            value: 20
                                          },
                                          {
                                            text: 'All Record',
                                            value: 100
                                          }
                                        ]
                                      })}
                                      striped
                                      hover
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-warning'
                    onClick={() => $('#push_to_vendor').hide()}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    id='btnSendToVendor'
                    className='btn btn-success'
                    disabled={this.state.Disable_Button}
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminUserCreditForms;
