import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import notie from 'notie';
import $ from 'jquery';
import axios from 'axios';
import styles from './styles.module.css';

class GeneratePDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      Disable_Button: '',
      Content: props.Content,
      User_Agreement_ID: props.User_Agreement_ID,
      User_FullName: props.User_FullName,
      SignatureFile: props.SignatureFile,
      User_Comp_Name: props.User_Comp_Name,
      User_Title: props.User_Title,
      Modal_ID: props.ModalID,
      User_Sign: '',
      txtSignName: props.User_FullName,
      txtSignTitle: props.User_Title,
      addSigneeName: '',
      addSigneeEmail: '',
      addSigneeTitle: '',
      SignatureFlag: false,
      editSignatureFile: '',
      editSign: '',
      editSignCheck: false,
      chkLoadSignature: false
    };
    this.savePDF = this.savePDF.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendtoSignee = this.sendtoSignee.bind(this);
    this.handleLoadSignature = this.handleLoadSignature.bind(this);
  }
  componentWillMount() {
    $('.signature_agreement').css('display', 'none');
  }

  handleLoadSignature = event => {
   if (this.state.SignatureFile === '' || this.state.SignatureFile === null) {
     notie.alert({
       type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
       text: 'eSign is not Available.',
       stay: false, // optional, default = false
       time: 3, // optional, default = 3, minimum = 1,
       position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
     });
     return;
   }
    const target = event.target;
    this.setState({ chkLoadSignature: target.checked });
    if (target.checked) {
      $('#divSig').show();
      $('#divImgSig').show();
      $('.clsimgSig').show();
      $('.clsimgSig').attr(
        'src',
        process.env.REACT_APP_BASE_URL + this.state.SignatureFile
      );

      $('#btnSignAgreementLarge').hide();

      $('#btnSaveAgreementSmall').show();
      $('#btnSaveAgreementLarge').show();
    } else {
      $('#btnSignAgreementLarge').show();

      $('#btnSaveAgreementSmall').hide();
      $('#btnSaveAgreementLarge').hide();
      $('#divImgSig').hide();
      $('.clsimgSig').attr('src', '');
      $('.clsimgSig').hide();
      $('#divSig').hide();
    }
  };

  handleClear() {
    this.signaturePad.clear();
    this.setState({ User_Sign: '' });

    $('#divImgSig').hide();
    $('.clsimgSig').attr('src', '');
    $('.clsimgSig').hide();
    $('#divSig').hide();
  }

  handleSave() {
    if ($('#txtSignName').val() === '') {
      notie.alert({
        type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: 'Please Enter Name',
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
      $('#txtSignName').focus();
    } else if ($('#txtSignTitle').val() === '') {
      notie.alert({
        type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: 'Please Enter Title',
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
      $('#txtSignTitle').focus();
    } else if (this.signaturePad.isEmpty()) {
      // eslint-disable-next-line no-alert
      notie.alert({
        type: 'info', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: 'Please Provide a Signature First',
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else {
      var data = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');
      this.setState({ User_Sign: data });
      $('#divSig').show();
      $('#divImgSig').show();
      $('.clsimgSig').show();
      $('.clsimgSig').attr('src', data);

      $('#btnSignAgreementSmall').show();
      $('#btnSignAgreementLarge').hide();

      $('#btnSaveAgreementSmall').show();
      $('#btnSaveAgreementLarge').show();

      $('.signature_agreement').css('display', 'none');

      $('.lblClientTitle').text($('#txtSignTitle').val());
      $('.lblClientName').text($('#txtSignName').val());
    }
  }

  sendtoSignee(e) {
    e.preventDefault();
    try {
      let objAgreement = {
        user_agreement_id: this.state.User_Agreement_ID,
        signee_name: this.state.addSigneeName,
        signee_email: this.state.addSigneeEmail,
        signee_title: this.state.addSigneeTitle
      };
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}canned_email/api/signeeAgreement`,
        data: objAgreement,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          let _data = res.data;
          if (_data.success) {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#signee_agreement').hide();
          } else {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: _data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  savePDF(e) {
    e.preventDefault();

    if (
      this.signaturePad.isEmpty() &&
      ($('.clsimgSig').attr('src') === undefined ||
        $('.clsimgSig').attr('src') === '')
    ) {
      notie.alert({
        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: 'Agreement is not Signed',
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    } else {
      try {
        this.setState({
          loading: true,
          Disable_Button: 'disabled'
        });
        var value = $('input[id^="hdlistControl"]').val();
        if (value !== undefined) {
          var _Posted_Control = JSON.parse(value);
          var _Posted_Control_Data = '[';
          _Posted_Control.forEach(element => {
            if (element.type === 'textbox') {
              console.log('element.id', element.id);
              _Posted_Control_Data +=
                `{"data":"` + $(`input[id^="${element.id}"]`).val() + '"},';
            } else if (element.type === 'textarea') {
              _Posted_Control_Data +=
                `{"data":"` +
                $(`textarea[id^="${element.id}"]`)
                  .val()
                  .replace(/\t/g, '')
                  .replace(/\n/g, '')
                  .replace(/\r/g, '') +
                '"},';
            } else if (element.type === 'checkbox') {
              let checkValues = '';
              $(`input[name="${element.id}"]:checked`).each(function() {
                checkValues += $(this).val() + ',';
              });
              _Posted_Control_Data +=
                `{"data":"` + checkValues.replace(/,\s*$/, '') + '"},';
            }
          });
          console.log('_Posted_Control_Data', _Posted_Control_Data);
          _Posted_Control_Data = JSON.parse(
            _Posted_Control_Data.replace(/,\s*$/, '') + ']'
          );
          console.log('_Posted_Control_Data', _Posted_Control_Data);
        }

        //var id = $('input[id^="hdlistControl"]').attr('id');
        console.log('value:', value);

        let objAgreement = {
          user_agreement_id: this.state.User_Agreement_ID,
          chkLoadSignature: this.state.chkLoadSignature,
          user_signature_file: this.state.SignatureFile,
          user_sign: this.state.User_Sign,
          sign_name: this.state.txtSignName,
          sign_title: this.state.txtSignTitle,
          posted_agreement_content: this.state.Content,
          posted_control: _Posted_Control,
          posted_control_data: _Posted_Control_Data
        };
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}user_agreements/api/signAgreement`,
          data: objAgreement,

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
          .then(res => {
            if (res.data.logout === true) {
              return (window.location = '/client_login');
            }
            if (res.data.success === false) {
              this.setState({
                loading: false,
                Disable_Button: ''
              });
              return notie.alert({
                type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: res.data.message,
                stay: true, // optional, default = false
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
              });
            }
            this.setState({
              loading: false,
              Disable_Button: ''
            });
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Agreement Signed Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.handleClear();
            $(`#${this.state.Modal_ID}`).hide();
            this.props.Callback();
          })
          .catch(function(response) {
            //handle error
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: response.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          });
      } catch (error) {
        this.setState({
          loading: false,
          Disable_Button: ''
        });
        notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: error,
          stay: false, // optional, default = false
          time: 3, // optional, default = 3, minimum = 1,
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderSignaturePad() {
    return (
      <div style={{ height: 'auto', width: '100%' }}>
        <div className='container-fluid'>
          <div className='row sigPadRowHide' style={{ paddingBottom: '2px' }}>
            <label htmlFor='txtSignName' className='col-md-3'>
              Full Name:
            </label>
            <input
              type='text'
              id='txtSignName'
              className='form-control col-md-7'
              name='txtSignName'
              value={this.state.txtSignName}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className='row sigPadRowHide' style={{ paddingBottom: '2px' }}>
            <label htmlFor='txtSignTitle' className='col-md-3'>
              Title:
            </label>
            <input
              type='text'
              id='txtSignTitle'
              className='form-control col-md-7'
              name='txtSignTitle'
              value={this.state.txtSignTitle}
              onChange={this.handleChange}
              required
            />
          </div>
          <div
            className='row sigPadRow'
            style={{
              padding: '10px 10px 10px 10px',
              height: '200px'
            }}
          >
            <label htmlFor=''>Sign Here:</label>

            <div className={styles.container}>
              <div className={styles.sigContainer}>
                <SignatureCanvas
                  clearOnResize={true}
                  canvasProps={{ className: styles.sigPad }}
                  ref={ref => {
                    this.signaturePad = ref;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ height: 'auto', width: '100%' }}>
        <React.Fragment>
          <form onSubmit={this.savePDF}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Agreement Preview</h4>

                <button
                  type='button'
                  className='close'
                  onClick={() => $(`#${this.state.Modal_ID}`).hide()}
                >
                  ×
                </button>
              </div>

              <div
                className='modal-body'
                style={{
                  width: '100%',
                  height: 'auto',
                  paddingTop: 0
                  // overflowY: 'auto',
                  // maxHeight: 'calc(100vh - 100px)'
                }}
              >
                <div style={{ float: 'right' }}>
                  {' '}
                  <button
                    id='btnSaveAgreementSmall'
                    className='btn btn-success'
                    type='submit'
                    style={{ display: 'none' }}
                    disabled={this.state.Disable_Button}
                  >
                    Submit Agreement
                  </button>
                </div>
                <div
                  id='pData'
                  style={{
                    width: '100%',
                    padding: '20px',
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 1px black',
                    margin: 'auto',
                    overflow: 'auto'
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.Content
                    }}
                  />
                </div>
                <div className='container-fluid' style={{ paddingTop: '10px' }}>
                  <div className='row' id='divSig' style={{ display: 'none' }}>
                    <div className='col-6'>
                      {' '}
                      <div
                        id='divImgSig'
                        style={{ display: 'none' }}
                        className='row'
                      >
                        Signature :{' '}
                        <img
                          id='imgSig'
                          className='clsimgSig'
                          width='100px'
                          alt='Signature'
                        />
                      </div>
                    </div>
                    <div className='col-6'>
                      {' '}
                      <button
                        type='button'
                        id='btnSignAgreementSmall'
                        className='btn btn-primary'
                        onClick={() => {
                          $('.signature_agreement').css('display', 'block');
                          setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                          }, 1);
                        }}
                        style={{ display: 'none', float: 'right' }}
                      >
                        Sign Again
                      </button>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-2'>
                      <label
                        style={{ display: 'inline' }}
                        htmlFor='chkLoadSignature'
                      >
                        eSign:
                      </label>
                      &nbsp;
                      <input
                        type='checkbox'
                        id='chkLoadSignature'
                        checked={this.state.chkLoadSignature}
                        onChange={this.handleLoadSignature}
                      />
                    </div>
                    <div className='col-10'>
                      <button
                        type='button'
                        id='btnSignAgreementLarge'
                        className='btn-lg btn-block btn-primary'
                        onClick={() => {
                          $('.signature_agreement').css('display', 'block');
                          setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                          }, 1);
                        }}
                        style={{ display: 'block' }}
                      >
                        Sign Agreement
                      </button>
                      <button
                        id='btnSaveAgreementLarge'
                        className='btn-lg btn-block btn-success'
                        type='submit'
                        style={{ display: 'none' }}
                        disabled={this.state.Disable_Button}
                      >
                        Submit Agreement
                      </button>
                    </div>
                  </div>
                  <div className='form-group row'>
                    &nbsp;
                    <button
                      type='button'
                      style={{ display: 'none' }}
                      className='btn btn-warning'
                      onClick={() => $('#signee_agreement').show()}
                    >
                      Send to Signee
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/** Send to Signee*/}
          <form onSubmit={this.sendtoSignee}>
            <div
              className='modal myModal'
              id='signee_agreement'
              style={{ display: 'none' }}
            >
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 className='modal-title'>Send To Signee</h4>
                    <button
                      type='button'
                      className='close'
                      onClick={() => $('#signee_agreement').hide()}
                    >
                      ×
                    </button>
                  </div>

                  <div className='modal-body'>
                    <div className='container-fluid'>
                      <div className='form-group row'>
                        <label htmlFor='addSigneeName' className='col-md-3'>
                          Full Name:
                        </label>
                        <input
                          type='text'
                          id='addSigneeName'
                          className='form-control col-md-7'
                          name='addSigneeName'
                          value={this.state.addSigneeName}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='addSigneeEmail' className='col-md-3'>
                          Email Address:
                        </label>
                        <input
                          type='email'
                          id='addSigneeEmail'
                          className='form-control col-md-7'
                          name='addSigneeEmail'
                          value={this.state.addSigneeEmail}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className='form-group row'>
                        <label htmlFor='addSigneeTitle' className='col-md-3'>
                          Title:
                        </label>
                        <input
                          id='addSigneeTitle'
                          className='form-control col-md-7'
                          name='addSigneeTitle'
                          value={this.state.addSigneeTitle}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-warning'
                      onClick={() => $('#signee_agreement').hide()}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='btn btn-success'
                      disabled={this.state.Disable_Button}
                    >
                      Send{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/** SIGNATURE MODAL */}
          <div
            className='signature_agreement modal myModal'
            id='signature_agreement'
            style={{ overflow: 'auto' }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>{this.state.User_Comp_Name}</h4>
                  <button
                    type='button'
                    className='save_signature'
                    onClick={this.handleSave.bind(this)}
                  >
                    &nbsp;
                  </button>
                  <button
                    type='button'
                    className='clear_signature'
                    onClick={this.handleClear.bind(this)}
                  >
                    &nbsp;
                  </button>
                  <button
                    type='button'
                    className='close'
                    onClick={() =>
                      $('.signature_agreement').css('display', 'none')
                    }
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  {this.renderSignaturePad()}

                  <div
                    className='modal-footer sigPadRowHide'
                    style={{ paddingTop: '35px' }}
                  >
                    {/**  <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => $('.signature_agreement').hide()}
                    >
                      Cancel
                   </button> */}
                    <button
                      type='button'
                      className='btn btn-warning'
                      onClick={this.handleClear.bind(this)}
                    >
                      Clear
                    </button>
                    <button
                      type='button'
                      className='btn btn-success'
                      onClick={this.handleSave.bind(this)}
                    >
                      Sign Agreement
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default GeneratePDF;
