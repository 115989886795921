import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import BaseSelect from 'react-select';
import FixRequiredSelect from '../../../components/FixRequiredSelect';
import './DemoManagePictures.css';

const { SearchBar } = Search;

const Select = props => (
  <FixRequiredSelect {...props} SelectComponent={BaseSelect} />
);

class DemoManagePictures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,

      User_ID: this.props.location.state.User_ID,
      User_FullName: this.props.location.state.User_FullName,
      Comp_Name: this.props.location.state.Comp_Name,

      addPictureTitle: '',
      addPictureDesc: '',
      addPictureRate: '',
      addPictureFile: '',
      addVideoLink: '',
      addLinkCheck: false,
      addisActive: 0,
      addConnectedUserID: '',
      editPictureTitle: '',
      editPictureDesc: '',
      editPictureRate: '',
      editPictureFile: '',
      editVideoLink: '',
      editisActive: 0,
      editLinkCheck: false,
      editConnectedUserID: '',
      Picture_ID: '',
      Pictures: [],
      Connected_Users: [],
      loading: true,
      selectedOption: null,
      selectedEditOption: null,
      previewImageURL: '',
      previewisVideo: '',
      previewVideoLink: '',
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'picture_title',
          text: 'Picture Title',
          sort: true
        },

        {
          dataField: 'isactive',
          text: 'Active',
          sort: true,
          headerAttrs: { width: 100, style: { textAlign: 'center' } },
          attrs: { width: 100, style: { textAlign: 'center' } }
        },
        {
          dataField: 'pictureViewSmallFormatter',
          text: '',
          sort: false,
          formatter: this.pictureViewSmallFormatter,
          headerAttrs: { style: { textAlign: 'center' } },
          attrs: { width: 150, className: 'EditRow' }
        },
        {
          dataField: 'preview',
          text: 'View',
          sort: false,
          formatter: this.GalleryPreviewFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        }
        // {
        //   dataField: 'edit',
        //   text: 'Edit',
        //   sort: false,
        //   formatter: this.pictureFormatter,
        //   headerAttrs: { width: 75, style: { textAlign: 'center' } },
        //   attrs: { width: 75, style: { textAlign: 'center' } }
        // },
        // {
        //   dataField: 'delete',
        //   text: 'Delete',
        //   sort: false,
        //   formatter: this.pictureDeleteFormatter,
        //   headerAttrs: { width: 75, style: { textAlign: 'center' } },
        //   attrs: { width: 75, style: { textAlign: 'center' } }
        // }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getPictureData = this.getPictureData.bind(this);
    this.onChangeaddUpload = this.onChangeaddUpload.bind(this);
    this.onChangeeditUpload = this.onChangeeditUpload.bind(this);
    this.handleAddLink = this.handleAddLink.bind(this);
  }

  GetAllPictures() {
    this.setState({
          Pictures: [ 
   { 
      "serial_number":133,
      "user_id":245,
      "isactive":"No",
      "picture_id":47,
      "picture_title":"The future lies in the collaboration",
      "picture_desc":null,
      "picture_file_path":"https://system.hungryautomation.com:5050/pictures/1571879517109-CR35iAcollaborativeRobothelpinghuman.jpg",
      "picture_file_name":"1571879517109-CR35iAcollaborativeRobothelpinghuman.jpg",
      "isdeleted":0,
      "created_date":"2019-10-24T01:11:57.000Z",
      "modified_date":null,
      "isvideo":0,
      "video_link":"null",
      "picture_order":44
   }],
          loading: false
        });
  }

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  pictureViewSmallFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal',
          backgroundColor: 'whitesmoke'
        }}
      >
        <img alt='...' src={row.picture_file_path} style={{ height: '40px' }} />
      </div>
    );
  };

  handleAdd(e) {
    e.preventDefault();

    try {
      notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Picture Added Successfully',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_picture').hide();
            //$('.modal-backdrop').remove();
            this.GetAllPictures();
            $('#addPictureFile').val('');
            this.setState({
              addPictureTitle: '',
              addPictureDesc: '',
              addisActive: 1,
              addLinkCheck: false,
              addVideoLink: '',
              selectedOption: null,
              addConnectedUserID: ''
            });
    } catch (error) {
      this.setState({
        loading: false
      });

      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  pictureFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getPictureData(row.picture_id)}
        />
      </div>
    );
  };

  pictureFileViewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <a
          className='fa fa-eye'
          style={{ textDecoration: 'none' }}
          target='_blank'
          href={process.env.REACT_APP_BASE_URL + row.picture_file_path}
        />
      </div>
    );
  };

  pictureDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Warning: DO YOU WANT TO DELETE THIS PRODUCT ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deletePictureData(row.picture_id) // optional
            })
          }
        />
      </div>
    );
  };

  pictureFileDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Are you sure to delete this record ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () => this.deletePictureFile(row.picture_file_id) // optional
            })
          }
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();
    try {
          $('#edit_picture').hide();
            this.GetAllPictures();
            $('#editPictureFile').val('');
            this.setState({
              editPictureTitle: '',
              editPictureDesc: '',
              editisActive: 0,
              editLinkCheck: false,
              editVideoLink: '',
              editConnectedUserID: '',
              selectedEditOption: null
            });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getPictureData(_Picture_ID) {
    try {
      this.setState({
        editPictureTitle: '',
        editPictureDesc: '',
        editisActive: 0,
        editLinkCheck: false,
        editVideoLink: '',
        selectedEditOption: null,
        loading: true
      });

       this.setState({
            loading: false
          });
          $('#edit_picture').show();
         
            $('.divEditLink').hide();
            $('.divEditImage').show();
          
          this.setState({
            editPictureTitle: '',
            editPictureDesc: '',
            editisActive: '',
            editLinkCheck: '',
            editVideoLink: '',
            selectedEditOption: ''
          });
    } catch (error) {}
  }

  deletePictureData(_Picture_ID) {
    try {
     notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: 'Picture Deleted Successfully.',
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllPictures();
    } catch (error) {}
  }

  onChangeaddUpload = event => {
    this.setState({
      addPictureFile: event.target.files[0]
    });
  };

  onChangeeditUpload = event => {
    this.setState({
      editPictureFile: event.target.files[0]
    });
  };

 

  addConnectedUserSetValues = selectedOption => {
    this.setState({ selectedOption });
    console.log('this.state.selectedOption', selectedOption);
    if (selectedOption !== null) {
      selectedOption.forEach(element => {
        console.log('element.value', element.value);
        this.setState({
          addConnectedUserID: [...this.state.addConnectedUserID, element.value]
        });
      });
    } else {
      this.setState({ addConnectedUserID: '' });
    }
    console.log('this.state.addConnectedUserID', this.state.addConnectedUserID);
  };

  editConnectedUserSetValues = selectedEditOption => {
    this.setState({ selectedEditOption });
    if (selectedEditOption !== null) {
      this.setState({ editConnectedUserID: '' });
      let _UserID = [];
      selectedEditOption.forEach(element => {
        //console.log('element.value', element.value);
        _UserID.push(element.value);
      });
      this.setState({ editConnectedUserID: _UserID });
    } else {
      this.setState({ editConnectedUserID: '' });
    }
  };

  handleAddLink = event => {
    this.setState({ addPictureFile: '' });
    $('#addPictureFile').val('');
    const target = event.target;
    if (target.checked) {
      this.setState({ addLinkCheck: true });
      $('.divAddLink').show();
      $('.divAddImage').hide();
    } else {
      this.setState({ addLinkCheck: false });
      $('.divAddLink').hide();
      $('.divAddImage').show();
    }
  };

  handleEditLink = event => {
    const target = event.target;
    if (target.checked) {
      this.setState({ editLinkCheck: true });
      $('.divEditLink').show();
      $('.divEditImage').hide();
    } else {
      this.setState({ editLinkCheck: false });
      $('.divEditLink').hide();
      $('.divEditImage').show();
    }
  };
  GalleryPreviewFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-eye'
          onClick={() => this.viewPictrueData(row.picture_id)}
        />
      </div>
    );
  };
  viewPictrueData(_Picture_ID) {
    try {
       this.setState({
            previewImageURL: 'https://system.hungryautomation.com:5050/pictures/1571879517109-CR35iAcollaborativeRobothelpinghuman.jpg',
            previewisVideo: '0',
            previewVideoLink: ''
          });
          $('#preview_picture_detail').show();
    } catch (error) {}
  }

  componentDidMount = () => {
    this.GetAllPictures();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const {
      loading,
      selectedOption,
      selectedEditOption,
      previewImageURL,
      previewisVideo,
      previewVideoLink
    } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  Manage Gallery
                </h4>
              </div>
              {/**<div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    this.setState({ selectedOption: null });
                    $('#add_picture').show();
                  }}
                >
                  Add Picture
                </button>
              </div> */}
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='picture_id'
                          data={this.state.Pictures}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>

                        <form
                          onSubmit={this.handleAdd}
                          encType='multipart/form-data'
                        >
                          <div
                            className='modal myModal AddPicture'
                            id='add_picture'
                            style={{
                              display: 'none',
                              paddingLeft: '15px',
                              overflow: 'auto'
                            }}
                          >
                            <div className='modal-dialog modal-dialog-centered modal-lg'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h4 className='modal-title'>Add Picture</h4>
                                  <button
                                    type='button'
                                    className='close'
                                    onClick={() => $('#add_picture').hide()}
                                  >
                                    ×
                                  </button>
                                </div>

                                <div className='modal-body'>
                                  <div className='container-fluid'>
                                    <div className='form-group row'>
                                      <label
                                        htmlFor='ddladdConnectedUser'
                                        className='col-md-2 control-labels'
                                      >
                                        Select Client:
                                      </label>
                                      <Select
                                        id='ddladdConnectedUser'
                                        value={selectedOption}
                                        className='col-md-10'
                                        onChange={
                                          this.addConnectedUserSetValues
                                        }
                                        placeholder='Select User(s)'
                                        options={this.state.Connected_Users}
                                        isMulti='true'
                                        isSearchable='true'
                                        closeMenuOnSelect='false'
                                      />
                                    </div>
                                    <div className='form-group row'>
                                      <label
                                        htmlFor='addPictureTitle'
                                        className='col-sm-2 control-labels'
                                      >
                                        Picture Title:
                                      </label>
                                      <input
                                        type='text'
                                        id='addPictureTitle'
                                        className='form-control col-md-9'
                                        name='addPictureTitle'
                                        value={this.state.addPictureTitle}
                                        onChange={this.handleChange}
                                        maxLength='150'
                                        required
                                      />
                                    </div>

                                    {/**  <div className='form-group row'>
                      <label
                        htmlFor='addPictureDesc'
                        className='col-sm-2 control-labels'
                      >
                        Picture Description:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        
                      </div>
                    </div> */}
                                    <div className='form-group row'>
                                      <label
                                        className='col-sm-2 control-labels'
                                        htmlFor='chkAddLink'
                                      >
                                        Video Link:
                                      </label>
                                      <div className='isnotified:0,'>
                                        <input
                                          type='checkbox'
                                          id='chkAddLink'
                                          checked={this.state.chkAddLink}
                                          onChange={this.handleAddLink}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className='form-group row divAddLink'
                                      style={{ display: 'none' }}
                                    >
                                      <label
                                        htmlFor='addVideoLink'
                                        className='col-sm-2 control-labels'
                                      >
                                        Video Link:
                                      </label>
                                      <input
                                        type='text'
                                        id='addVideoLink'
                                        className='form-control col-md-9'
                                        name='addVideoLink'
                                        value={this.state.addVideoLink}
                                        onChange={this.handleChange}
                                        maxLength='490'
                                      />
                                    </div>
                                    <div className='form-group row divAddImage '>
                                      <label
                                        htmlFor='addPictureFile'
                                        className='col-sm-2 control-labels'
                                      >
                                        Upload Files*:
                                      </label>
                                      <input
                                        type='file'
                                        id='addPictureFile'
                                        className=' col-md-9'
                                        name='addPictureFile'
                                        onChange={this.onChangeaddUpload}
                                        style={{ paddingLeft: 0 }}
                                        accept='.png,.jpg,.jpeg'
                                      />
                                    </div>
                                    <div className='form-group row'>
                                      <label
                                        htmlFor='addisActive'
                                        className='col-sm-2 control-labels'
                                      >
                                        Active:
                                      </label>
                                      <div className='isnotified:0,'>
                                        <input
                                          type='checkbox'
                                          id='addisActive'
                                          name='addisActive'
                                          checked={this.state.addisActive}
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className='form-group row'>
                                      <div className='col-sm-10 control-labels'>
                                        <label>
                                          * Image size must be 1020 x 500
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-danger'
                                    onClick={() => $('#add_picture').hide()}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type='submit'
                                    className='btn btn-success'
                                  >
                                    Save{' '}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/** EDIT FORM */}
                        <form
                          onSubmit={this.handleEdit}
                          encType='multipart/form-data'
                        >
                          <div
                            className='modal myModal EditPicture'
                            id='edit_picture'
                            style={{
                              display: 'none',
                              paddingLeft: '15px',
                              overflow: 'auto'
                            }}
                          >
                            <div className='modal-dialog modal-dialog-centered modal-lg'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h4 className='modal-title'>Edit Picture</h4>
                                  <button
                                    type='button'
                                    className='close'
                                    onClick={() => $('#edit_picture').hide()}
                                  >
                                    ×
                                  </button>
                                </div>

                                <div className='modal-body'>
                                  <div className='container-fluid'>
                                    <div className='form-group row'>
                                      <label
                                        htmlFor='ddleditConnectedUser'
                                        className='col-sm-2 control-labels'
                                      >
                                        Select User(s):
                                      </label>
                                      <Select
                                        id='ddleditConnectedAgreement'
                                        value={selectedEditOption}
                                        className='col-md-9'
                                        onChange={
                                          this.editConnectedUserSetValues
                                        }
                                        placeholder='Select User(s)'
                                        options={this.state.Connected_Users}
                                        isMulti='true'
                                        isSearchable='true'
                                        closeMenuOnSelect='false'
                                      />
                                    </div>
                                    <div className='form-group row'>
                                      <label
                                        htmlFor='editPictureTitle'
                                        className='col-sm-2 control-labels'
                                      >
                                        Picture Title:
                                      </label>
                                      <input
                                        type='text'
                                        id='editPictureTitle'
                                        className='form-control col-md-9'
                                        name='editPictureTitle'
                                        value={this.state.editPictureTitle}
                                        onChange={this.handleChange}
                                        required
                                        maxLength='150'
                                      />
                                    </div>

                                    {/**<div className='form-group row'>
                      <label
                        htmlFor='editPictureDesc'
                        className='col-sm-2 control-labels'
                      >
                        Picture Description:
                      </label>
                      <div className='col-md-9' style={{ paddingLeft: 0 }}>
                        
                      </div>
          </div>*/}
                                    <div className='form-group row'>
                                      <label
                                        className='col-sm-2 control-labels'
                                        htmlFor='editLinkCheck'
                                      >
                                        Video Link:
                                      </label>
                                      <div className='isnotified:0,'>
                                        <input
                                          type='checkbox'
                                          id='editLinkCheck'
                                          checked={this.state.editLinkCheck}
                                          onChange={this.handleEditLink}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className='form-group row divEditLink'
                                      style={{ display: 'none' }}
                                    >
                                      <label
                                        htmlFor='editVideoLink'
                                        className='col-sm-2 control-labels'
                                      >
                                        Video Link:
                                      </label>
                                      <input
                                        type='text'
                                        id='editVideoLink'
                                        className='form-control col-md-9'
                                        name='editVideoLink'
                                        value={this.state.editVideoLink}
                                        onChange={this.handleChange}
                                        maxLength='490'
                                      />
                                    </div>
                                    <div className='form-group row divEditImage'>
                                      <label
                                        htmlFor='editPictureFile'
                                        className='col-sm-2 control-labels'
                                      >
                                        Upload Files*:
                                      </label>
                                      <input
                                        type='file'
                                        id='editPictureFile'
                                        className='col-md-9'
                                        name='editPictureFile'
                                        onChange={this.onChangeeditUpload}
                                        style={{ paddingLeft: 0 }}
                                        accept='.png,.jpg,.jpeg'
                                      />
                                    </div>
                                    <div className='form-group row'>
                                      <label
                                        htmlFor='editisActive'
                                        className='col-sm-2 control-labels'
                                      >
                                        Active:
                                      </label>
                                      <div className='isnotified:0,'>
                                        <input
                                          type='checkbox'
                                          id='editisActive'
                                          name='editisActive'
                                          checked={this.state.editisActive}
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className='form-group row'>
                                      <div className='col-sm-10 control-labels'>
                                        <label>
                                          * Image size must be 1020 x 500
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-danger'
                                    onClick={() => $('#edit_picture').hide()}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type='submit'
                                    className='btn btn-success'
                                  >
                                    Save{' '}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** PREWVIEW PICTURE*/}
        <div
          className='modal preview_picture_detail myModal'
          id='preview_picture_detail'
          style={{ display: 'none' }}
        >
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'> Preview</h4>
                <button
                  type='button'
                  className='close'
                  onClick={() => $('#preview_picture_detail').hide()}
                >
                  ×
                </button>
              </div>

              <div className='modal-body'>
                <div className='container-fluid'>
                  <div className='form-group row'>
                    {previewisVideo === 1 ? (
                      <iframe
                        height='500px'
                        width='100%'
                        src={previewVideoLink}
                      ></iframe>
                    ) : (
                      <img alt='' height='500px' src={previewImageURL} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemoManagePictures;
