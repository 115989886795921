import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import notie from 'notie';
import $ from 'jquery';
import './AgentManageSite.css';
const { SearchBar } = Search;

class AgentManageSite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      User_ID: this.props.location.state.User_ID,
      User_FullName: this.props.location.state.User_FullName,
      Comp_Name: this.props.location.state.Comp_Name,
      isSiteInfo: this.props.location.state.isSiteInfo,
      Agent_ID: this.props.location.state.Agent_ID,

      addSiteMasterName: '',
      addisActive: 0,

      editSiteMasterName: '',
      editisActive: 0,

      Site_Master_ID: '',
      Site_Masters: [],
      loading: true,
      columns: [
        {
          dataField: 'serial_number',
          text: 'Sr #',
          sort: true
        },
        {
          dataField: 'site_master_name',
          text: 'Site Name',
          sort: true
        },

        {
          dataField: 'isactive',
          text: 'Active',
          sort: true
        },
        {
          dataField: 'detail',
          text: 'View Detail',
          sort: false,
          formatter: this.viewSiteDetailFormatter,
          headerAttrs: { width: 100, style: { textAlign: 'center' } },
          attrs: { width: 100, style: { textAlign: 'center' } }
        },
        {
          /** {
          dataField: 'edit',
          text: 'Edit',
          sort: false,
          formatter: this.siteMasterFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          sort: false,
          formatter: this.siteMasterDeleteFormatter,
          headerAttrs: { width: 75, style: { textAlign: 'center' } },
          attrs: { width: 75, style: { textAlign: 'center' } }
        }*/
        }
      ]
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSiteMasterData = this.getSiteMasterData.bind(this);
  }

  GetAllSiteMaster() {
    const api = `${process.env.REACT_APP_BASE_URL}site_master/api/agent?user_id=${this.state.User_ID}`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/tcagent');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          site_masters: res.data,
          loading: false
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked === true
          ? 1
          : 0
        : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAdd(e) {
    e.preventDefault();

    try {
      let objSiteMaster = {
        user_id: this.state.User_ID,
        site_master_name: this.state.addSiteMasterName,
        isactive: this.state.addisActive
      };

      //console.log('this.state.addSignCheck', this.state.addSignCheck);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}site_master/api/agent/`,
        data: objSiteMaster,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          } else {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#add_site_master').hide();
            //$('.modal-backdrop').remove();
            this.GetAllSiteMaster();

            this.setState({
              addSiteMasterName: '',
              addisActive: 1
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  siteMasterFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-edit'
          onClick={() => this.getSiteMasterData(row.site_master_id)}
        />
      </div>
    );
  };

  siteMasterDeleteFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-trash'
          onClick={() =>
            notie.confirm({
              text: 'Are you sure to delete this record ?',
              submitText: 'Yes', // optional, default = 'Yes'
              cancelText: 'No', // optional, default = 'Cancel'
              position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
              submitCallback: () =>
                this.deleteSiteMasterData(row.site_master_id) // optional
            })
          }
        />
      </div>
    );
  };

  viewSiteDetailFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: 'normal'
        }}
      >
        <span
          className='fa fa-info'
          onClick={() =>
            this.props.history.push({
              pathname: '/agent_client_site_detail',
              state: {
                User_ID: this.state.User_ID,
                User_FullName: this.state.User_FullName,
                Comp_Name: this.state.Comp_Name,
                Site_Name: row.site_master_name,
                Site_Master_ID: row.site_master_id,
                isSiteInfo: this.state.isSiteInfo,
                Agent_ID : this.state.Agent_ID
              }
            })
          }
        />
      </div>
    );
  };

  handleEdit(e) {
    e.preventDefault();
    try {
      let objUdpateSiteMaster = {
        site_master_id: this.state.Site_Master_ID,
        site_master_name: this.state.editSiteMasterName,
        isactive: this.state.editisActive
      };

      axios({
        method: 'put',
        url: `${process.env.REACT_APP_BASE_URL}site_master/api/agent/`,
        data: objUdpateSiteMaster,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
        }
      })
        .then(res => {
          if (res.data.success === false) {
            notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            return false;
          } else {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            $('#edit_site_master').hide();

            this.GetAllSiteMaster();

            this.setState({
              Site_Master_ID: '',
              editSiteMasterName: '',
              editisActive: 0
            });
          }
        })
        .catch(function(response) {
          //handle error
          notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: response.message,
            stay: false, // optional, default = false
            time: 3, // optional, default = 3, minimum = 1,
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        });
    } catch (error) {
      notie.alert({
        type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        text: error,
        stay: false, // optional, default = false
        time: 3, // optional, default = 3, minimum = 1,
        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      });
    }
  }

  getSiteMasterData(_Site_Master_ID) {
    try {
      this.setState({
        editSiteMasterName: '',
        editisActive: 0
      });

      this.setState({ Site_Master_ID: _Site_Master_ID });
      const api = `${process.env.REACT_APP_BASE_URL}site_master/api/agent/getSiteMaster?site_master_id=${_Site_Master_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          $('#edit_site_master').show();
          this.setState({
            editSiteMasterName: res.data[0].site_master_name,
            editisActive: res.data[0].isactive
          });
        });
    } catch (error) {}
  }

  deleteSiteMasterData(_Site_Master_ID) {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}site_master/api/agent/?site_master_id=${_Site_Master_ID}`;
      axios
        .delete(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_agent_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/tcagent');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          } else {
            notie.alert({
              type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: false, // optional, default = false
              time: 3, // optional, default = 3, minimum = 1,
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
            this.GetAllSiteMaster();
          }
        });
    } catch (error) {}
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    this.GetAllSiteMaster();
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <div>
        <div className='row'>
          <div className='col-md-12 card '>
            <div className='row'>
              <div className='col-md-9'>
                <h4 className='ml-3' id='heading'>
                  {this.state.Comp_Name} Site Info
                </h4>
              </div>
              {/** <div className='col-md-3 col-sm-5 text-right text-sm-left '>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => $('#add_site_master').show()}
                >
                  Add Site Info
                </button>
              </div>*/}
            </div>
            <hr />
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='example-wrap'>
                  <div className='example'>
                    {loading ? (
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src='assets/images/tcc_loading.gif'
                          style={{ width: '128px' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <ToolkitProvider
                          keyField='site_master_id'
                          data={this.state.site_masters}
                          columns={this.state.columns}
                          search
                        >
                          {props => (
                            <div>
                              <SearchBar
                                {...props.searchProps}
                                className='custome-search-field'
                                style={{ color: '#000' }}
                                
                                placeholder='Search ...'
                              />
                              <hr />
                              <BootstrapTable
                                {...props.baseProps}
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleAdd}>
          <div
            className='modal myModal AddSiteMaster'
            id='add_site_master'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Add Vendor Contact</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#add_site_master').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label htmlFor='addSiteMasterName' className='col-md-4'>
                        Site Name:
                      </label>
                      <input
                        type='text'
                        id='addSiteMasterName'
                        className='form-control col-md-6'
                        name='addSiteMasterName'
                        value={this.state.addSiteMasterName}
                        onChange={this.handleChange}
                        maxLength='150'
                        required
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='addisActive' className='col-md-4'>
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='addisActive'
                          name='addisActive'
                          checked={this.state.addisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#add_site_master').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/** EDIT FORM */}
        <form onSubmit={this.handleEdit}>
          <div
            className='modal myModal edit_site_master'
            id='edit_site_master'
            style={{
              display: 'none',
              paddingLeft: '15px',
              overflow: 'auto'
            }}
          >
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title ml-40'>Edit Site Info</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => $('#edit_site_master').hide()}
                  >
                    ×
                  </button>
                </div>

                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='form-group row'>
                      <label htmlFor='editSiteMasterName' className='col-md-4'>
                        Site Name:
                      </label>
                      <input
                        type='text'
                        id='editSiteMasterName'
                        className='form-control col-md-6'
                        name='editSiteMasterName'
                        value={this.state.editSiteMasterName}
                        onChange={this.handleChange}
                        required
                        maxLength='150'
                      />
                    </div>

                    <div className='form-group row'>
                      <label htmlFor='editisActive' className='col-md-4'>
                        Active:
                      </label>
                      <div className='isnotified:0,'>
                        <input
                          type='checkbox'
                          id='editisActive'
                          name='editisActive'
                          checked={this.state.editisActive}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => $('#edit_site_master').hide()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-success'>
                    Save{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default AgentManageSite;
