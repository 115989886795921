import React from 'react';
import notie from 'notie';
import axios from 'axios';
import $ from 'jquery';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import GeneratePDF from '../../components/GeneratePDF';
import { replaceAgreementContent } from '../../helper/util';
import ProductFiles from './ProductFiles';
import ProductCreditForm from './ProductCreditForm';
import ProductAgreements from './ProductAgreements';
import './UserDashboard.css';

class UserDashboard extends React.Component {
  constructor(props) {
    super(props);
    console.log('props.Service', props.Service);
    this.state = {
      hasError: false,
      loading: true,
      Dashboard_Content_Tile: '',
      Dashboard_Content: '',
      Service: props.Service,
      notifyAgreements: [],
      mandatoryAgreements: [],
      User_Credit_Form: '',
      User_Order_Form: '',
      User_Agreement_ID: '',
      User_FullName: '',
      User_Title: '',
      User_Comp_Name: '',
      SignatureFile: '',
      previewMandatory_Agreement: '',
      previewNotify_Agreement: '',
      preview_Agreement: '',
      preview_More_Info: '',
      preview_More_Info_Title: '',
      Callback: '',
      Products: [],
      columns: [
        {
          class: 'col',
          dataField: 'product_title',
          text: 'Item',
          sort: false,
          headerAttrs: {
            width: 200,
            style: {
              textAlign: 'left',
              backgroundColor: '#9E9E9E',
              color: 'white'
            }
          },
          attrs: { style: { textAlign: 'left' } }
        },

        {
          dataField: 'downloads',
          text: 'Downloads',
          sort: false,
          formatter: this.productFileFormatter,
          headerAttrs: {
            width: 100,
            style: {
              textAlign: 'center',
              backgroundColor: '#9E9E9E',
              color: 'white'
            }
          },
          attrs: { style: { textAlign: 'center' } }
        },
        {
          dataField: 'agreement',
          text: 'Agreement Status',
          sort: false,
          formatter: this.productAgreementFormatter,
          headerAttrs: {
            width: 100,
            style: {
              textAlign: 'center',
              backgroundColor: '#9E9E9E',
              color: 'white'
            }
          },
          attrs: { style: { textAlign: 'center' } }
        },
        {
          dataField: 'more_info',
          text: 'More Info',
          sort: false,
          formatter: this.productMoreInfoFormatter,
          headerAttrs: {
            width: 100,
            style: {
              textAlign: 'center',
              backgroundColor: '#9E9E9E',
              color: 'white'
            }
          },
          attrs: { style: { textAlign: 'center' } }
        }
      ]
    };
    this.GetUserNotifyAgreements = this.GetUserNotifyAgreements.bind(this);
    this.GetUserMandatoryAgreements = this.GetUserMandatoryAgreements.bind(
      this
    );
    this.GetAllProducts = this.GetAllProducts.bind(this);

    this.viewAgreementData = this.viewAgreementData.bind(this);
    this.Generate_Agreement = this.Generate_Agreement.bind(this);

    this.productFileFormatter = this.productFileFormatter.bind(this);
    this.productAgreementFormatter = this.productAgreementFormatter.bind(this);
    this.productOrderFormFormatter = this.productOrderFormFormatter.bind(this);
    this.productCreditFormFormatter = this.productCreditFormFormatter.bind(
      this
    );
    this.handlerviewAgreementData = this.handlerviewAgreementData.bind(this);
    this.GetProductCreditForm = this.GetProductCreditForm.bind(this);
    this.GetProductOrderForm = this.GetProductOrderForm.bind(this);
  }

  handlerviewAgreementData(_User_Agreement_ID) {
    this.viewAgreementData(_User_Agreement_ID, this.GetAllProducts);
  }

  GetAllProducts() {
    const api = `${process.env.REACT_APP_BASE_URL}products/api/getClientDashboardProducts`;
    this.setState({ Products: [] });
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }
        this.setState({
          Products: res.data,
          loading: false
        });
      });
  }

  GetUserNotifyAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/NotifiedAgreements`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        this.setState({ notifyAgreements: res.data });
      });
  }

  GetUserMandatoryAgreements() {
    const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/MandatoryAgreements`;
    axios
      .get(api, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
        }
      })
      .then(res => {
        if (res.data.logout === true) {
          return (window.location = '/client_login');
        }
        if (res.data.success === false) {
          return notie.alert({
            type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
            text: res.data.message,
            stay: true, // optional, default = false
            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
          });
        }

        this.setState({ mandatoryAgreements: res.data });
      });
  }

  viewAgreementData(_User_Agreement_ID, _Callback) {
    try {
      this.setState({
        loading: true,
        preview_Agreement: '',
        Callback: ''
      });
      const api = `${process.env.REACT_APP_BASE_URL}user_agreements/api/getAgreementUser?user_agreement_id=${_User_Agreement_ID}`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }

          let objUser = {
            client_title: res.data[0].client_title,
            client_name: res.data[0].client_name,
            client_comp_name: res.data[0].client_comp_name,
            client_email: res.data[0].client_email,
            client_address: res.data[0].client_address,
            client_phone_no: res.data[0].client_phone_no,
            client_fax: res.data[0].client_fax,
            vendor_signor_name: res.data[0].vendor_signor_name,
            vendor_signor_comp_name: res.data[0].vendor_signor_comp_name,
            vendor_signor_title: res.data[0].vendor_signor_title,
            vendor_signor_address: res.data[0].vendor_signor_address,
            vendor_signor_email: res.data[0].vendor_signor_email,
            vendor_signor_phone_no: res.data[0].vendor_signor_phone_no,
            vendor_signor_fax: res.data[0].vendor_signor_fax,
            user_comp_name: res.data[0].user_comp_name,
            user_comp_name_short: res.data[0].user_comp_name_short,
            user_address: res.data[0].user_address
          };
          this.setState({
            preview_Agreement: replaceAgreementContent(
              res.data[0].agreement_content,
              objUser
            ),
            User_Agreement_ID: res.data[0].user_agreement_id,
            User_FullName: res.data[0].client_name,
            User_Comp_Name: res.data[0].client_comp_name,
            User_Title: res.data[0].client_title,
            SignatureFile: res.data[0].user_signature_file,
            Callback: _Callback
          });
          this.setState({
            loading: false
          });
          $('#preview_agreement').show();
        });
    } catch (error) {}
  }

  productFileFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <ProductFiles Product_ID={row.product_id} />
      </React.Fragment>
    );
  };

  productAgreementFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <ProductAgreements
          handlerviewAgreementData={this.handlerviewAgreementData}
          Product_ID={row.product_id}
        />
      </React.Fragment>
    );
  };

  productOrderFormFormatter = (cell, row) => {
    return '-';
  };

  productCreditFormFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <ProductCreditForm
          Product_ID={row.product_id}
          Product_Rate={row.product_rate}
          Product_Title={row.product_title}
          history={this.props.history}
        />
      </React.Fragment>
    );
  };

  productMoreInfoFormatter = (cell, row) => {
    return (
      <a
        href='javascript:void'
        onClick={() => {
          this.setState({
            preview_More_Info: row.product_desc,
            preview_More_Info_Title: row.product_title
          });
          $('#preview_more_info').show();
        }}
      >
        More Info
      </a>
    );
  };

  Generate_Agreement() {
    if (this.state.preview_Agreement !== '') {
      return (
        <GeneratePDF
          Content={this.state.preview_Agreement}
          SignatureFile={this.state.SignatureFile}
          User_Agreement_ID={this.state.User_Agreement_ID}
          User_FullName={this.state.User_FullName}
          User_Comp_Name={this.state.User_Comp_Name}
          User_Title={this.state.User_Title}
          Callback={this.state.Callback}
          ModalID='preview_agreement'
        />
      );
    }
  }

  GetProductCreditForm() {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}credit_form/api/getUserCreditForm`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          if (res.data[0] !== undefined) {
            this.setState({
              User_Credit_Form: res.data[0].credit_form_path
            });
          }
        });
    } catch (error) {}
  }

  GetProductOrderForm() {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}order_form/api/getUserOrderForm`;
      axios
        .get(api, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tcc_user_token')}`
          }
        })
        .then(res => {
          if (res.data.logout === true) {
            return (window.location = '/client_login');
          }
          if (res.data.success === false) {
            return notie.alert({
              type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
              text: res.data.message,
              stay: true, // optional, default = false
              position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            });
          }
          if (res.data[0] !== undefined) {
            this.setState({
              User_Order_Form: res.data[0].order_form_path
            });
          }
        });
    } catch (error) {}
  }

  GetPageContent() {
    const api = `${process.env.REACT_APP_BASE_URL}cms/api/getCMS?cms_id=3`;
    axios.get(api).then(res => {
      if (res.data.logout === true) {
        return (window.location = '/client_login');
      }
      if (res.data.success === false) {
        return notie.alert({
          type: 'error', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
          text: res.data.message,
          stay: true, // optional, default = false
          position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        });
      }

      this.setState({
        Dashboard_Content_Title: res.data[0].cms_template_title,
        Dashboard_Content: res.data[0].cms_template
      });
    });
  }

  componentWillMount = () => {
    //console.log('UserDashboard will mount');
  };

  componentDidMount = () => {
    this.GetPageContent();
    this.GetProductCreditForm();
    this.GetProductOrderForm();
    this.GetUserNotifyAgreements();
    this.GetUserMandatoryAgreements();
    this.GetAllProducts();
  };

  componentWillReceiveProps = nextProps => {
    //console.log('UserDashboard will receive props', nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    //console.log('UserDashboard will update', nextProps, nextState);
  };

  componentDidUpdate = () => {
    $('.active_left_menu').removeClass('active_left_menu');
    $('#lnkdashboard').addClass('active_left_menu');
  };

  componentWillUnmount = () => {
    //console.log('UserDashboard will unmount');
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div>
          <div className='page-header'>
            <h1 className='page-title'>DASHBOARD</h1>
          </div>

          <div className='page-content'>
            <div className='panel'>
              <div className='panel-body container-fluid'>
                <div className='example-wrap wrap-1 '>
                  <div
                    className='example example-well well-1'
                    style={{ marginTop: '0 !important' }}
                  >
                    <div className='page-header'>
                      <h2 className='page-title text-white text-uppercase title-1'>
                        {this.state.Dashboard_Content_Title}
                      </h2>
                    </div>
                  </div>
                </div>
                <p className='para'>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.Dashboard_Content
                    }}
                  />
                </p>
                {loading ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src='../assets/images/tcc_loading.gif'
                      style={{ width: '128px' }}
                    />
                  </div>
                ) : (
                  <div>
                    <ToolkitProvider
                      keyField='product_id'
                      data={this.state.Products}
                      columns={this.state.columns}
                      search
                    >
                      {props => (
                        <div>
                          <BootstrapTable {...props.baseProps} striped hover />
                        </div>
                      )}
                    </ToolkitProvider>
                    {/** 
                    <div className='react-bootstrap-table'>
                      <table className='table table-striped table-hover table-bordered'>
                        <thead>
                          <tr>
                            {this.state.Service === 'Prepay' ? (
                              ''
                            ) : (
                              <th
                                width='100'
                                tabIndex='0'
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: 'rgb(158, 158, 158)',
                                  color: 'white'
                                }}
                              >
                                Credit Application
                              </th>
                            )}

                            <th
                              width='100'
                              tabIndex='0'
                              style={{
                                textAlign: 'center',
                                backgroundColor: 'rgb(158, 158, 158)',
                                color: 'white'
                              }}
                            >
                              Order Form
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.Service == 'Prepay' ? (
                              ''
                            ) : (
                              <td style={{ textAlign: 'center' }}>
                                {' '}
                                <span
                                  className='fa fa-pen'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname: '/client_credit'
                                    });
                                  }}
                                />
                                &nbsp;&nbsp;
                                {this.state.User_Credit_Form !== '' ? (
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    className='fa fa-file'
                                    target='_blank'
                                    href={
                                      process.env.REACT_APP_BASE_URL +
                                      this.state.User_Credit_Form
                                    }
                                  />
                                ) : (
                                  ''
                                )}
                              </td>
                            )}
                            <td style={{ textAlign: 'center' }}>
                              {' '}
                              <span
                                className='fa fa-pen'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: '/client_order'
                                  });
                                }}
                              />
                              &nbsp;&nbsp;
                              {this.state.User_Order_Form !== '' ? (
                                <a
                                  style={{ cursor: 'pointer' }}
                                  className='fa fa-file'
                                  target='_blank'
                                  href={
                                    process.env.REACT_APP_BASE_URL +
                                    this.state.User_Order_Form
                                  }
                                />
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>*/}
                  </div>
                )}
              </div>
              <div className='panel-heading'>
                <h3 className='panel-title'>
                  {this.state.notifyAgreements === [] ? (
                    ''
                  ) : (
                    <React.Fragment>
                      {this.state.notifyAgreements.map((data, index) => (
                        <React.Fragment key={data.user_agreement_id}>
                          <div
                            key={data.user_agreement_id}
                            className='btn btn-warning btn-block'
                            style={{
                              textAlign: 'left',
                              backgroundColor: '#FCEDCF',
                              color: 'black'
                            }}
                          >
                            Admin has updated <b>{data.agreement_title}</b>.
                            <a
                              key={data.user_agreement_id}
                              style={{ display: 'inline' }}
                              href='javascript:void();'
                              onClick={() =>
                                this.viewAgreementData(
                                  data.user_agreement_id,
                                  this.GetUserNotifyAgreements
                                )
                              }
                            >
                              Click here
                            </a>
                            , to sign this agreement.
                          </div>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                  {this.state.mandatoryAgreements === [] ? (
                    ''
                  ) : (
                    <React.Fragment>
                      {this.state.mandatoryAgreements.map((data, index) => (
                        <React.Fragment key={data.user_agreement_id}>
                          <div
                            key={data.user_agreement_id}
                            className='btn btn-warning btn-block'
                            style={{
                              textAlign: 'left',
                              backgroundColor: '#FCEDCF',
                              color: 'black'
                            }}
                          >
                            <b>{data.agreement_title}</b> is pending Signature.
                            <a
                              key={data.user_agreement_id}
                              href='javascript:void();'
                              style={{ display: 'inline' }}
                              onClick={() =>
                                this.viewAgreementData(
                                  data.user_agreement_id,
                                  this.GetUserMandatoryAgreements
                                )
                              }
                            >
                              Click here
                            </a>
                            , to sign this agreement.
                          </div>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                </h3>
              </div>
            </div>
          </div>
          {/** PREWVIEW AGREEMENT*/}

          <div
            className='modal myModal'
            id='preview_agreement'
            style={{ display: 'none' }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              {this.Generate_Agreement()}
            </div>
          </div>
          {/** PREWVIEW MORE INFO*/}

          <div
            className='modal myModal'
            id='preview_more_info'
            style={{ display: 'none' }}
          >
            <div className='modal-dialog modal-dialog-centered modal-lg'>
              <div className='modal-content'>
                <div
                  className='modal-body'
                  style={{
                    padding: 0,
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 100px)'
                  }}
                >
                  <div className='panel'>
                    <div className='container-fluid'>
                      <div className='example-wrap wrap-1'>
                        <div className='example example-well well-1'>
                          <div className='page-header'>
                            <h2 className='page-title text-white text-uppercase title-1'>
                              {this.state.preview_More_Info_Title}
                            </h2>
                            <button
                              type='button'
                              className='btn btn-outline btn-primary my-btn'
                              onClick={() => $('#preview_more_info').hide()}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div
                            className='ml-20 mr-md-50'
                            dangerouslySetInnerHTML={{
                              __html: this.state.preview_More_Info
                            }}
                          />
                        </div>
                        <div className='col-md-3' style={{ display: 'none' }}>
                          <ul className='list-unstyled'>
                            <li>
                              <button
                                type='button'
                                className='btn btn-block btn-danger mb-10'
                              >
                                Download Rate Deck
                              </button>
                            </li>
                            <li>
                              <button
                                type='button'
                                className='btn btn-block btn-success  mb-10'
                              >
                                Sign Agreement
                              </button>
                            </li>
                            <li>
                              <button
                                type='button'
                                className='btn btn-block btn-info mb-10'
                              >
                                Credit Application
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserDashboard;
